import React, {
  useState,
  useEffect,
  useMemo,
  // useCallback,
  useContext
} from 'react'
import styled, { css, keyframes } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { propOr } from 'ramda'

import saltyCalendarInit from 'assets/images/saltyCalendarInit.svg'

import {
  Button,
  SingleSelect,
  BouncingLoader,
  CalendarIcon,
  Tooltip
} from 'examkrackers-components'

import { cutTimeAndFormat, DATE_FORMATS, formatDate } from 'utils/date'
// import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import {
  ANALYTICS_EVENTS,
  handleActionAnalytics,
  getCourseTypeForAnalyticsEvents
} from 'utils/analytics'
import {
  columnsForExpiration,
  columnsOrderForExpiration,
  // countStudyDaysInRange,
  daysOfWeek,
  // firstClassDateCalendar,
  // countStudyDaysInRangeForExpiration,
  NUMBER_OF_DAYS_NEEDED_BEFORE_FIRST_CLASS
} from 'utils/calendar'
import { getOptionByValue } from 'utils/form'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import PATHS from 'utils/paths'
// import { SEVERITY } from 'utils/toast'

import {
  getMcatDates,
  setExamDate,
  setPrioriDays,
  // postBuildLiveClasses,
  postBuildPreReading
} from 'services/CalendarService'
// import { initialiseCourse } from 'services/CourseService'
// import LocalStorageService from 'services/LocalStorageService'
import AnalyticsService from 'services/AnalyticsService'

import { getStudent } from 'modules/auth/ducks/selectors'
// import { showToastRoutine } from 'modules/toast/ducks/actions'

import DateInput from 'components/DateInput/DateInput'
import LeftColumnQuestions from './LeftColumnQuestions'
import DayListItem from './DayListItem'
import useCalendarSettingsValidationForExpiration from 'hooks/useCalendarSettingsValidationForExpiration'

import {
  subDays,
  isAfter,
  parse,
  isEqual,
  differenceInDays,
  addDays
} from 'date-fns'
import PreReadingModal from 'modules/calendar/PreReadingModal'
// import WithoutPreReadingModal from 'modules/calendar/WithoutPreReadingModal'
import withSettingsPageWrapper from 'modules/calendar/SettingsPageWrapper'
import { CalendarContext, CalendarContextProvider } from 'hooks/CalendarContext'
import { Course } from 'types'

interface McatDate {
  id: string
  course_id: string
  mcat_date: string
}

const initialValues = {
  mcat_at: '',
  calendar_start_at: '',
  exam_at: '',
  prioriDays: [0, 0, 0, 0, 0, 0, 0],
  prereading_end_date: ''
}

const WrappedCalendarInitForExpiration = () => {
  return (
    <CalendarContextProvider>
      <CalendarInitForExpiration />
    </CalendarContextProvider>
  )
}

const CalendarInitForExpiration: React.FC = () => {
  const [values, setValues] = useState(initialValues)
  const [open, setOpen] = useState(true)
  // const [openWithoutPreReading, setOpenWithoutPreReading] = useState(false)
  const [datesMcat, setDatesMcat] = useState<McatDate[]>([])
  const [hoveringDate, setHoveringDate] = useState<string | null>(null)

  const [columnsData, setColumnsData] = useState(columnsForExpiration)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [withoutPreReading, setWithoutPreReading] = useState<boolean>(false)

  const { course } = useContext(CalendarContext)
  const courseFromContext = course as Course

  const { t } = useTranslation()

  const { push } = useHistory()
  // const dispatch = useDispatch()

  const isLiveCourse = course?.type === 'live_course'

  const calendarStartAt = values.calendar_start_at

  const dayBeforeFirstClassDate = subDays(
    new Date(course.endDateFirstDay?.class_date as string),
    1
  ).toString()

  const minPreReadingEndDate = addDays(calendarStartAt, 1)

  const maxPreReadingEndDate = subDays(
    new Date(course.endDateFirstDay?.class_date as string),
    NUMBER_OF_DAYS_NEEDED_BEFORE_FIRST_CLASS
  ).toString()

  const firstClassDate = course.endDateFirstDay?.class_date
  const parsedFirstClassDate = parse(
    firstClassDate?.slice(0, 10) as string,
    'yyyy-MM-dd',
    new Date()
  )
  const parsedHoveringDate = hoveringDate
    ? parse(hoveringDate as string, 'MMMM dd, yyyy', new Date())
    : ''
  const displayAfterFirstClassHelperText =
    hoveringDate &&
    (isAfter(new Date(hoveringDate), new Date(firstClassDate as string)) ||
      isEqual(parsedFirstClassDate, parsedHoveringDate))

  const daysBetweenStartDateAndFirstClass =
    differenceInDays(calendarStartAt, firstClassDate as string) * -1 - 1

  const canCalendarHaveSpaceForPreReading =
    isLiveCourse &&
    Number(daysBetweenStartDateAndFirstClass) >=
      NUMBER_OF_DAYS_NEEDED_BEFORE_FIRST_CLASS

  const accessibleTo = propOr('', 'accessible_to', course)
  const formatAccessibleTo = accessibleTo
    ? formatDate(accessibleTo, DATE_FORMATS.yearFirst)
    : ''

  const courseId = course.book_course_id
  const courseStudentId = course.id

  // const isReady = propOr(false, 'is_ready', course)
  const user = useSelector(getStudent)

  // const showToast = useCallback(
  //   payload => dispatch(showToastRoutine(payload)),
  //   [dispatch]
  // )

  const mcatDateOptions: { label: string; value: string }[] = useMemo(() => {
    return datesMcat
      .filter(date => isAfter(new Date(date.mcat_date), new Date()))
      .sort(
        (a, b) =>
          new Date(a.mcat_date).getTime() - new Date(b.mcat_date).getTime()
      )
      .map(date => ({
        label: formatDate(date.mcat_date, DATE_FORMATS.slash),
        value: propOr('', 'id', date)
      }))
  }, [datesMcat])

  const courseAnalysis = () => {
    const action = handleActionAnalytics(course as Course)
    let data = {}

    if (action === 'start') {
      data = {
        Time: formatDate(new Date(), DATE_FORMATS.dashedWithTime),
        'Course type': course?.type || false,
        'Course name': course?.title || false,
        'Course expiration date': mcat_at || false,
        'Course class end date': `${course?.calendar_start_at}` || false
      }
    } else {
      data = {
        Time: formatDate(new Date(), DATE_FORMATS.dashedWithTime),
        'Course type': course?.type || false,
        'Course name': course?.title || false,
        'Course expiration date': mcat_at || false
      }
    }

    AnalyticsService(user).logEvent(
      ANALYTICS_EVENTS[
        getCourseTypeForAnalyticsEvents(action, course?.type as string)
      ],
      data
    )
  }

  // changed for new init flow
  const redirectToCourseCalendar = prioriDays => {
    courseAnalysis()
    const isPrioriDays = isNotNilOrEmpty(prioriDays)

    isPrioriDays ? push(PATHS.calendar) : push(PATHS.calendarSetupExpiration)
  }

  // const handleInitialise = productType => {
  //   const handleSuccess = () => {
  //     AnalyticsService(user).logEvent(
  //       ANALYTICS_EVENTS[
  //         getCourseTypeForAnalyticsEvents('start', course?.type as string)
  //       ],
  //       {
  //         Time: formatDate(new Date(), DATE_FORMATS.dashedWithTime),
  //         'Course type': course?.type || false,
  //         'Course name': course?.title || false,
  //         'Course expiration date': course?.accessible_to || false,
  //         'Course class end date': `${course?.calendar_start_at}` || false
  //       }
  //     )
  //   }
  //   const handleError = e => {
  //     console.error(e)
  //     showToast({
  //       key: 'toast.somethingWentWrong',
  //       severity: SEVERITY.error
  //     })
  //   }

  //   initialiseCourse({
  //     id: course.id as string
  //   })
  //     .then(handleSuccess)
  //     .catch(handleError)
  //   // changed for new init flow
  //   // saveCourseInLocalStorage()
  //   if (productType === typeOfProduct.course) {
  //     push(PATHS.dashboardLoading)
  //   } else {
  //     push(PATHS.examLoading)
  //   }
  // }

  // const handleSelectCourse = () =>
  //   isReady
  //     ? redirectToCourseCalendar(values.prioriDays)
  //     : handleInitialise(typeOfProduct.course)

  const {
    dndErrors,
    // startDateMax,
    // examDateBoundaries,
    errors,
    touchedFields,
    setTouchedFields
  } = useCalendarSettingsValidationForExpiration({
    course: courseFromContext,
    values,
    mcatDateOptions,
    columnsData,
    isReorder: false,
    canCalendarHaveSpaceForPreReading: !withoutPreReading
  })

  const handleDragEnd = result => {
    const { source, destination, draggableId } = result

    setTouchedFields(prev => [...prev, 'priori_days'])

    if (destination.droppableId === 'prioritySix') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 6
    } else if (destination.droppableId === 'prioritySeven') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 7
    } else if (destination.droppableId === 'priorityOne') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 1
    } else if (destination.droppableId === 'priorityTwo') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 2
    } else if (destination.droppableId === 'priorityThree') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 3
    } else if (destination.droppableId === 'priorityFour') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 4
    } else if (destination.droppableId === 'priorityFive') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 5
    } else {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 0
    }

    if (!destination) {
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    const startColumn = columnsData[source.droppableId]
    const finishColumn = columnsData[destination.droppableId]

    if (startColumn === finishColumn) {
      const newDay = Array.from(startColumn.day)
      const [removed] = newDay.splice(0, 1)
      newDay.splice(destination.index, 0, removed)

      const newColumn = {
        ...startColumn,
        day: newDay
      }

      const newState = {
        ...columnsData,
        [source.droppableId]: newColumn
      }
      setColumnsData(newState)
    } else {
      const startNewDay = startColumn.day
      const [removed] = startNewDay.splice(0, 1)
      const newStartColumn = {
        ...startColumn,
        day: startNewDay
      }
      const finishNewDay = Array.from(finishColumn.day)
      finishNewDay.splice(destination.index, 0, removed)
      const newFinishColumn = {
        ...finishColumn,
        day: finishNewDay
      }

      const newState = {
        ...columnsData,
        [source.droppableId]: newStartColumn,
        [destination.droppableId]: newFinishColumn
      }

      setColumnsData(newState)
    }
  }

  const handleChangeMcatDate = option => {
    setValues(prev => ({ ...prev, mcat_at: option.value }))
    setTouchedFields(prev => [...prev, 'mcat_at'])
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
    setTouchedFields(prev => [...prev, name])
    if (name === 'prereading_end_date' || name === 'calendar_start_at') {
      setValues(prev => ({ ...prev, exam_at: course.accessible_to as string }))
    }
  }

  const handleSaveDate = async () => {
    setIsLoading(true)
    try {
      await setPrioriDays({
        id: courseStudentId as string,
        prioridays:
          JSON.stringify(values.prioriDays) ===
          JSON.stringify([0, 0, 0, 0, 0, 0, 0])
            ? [6, 0, 1, 4, 2, 3, 7]
            : values.prioriDays
      })
      await setExamDate({
        calendar_start_at: values.calendar_start_at,
        exam_at: formatAccessibleTo,
        course_id: courseStudentId as string,
        mcat_date_id: values.mcat_at,
        build_calendar: -3,
        is_pre_reading: true,
        pre_reading_end_date: values.prereading_end_date
      })
      await postBuildPreReading({
        date_end: values.prereading_end_date,
        date_start: values.calendar_start_at
      })

      courseAnalysis()

      setIsLoading(false)
      await redirectToCourseCalendar(values.prioriDays)
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  const handleSaveDateWithoutPreReading = async () => {
    setIsLoading(true)

    try {
      await setPrioriDays({
        id: courseStudentId as string,
        prioridays: values.prioriDays
      })

      await setExamDate({
        calendar_start_at: values.calendar_start_at,
        exam_at: formatAccessibleTo,
        course_id: courseStudentId as string,
        mcat_date_id: values.mcat_at,
        build_calendar: -3,
        is_pre_reading: false
        // pre_reading_end_date: values.prereading_end_date
      })

      courseAnalysis()

      setIsLoading(false)
    } catch (e) {
      console.error(e)
    } finally {
      push(PATHS.calendar)
    }
    setIsLoading(false)
  }

  const handleSaveDateAndCheckIfPreReadingIsPossible =
    canCalendarHaveSpaceForPreReading && !withoutPreReading
      ? handleSaveDate
      : handleSaveDateWithoutPreReading

  useEffect(() => {
    courseId &&
      getMcatDates({ id: courseId })
        .then(resp => {
          setDatesMcat(resp.data.data)
        })
        .catch(err => {
          console.error(err)
        })
  }, [])

  const { calendar_start_at, mcat_at } = values

  const shouldShowPreReadingModal =
    isNotNilOrEmpty(mcat_at) &&
    isNotNilOrEmpty(calendar_start_at) &&
    canCalendarHaveSpaceForPreReading

  useEffect(() => {
    setOpen(shouldShowPreReadingModal)
  }, [shouldShowPreReadingModal])

  // useEffect(() => {
  //   setOpenWithoutPreReading(shouldShowWithoutPreReadingModal)
  // }, [shouldShowWithoutPreReadingModal])

  // useEffect(() => {
  //   const handleAsyncEffect = async () => {
  //     if (withoutPreReading === true) {
  //       await handleSaveDateWithoutPreReading()
  //     }
  //   }
  //   handleAsyncEffect()
  // }, [withoutPreReading])

  const handleCalendarManualOpen = (id: string) => () => {
    const el = document.getElementById(id)

    if (el) {
      el.click()
    }
  }

  const handleHoverOverPrereadingEndDateSetup = e => {
    const canSetHoveringDate = e.target.ariaLabel ? true : false

    const isDateNotAvailable = e.target.ariaLabel
      ? e.target.ariaLabel.includes('ot available')
      : null
    const hoveringDateStringContentsArray = e.target.ariaLabel
      ? e.target.ariaLabel.split(' ')
      : []

    const currentlyHoveringDate = isDateNotAvailable
      ? hoveringDateStringContentsArray
          .slice(3, hoveringDateStringContentsArray.length)
          .join(' ')
          .toString()
          .replace(/(\d+)(st|nd|rd|th)/gi, '$1')
      : hoveringDateStringContentsArray
          .slice(2, hoveringDateStringContentsArray.length)
          .join(' ')
          .toString()
          .replace(/(\d+)(st|nd|rd|th)/gi, '$1')

    canSetHoveringDate ? setHoveringDate(currentlyHoveringDate) : null
  }

  useEffect(() => {
    if (isNotNilOrEmpty(values.calendar_start_at)) {
      setValues(prev => ({
        ...prev,
        prereading_end_date: maxPreReadingEndDate as string
      }))
    }
  }, [values.calendar_start_at])

  if (isLoading) {
    return (
      <LoaderContainer>
        <BouncingLoader />
      </LoaderContainer>
    )
  }

  return (
    <Container data-testid='CalendarInitForExpiration-Container'>
      <PreReadingModal
        data-testid='CalendarInitForExpiration-PreReadingModal'
        open={open}
        setOpen={setOpen}
        setWithoutPreReading={setWithoutPreReading}
      />
      {/* <WithoutPreReadingModal
        data-testid='CalendarInitForExpiration-WithoutPreReadingModal'
        open={openWithoutPreReading}
        setOpen={setOpenWithoutPreReading}
        setWithoutPreReading={setWithoutPreReading}
        generateCalendarWithoutPreReading={handleSaveDateWithoutPreReading}
      /> */}
      <TitleWrapper data-testid='CalendarInitForExpiration-TitleWrapper'>
        {t('calendar.setup.title')}
      </TitleWrapper>
      <SubtitleWrapper data-testid='CalendarInitForExpiration-SubtitleWrapper'>
        {t('calendar.setup.description')}
      </SubtitleWrapper>
      <ColumnsWrapper>
        <LeftColumn data-testid='CalendarInitForExpiration-LeftColumn'>
          <QuestionContainer data-testid='CalendarInitForExpiration-QuestionContainer'>
            <DescWrapper>
              <QuestionTitle data-testid='CalendarInitForExpiration-QuestionTitle'>
                {t('calendar.setup.mcat.question')}
              </QuestionTitle>
              <QuestionDescription data-testid='CalendarInitForExpiration-QuestionDescription'>
                {t('calendar.setup.mcat.tooltip')}
              </QuestionDescription>
            </DescWrapper>
            <SelectWrapper data-testid='CalendarInitForExpiration-SelectWrapper'>
              <StyledSelect
                data-testid='CalendarInitForExpiration-StyledSelect'
                removeMargin
                label=''
                size='small'
                options={mcatDateOptions}
                onChange={handleChangeMcatDate}
                disabled={isNilOrEmpty(mcatDateOptions)}
                value={getOptionByValue(values.mcat_at)(mcatDateOptions)}
              />
            </SelectWrapper>
          </QuestionContainer>
          {errors.mcatDate && touchedFields.includes('mcat_at') && (
            <Error data-testid='CalendarInitForExpiration-Error-mcatDate'>
              {errors.mcatDate}
            </Error>
          )}
          <QuestionContainer
            data-testid='CalendarInitForExpiration-QuestionContainer'
            disabled={isNilOrEmpty(values.mcat_at)}
          >
            <DescWrapper>
              <QuestionTitle data-testid='CalendarInitForExpiration-QuestionTitle-start_at'>
                {t('calendar.setup.start.question')}
              </QuestionTitle>
              <QuestionDescription>
                {t('calendar.setup.start.tooltip')}
              </QuestionDescription>
            </DescWrapper>

            <PickerWrapper>
              <DateInput
                data-testid='CalendarInitForExpiration-DateInput-start_at'
                name='calendar_start_at'
                id='calendar_start_at_input'
                value={values.calendar_start_at}
                validate={() => true}
                size='small'
                onChange={handleValueChange}
                minDate={new Date()}
                maxDate={
                  isAfter(new Date(), dayBeforeFirstClassDate as string)
                    ? (course.accessible_to as string)
                    : (dayBeforeFirstClassDate as string)
                }
              />
            </PickerWrapper>

            <IconContainer>
              <CalendarIcon
                data-testid='CalendarInitForExpiration-CalendarIcon-start_at'
                onClick={handleCalendarManualOpen('calendar_start_at_input')}
              />
            </IconContainer>
          </QuestionContainer>
          {errors.startDate && touchedFields.includes('calendar_start_at') && (
            <Error>{errors.startDate}</Error>
          )}
          {canCalendarHaveSpaceForPreReading && !withoutPreReading ? (
            <QuestionContainer
              data-testid='CalendarInitForExpiration-QuestionContainer-preReadingEnd'
              disabled={isNilOrEmpty(values.calendar_start_at) || open}
            >
              <DescWrapper>
                <QuestionTitle data-testid='CalendarInitForExpiration-QuestionTitle-preReadingEnd'>
                  {t('calendar.setupForExpiration.end.question')}
                </QuestionTitle>
                <QuestionDescription>
                  <QuestionContent data-testid='CalendarInitForExpiration-QuestionContent'>
                    {t('calendar.setupForExpiration.end.tooltip')}
                  </QuestionContent>
                </QuestionDescription>
              </DescWrapper>
              <PickerWrapper
                onMouseMove={handleHoverOverPrereadingEndDateSetup}
              >
                <DateInput
                  data-testid='CalendarInitForExpiration-DateInput-preReadingEnd'
                  name='prereading_end_date'
                  id='prereading_end_date_input'
                  value={values.prereading_end_date}
                  validate={() => true}
                  size='small'
                  // t={t}
                  onChange={handleValueChange}
                  minDate={minPreReadingEndDate}
                  maxDate={maxPreReadingEndDate as string}
                  course={course as Course}
                  withExtendModal
                />
              </PickerWrapper>
              <IconContainer>
                <CalendarIcon
                  data-testid='CalendarInitForExpiration-CalendarIcon-preReadingEnd'
                  onClick={handleCalendarManualOpen(
                    'prereading_end_date_input'
                  )}
                />
              </IconContainer>
            </QuestionContainer>
          ) : null}
          {errors.prereading_end_date &&
            canCalendarHaveSpaceForPreReading &&
            touchedFields.includes('prereading_end_date') && (
              <Error data-testid='CalendarInitForExpiration-Error-preReadingEnd'>
                {errors.prereading_end_date}
              </Error>
            )}
          {displayAfterFirstClassHelperText ? (
            <ErrorPrereading>
              {t('calendar.setupForExpiration.end.error')}
            </ErrorPrereading>
          ) : null}

          {canCalendarHaveSpaceForPreReading && !withoutPreReading && (
            <LeftColumnQuestions
              data-testid='CalendarInitForExpiration-LeftColumnQuestions'
              disabled={isNilOrEmpty(values.prereading_end_date) || open}
              isLiveCourse
            />
          )}
        </LeftColumn>
        {canCalendarHaveSpaceForPreReading &&
        !withoutPreReading &&
        isNotNilOrEmpty(values.prereading_end_date) ? (
          <RightColumn data-testid='CalendarInitForExpiration-RightColumn'>
            <ImageContainer data-testid='CalendarInitForExpiration-ImageContainer-saltyCalendarInit'>
              <img src={saltyCalendarInit} alt='book-cover-all' />
            </ImageContainer>
            <DragDropContext
              data-testid='CalendarInitForExpiration-DragDropContext'
              onDragEnd={handleDragEnd}
            >
              <WrapperDnd
                data-testid='CalendarInitForExpiration-WrapperDnd'
                disabled={isNilOrEmpty(values.exam_at)}
              >
                <WeekDaysColumn data-testid='CalendarInitForExpiration-WeekDaysColumn'>
                  <>
                    <ColumnTitle data-testid='CalendarInitForExpiration-ColumnTitle'>
                      {t('calendar.setup.mandatoryDaysOff')}
                    </ColumnTitle>
                    {columnsOrderForExpiration.map((columnId, index) => {
                      const column = columnsData[columnId]
                      if (index <= 6) {
                        return (
                          <DropZoneWrapper
                            key={column.id}
                            data-testid={`CalendarInitForExpiration-DropZoneWrapper-${column.id}`}
                          >
                            <Droppable
                              data-testid={`CalendarInitForExpiration-Droppable-WeekDays-${column.id}`}
                              droppableId={column.id}
                              isDropDisabled={
                                column.day && column.day.length === 1
                              }
                            >
                              {(provided, snapshot) => {
                                return (
                                  <SingleDropZone
                                    data-testid={`CalendarInitForExpiration-SingleDropZone-WeekDays-${column.id}`}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                  >
                                    <DropContainer
                                      data-testid={`CalendarInitForExpiration-DropContainer-WeekDays-${column.title}`}
                                    >
                                      {column.title}
                                    </DropContainer>

                                    {isNotNilOrEmpty(column.day) && (
                                      <DayListItem
                                        data-testid={`CalendarInitForExpiration-DayListItem-WeekDays-${column.day[0]}`}
                                        day={column.day[0]}
                                        index={index}
                                      />
                                    )}
                                    {provided.placeholder}
                                  </SingleDropZone>
                                )
                              }}
                            </Droppable>
                          </DropZoneWrapper>
                        )
                      } else {
                        return null
                      }
                    })}
                  </>
                </WeekDaysColumn>

                <PrioriColumn>
                  <>
                    <ColumnTitle data-testid='CalendarInitForExpiration-ColumnTitle-studyDays'>
                      {t('calendar.setup.studyDays')}
                    </ColumnTitle>
                    {columnsOrderForExpiration.map((columnId, index) => {
                      const column = columnsData[columnId]
                      if (index > 6) {
                        return (
                          <DropZoneWrapper
                            data-testid={`CalendarInitForExpiration-DropZoneWrapper-studyDays-${column.id}`}
                            key={column.id}
                          >
                            <Droppable
                              data-testid={`CalendarInitForExpiration-Droppable-studyDays-${column.id}`}
                              droppableId={column.id}
                              isDropDisabled={
                                column.day && column.day.length === 1
                              }
                            >
                              {(provided, snapshot) => {
                                return (
                                  <SingleDropZoneActive
                                    data-testid={`CalendarInitForExpiration-SingleDropZoneActive-studyDays-${column.id}`}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    isDraggingOver={snapshot.isDraggingOver}
                                  >
                                    <DropContainer
                                      data-testid={`CalendarInitForExpiration-DropContainer-studyDays-${column.title}`}
                                    >
                                      {column.title}
                                    </DropContainer>
                                    {isNotNilOrEmpty(column.day) && (
                                      <DayListItem
                                        data-testid={`CalendarInitForExpiration-DayListItem-studyDays-${column.day[0]}`}
                                        day={column.day[0]}
                                        index={index}
                                      />
                                    )}
                                    {provided.placeholder}
                                  </SingleDropZoneActive>
                                )
                              }}
                            </Droppable>
                          </DropZoneWrapper>
                        )
                      } else {
                        return null
                      }
                    })}
                  </>
                </PrioriColumn>
              </WrapperDnd>
            </DragDropContext>
            {isNotNilOrEmpty(dndErrors) &&
              touchedFields.includes('priori_days') && (
                <DndErrors>
                  {dndErrors.map(error => (
                    <Error>{error}</Error>
                  ))}
                </DndErrors>
              )}
          </RightColumn>
        ) : null}
      </ColumnsWrapper>
      <ButtonWrapper data-testid='CalendarInitForExpiration-ButtonWrapper'>
        {isNotNilOrEmpty(errors) ? (
          <Tooltip
            id='inactive-button'
            tooltipContent='This form must be completed in order to continue. You will be able to make changes at any time by clicking on the Reorganize button on your calendar.'
          >
            <Button
              data-testid='CalendarInitForExpiration-Button-setup-disabled'
              variant='contained'
              color='secondary'
              size='small'
              onClick={handleSaveDateAndCheckIfPreReadingIsPossible}
              disabled
            >
              {t('calendar.setup.button')}
            </Button>
          </Tooltip>
        ) : (
          <Button
            data-testid='CalendarInitForExpiration-Button-setup'
            variant='contained'
            color='secondary'
            size='small'
            onClick={handleSaveDateAndCheckIfPreReadingIsPossible}
            disabled={isNotNilOrEmpty(errors)}
          >
            {t('calendar.setup.button')}
          </Button>
        )}
      </ButtonWrapper>
    </Container>
  )
}

export default withSettingsPageWrapper(WrappedCalendarInitForExpiration)

const Container = styled.div`
  margin: 20px 132px;
  font-size: 12px;
  padding-bottom: 30px;
`

const TitleWrapper = styled.div`
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
`

const SubtitleWrapper = styled.div`
  text-align: center;
  font-size: 12px;
  line-height: px;
  margin-top: 10px;
`

const LeftColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const fadeId = keyframes`
from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const RightColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  animation: ${fadeId} 200ms ease-in-out;
`

const ColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ImageContainer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 65px;
`
const SingleDropZone = styled.div`
  color: ${({ theme }) => theme.colors.main.grey400};
  background-color: #fbf5f2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 26px;
  gap: 25px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23C5C6C8FF' stroke-width='2' stroke-dasharray='2%2c 8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
`
const SingleDropZoneActive = styled(SingleDropZone)`
  color: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? theme.colors.main.primary500 : '#444'};
  background-color: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? theme.colors.main.primary300 : '#fff3ed'};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%237B7C7DFF' stroke-width='2' stroke-dasharray='2%2c 8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
`

const WrapperDnd = styled.div`
  margin-left: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;

  margin-top: 36px;

  ${({ disabled }) =>
    disabled &&
    css`
      display: none;
      pointer-events: none;
      cursor: default;
    `}
`
const ColumnTitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
  width: 155px;
  text-align: center;
`
const WeekDaysColumn = styled.div``
const PrioriColumn = styled.div``

const DropZoneWrapper = styled.div`
  margin: 6px;
`
const DropContainer = styled.div`
  position: absolute;
`

const PickerWrapper = styled.div`
  display: flex;

  width: 120px;
  margin-top: -20px;
  margin-bottom: -30px;
`
const QuestionContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 20px;

  ${({ disabled }) =>
    disabled &&
    css`
      display: none;
      pointer-events: none;
      cursor: default;
    `}

  animation: ${fadeId} 200ms ease-in-out;
`
const QuestionTitle = styled.div`
  font-size: 14px;
  line-height: 30px;
`
const QuestionDescription = styled.div`
  font-size: 10px;
  margin-left: 20px;
  line-height: 20px;
  width: 310px;
`
const DescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 990px; */
`
const IconContainer = styled.div`
  color: ${({ theme }) => theme.colors.main.primary500};
  font-size: 30px;
  display: flex;
  height: 28px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-top: 7px;
  cursor: pointer !important;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`
const SelectWrapper = styled.div`
  margin-right: 8px;
  width: 100%;
  height: ${({ theme }) => theme.dimensions.inputHeight};
`
const StyledSelect = styled(SingleSelect)`
  min-width: 121px;
  max-width: 121px;

  div {
    overflow-x: hidden;
  }
`
const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Error = styled.div`
  color: red;
`

const ErrorPrereading = styled.div`
  color: red;
  font-size: 10px;
  margin-top: 10px;
  margin-left: 20px;
  max-width: 250px;
`

const DndErrors = styled.div`
  margin-top: 10px;
  padding-left: 10px;
`

// const WarningBox = styled.div`
//   margin-top: 20px;
//   display: flex;
//   justify-content: center;
// `

// const WarningBoxContent = styled.div`
//   text-align: center;
//   color: red;
// `
const QuestionContent = styled.div`
  width: 250px;
`
