import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal } from 'examkrackers-components'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { fetchAvailableClasses } from 'services/ClassScheduleRepository'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import { Course } from 'types'

import { removeUnderscoreAndAddSpace } from 'utils/string'

import {
  DATE_FORMATS,
  formatDate,
  extractDays,
  getYearsFromDates
} from 'utils/date'

import NextClassessTable from './NextClassesTable'

const NextClassesModal = ({ event, title }) => {
  const [open, setOpen] = useState(false)
  const [newClasses, setClasses] = useState([])

  const eventStudentId = event.student_item_id

  const extractedDays = extractDays(newClasses)

  const { t } = useTranslation()

  const formattedTitle = removeUnderscoreAndAddSpace(title)

  const currentCourse: Course = useSelector(getCurrentCourse)

  const accessibleFrom = currentCourse?.accessible_from

  const accessibleTo = currentCourse?.accessible_to

  const years = getYearsFromDates(accessibleFrom, accessibleTo)
  const formattedEndDate = formatDate(accessibleTo, DATE_FORMATS.dashed)

  const fetchNextClasses = async () => {
    const setClassesSet = new Set()

    for (const year of years) {
      const response = await fetchAvailableClasses(
        currentCourse.original.id,
        year,
        eventStudentId
      )

      // Assuming response has classes in an array; add them to the set
      if (Array.isArray(response.data)) {
        response.data.forEach(classData =>
          setClassesSet.add(JSON.stringify(classData))
        ) // Using JSON string for uniqueness
      }
    }

    // Convert the Set back to an array of objects
    const uniqueClasses = Array.from(setClassesSet).map(classData =>
      JSON.parse(classData)
    )
    return uniqueClasses
  }
  const handleOpen = async () => {
    setOpen(true)
    const classes = await fetchNextClasses()
    setClasses(classes) // Update state with the unique classes
  }
  const handleClose = () => setOpen(false)

  return (
    <>
      <NextClassesButton onClick={handleOpen}>
        {t('calendar.button.optionalClassTime')}
      </NextClassesButton>
      <Modal
        data-testid='NextClassesModal-Button-Modal'
        open={open}
        handleClose={handleClose}
        title={
          <Trans i18nKey='calendar.nextClasses.title'>0{formattedTitle}2</Trans>
        }
        hasBorder
      >
        <>
          <DescWrapper>
            <Trans i18nKey='calendar.nextClasses.description'>
              0{formattedEndDate}2
            </Trans>
          </DescWrapper>

          <Table>
            <thead>
              <tr>
                <HeaderCell>
                  {' '}
                  {t('calendar.nextClasses.table.headers.date')}
                </HeaderCell>
                <HeaderCell>
                  {' '}
                  {t('calendar.nextClasses.table.headers.courseGroup')}
                </HeaderCell>
                <HeaderCell>
                  {' '}
                  {t('calendar.nextClasses.table.headers.action')}
                </HeaderCell>
              </tr>
            </thead>
            <NextClassessTable
              extractedDays={extractedDays}
              event={event}
              handleClose={handleClose}
            />
          </Table>
        </>
      </Modal>
    </>
  )
}

export default NextClassesModal

const NextClassesButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-size: 11px;
  text-decoration: underline;
  cursor: pointer;
`

const DescWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  width: 514px;
  margin-left: 30px;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`

const HeaderCell = styled.th`
  border-bottom: 1px solid #ddd;
  padding: 8px;
  text-align: left;
`
