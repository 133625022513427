import APIService from 'services/APIService'
import {
  ChangeTargetScorePayload,
  ChangeSectionTargetScorePayload,
  ResetTargetScorePayload,
  ShortStudentsActivityTimePayload,
  LongStudentsActivityTimePayload
} from 'types'

const api = APIService()

//
// Student target scores
//

// Change the target score for an exam
export const changeTargetScore = (payload: ChangeTargetScorePayload) =>
  api.patch(`/students/change-target-score/${payload.id}`, {
    value: payload.value
  })

// Change the target score for an exam section
export const changeSectionTargetScore = (
  payload: ChangeSectionTargetScorePayload
) =>
  api.patch(
    `/students/change-section-target-score/${payload.id}/${payload.order}`,
    { value: payload.value }
  )

// Reset the target score for an exam
export const resetTargetScore = (payload: ResetTargetScorePayload) =>
  api.patch(`/students/reset-target-score/${payload.exam_id}`)

//
// Student navigation
//

// Fetch student navigation
export const fetchNavigation = () => api.get('/students/exams/nav')

//
// Student's activity in the service
//
export const shortStudentActivity = (
  payload: ShortStudentsActivityTimePayload
) => api.patch(`/students/bump-site-activity`, { duration: payload.duration })

export const longStudentActivity = (payload: LongStudentsActivityTimePayload) =>
  api.patch(`/students/bump-site-activity`, { duration: payload.duration })

//
// Student's last 6 salty bucks earnings
//

export const fetchStudentsLastEarnings = () =>
  api.get(
    `students/salty-bucks-logs?order[by]=created_at&order[dir]=desc&limit[take]=6&limit[page]=1`
  )

export const markIsGettingStartedCompleted = () =>
  api.patch('/students/getting-started-completed')
