import * as R from 'ramda'

// Function to remove duplicates and save the middle student's value
export const removeDuplicatesAndSaveMiddleStudentsValue = scores => {
  // Find the index of the first element with 'students' > 0
  const firstIndex = R.findIndex(R.propSatisfies(R.lt(0), 'students'))(scores)

  // Find the index of the last element with 'students' > 0 by reversing the array
  const lastIndex_ = R.findIndex(R.propSatisfies(R.lt(0), 'students'))(
    R.reverse(scores)
  )

  // Adjust the last index to match the original array
  const lastIndex = scores.length - lastIndex_

  // Slice the array from the first to the last index
  const scoresB: any = R.slice(firstIndex, lastIndex, scores)
  const length = scoresB.length
  const middle = length / 2
  const middleIndex = Math.floor(middle) - 1

  // Iterate over the sliced array
  scoresB.forEach((score, index) => {
    // Keep the 'students' value for the middle element or if there's only one element
    if (index === middleIndex || length === 1) {
      return score
    } else {
      // Set 'students' to 0 for all other elements
      score.students = 0
    }
  })

  // Return the modified scores array
  return scores
}
