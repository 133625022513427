import React, { useCallback, useEffect, useReducer } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation, Trans } from 'react-i18next'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
// import * as R from 'ramda'
import PATHS from 'utils/paths'

import LocalStorageService from 'services/LocalStorageService'
import { fetchSingleCourse } from 'services/CourseService'
import { showToastRoutine } from 'modules/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'

import { BouncingLoader, Heading1, Heading2 } from 'examkrackers-components'

import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { pathOr } from 'ramda'
// import { getCurrentCourse } from 'modules/courses/ducks/selectors'
// import { Course } from 'types'

const initialState = {
  isReady: false,
  isOnDemand: false,
  isFreeTrial: false,
  isLiveCourse: false,
  isCalendarAvailable: false
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_IS_READY':
      return { ...state, isReady: action.payload }
    // case 'SET_IS_ON_DEMAND':
    //   return { ...state, isOnDemand: action.payload }
    // case 'SET_IS_FREE_TRIAL':
    //   return { ...state, isFreeTrial: action.payload }
    // case 'SET_IS_LIVE_COURSE':
    //   return { ...state, isLiveCourse: action.payload }
    // case 'SET_IS_CALENDAR_AVAILABLE':
    //   return { ...state, isCalendarAvailable: action.payload }
    default:
      return state
  }
}

const DashboardLoading = () => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const dispatch = useDispatch()
  const [state, localDispatch] = useReducer(reducer, initialState)
  // const currentCourse: Course = useSelector(getCurrentCourse)
  // const isPrioriDays = R.propOr(false, 'prioridays', currentCourse)
  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const checkIfReady = () => {
    const courseId = LocalStorageService.get(LOCAL_STORAGE_KEYS.studentCourseId)

    const handleSuccess = response => {
      // const isCalendarAvailable = response.data.original.is_calendar_enabled
      // localDispatch({
      //   type: 'SET_IS_CALENDAR_AVAILABLE',
      //   payload: isCalendarAvailable
      // })
      const isCourseReady = pathOr(false, ['data', 'is_ready'], response)
      localDispatch({ type: 'SET_IS_READY', payload: isCourseReady })
      // const isOnDemandType = response.data.type === 'on_demand'
      // const isFreeTrialType = response.data.type === 'free_trial'
      // const isLiveCourseType = response.data.type === 'live_course'

      // localDispatch({ type: 'SET_IS_ON_DEMAND', payload: isOnDemandType })
      // localDispatch({ type: 'SET_IS_FREE_TRIAL', payload: isFreeTrialType })
      // localDispatch({ type: 'SET_IS_LIVE_COURSE', payload: isLiveCourseType })
    }
    const handleError = e => {
      console.error(e)
      push(PATHS.selectCourse)
      showToast({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    }

    fetchSingleCourse({ id: courseId }).then(handleSuccess).catch(handleError)
  }

  useEffect(() => {
    const handleCheckIfReady = setInterval(checkIfReady, 5000)

    return () => {
      clearInterval(handleCheckIfReady)
    }
  }, [])
  // changed for new init flow
  //reroute students to the calendar first
  // useEffect(() => {
  //   if (state.isReady && state.isOnDemand && state.isCalendarAvailable) {
  //     push({
  //       pathname: PATHS.calendarSetup
  //     })
  //   } else if (
  //     state.isReady &&
  //     state.isFreeTrial &&
  //     state.isCalendarAvailable
  //   ) {
  //     push({
  //       pathname: PATHS.calendarSetupFreeTrial
  //     })
  //   } else if (
  //     state.isReady &&
  //     state.isLiveCourse &&
  //     state.isCalendarAvailable
  //   ) {
  //     push(PATHS.calendarSetupExpiration)
  //   } else if (
  //     state.isReady &&
  //     !state.isOnDemand &&
  //     !state.isFreeTrial &&
  //     !state.isLiveCourse &&
  //     state.isCalendarAvailable &&
  //     isPrioriDays
  //   ) {
  //     push(PATHS.calendar)
  //   } else if (state.isReady && !state.isCalendarAvailable) {
  //     state.isLiveCourse || state.isOnDemand
  //       ? push(PATHS.dashboard)
  //       : push(PATHS.exams)
  //   }
  // }, [state])

  //reroute students to dashboard first
  useEffect(() => {
    if (state.isReady) {
      push({
        pathname: PATHS.dashboard
      })
    }
  }, [state])

  return (
    <>
      <Helmet>
        <title>{t('pages.dashboard')}</title>
      </Helmet>
      <Container>
        <StyledBouncingLoader />
        <div className='title'>
          <Heading1>{t('courses.loadingCourse.heading')}</Heading1>
          <Heading2>
            <Trans
              i18nKey='courses.loadingCourse.description'
              components={{ Bold: <Bold /> }}
            />
          </Heading2>
        </div>
      </Container>
    </>
  )
}

export default DashboardLoading

const Container = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    font-size: 35px;
    color: ${({ theme }) => theme.colors.main.error500};
    margin-bottom: 20px;
  }
`

const StyledBouncingLoader = styled(BouncingLoader)`
  margin-right: 20px;
`

const Bold = styled.strong`
  font-weight: 700;
`
