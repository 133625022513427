import React, { useContext, useState, useCallback } from 'react'
import styled from 'styled-components'
import { Button, Modal, TrashIcon } from 'examkrackers-components'
import {
  CalendarEventTypes,
  removeCalendarEvent
} from 'services/CalendarService'
import { Trans, useTranslation } from 'react-i18next'
import { CalendarContext } from 'hooks/CalendarContext'
import { useDispatch } from 'react-redux'
import { showToastRoutine } from 'modules/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'
import { removeUnderscoreAndAddSpace } from 'utils/string'

interface RemoveCalendarEventModalProps {
  event: CalendarEventTypes
  onSelect: () => void
  isArchived?: boolean
  isCustomLiveClassType?: boolean
}

const RemoveCalendarEventModal = ({
  event,
  onSelect,
  isArchived,
  isCustomLiveClassType
}: RemoveCalendarEventModalProps) => {
  const { detachEventFromDate, removeInactiveLiveClass } =
    useContext(CalendarContext)
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const dispatch = useDispatch()

  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    onSelect()
    setOpen(false)
  }

  const handleSubmit = async () => {
    try {
      // @ts-ignore
      const response = await removeCalendarEvent({ id: event.id })

      if ([200, 201].includes(response.status)) {
        detachEventFromDate(event)
        if (isCustomLiveClassType) {
          removeInactiveLiveClass(event?.parent_event_id || '')
          showToast({
            key: 'toast.removeExtraLiveClassSuccess',
            severity: SEVERITY.success
          })
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      {!isArchived && <StyledTrashIcon onClick={handleOpen} />}
      <Modal
        data-testid='RemoveCalendarEventModal-Modal'
        open={open}
        title={t('calendar.removeCustomEventModal.title')}
        handleClose={handleClose}
      >
        <ModalContent data-testid='RemoveCalendarEventModal-ModalContent'>
          <Description data-testid='RemoveCalendarEventModal-Description'>
            <Trans
              data-testid='RemoveCalendarEventModal-Trans'
              i18nKey='calendar.removeCustomEventModal.description'
              values={{ event: event.title }}
            >
              0
              <EventTitle data-testid='RemoveCalendarEventModal-EventTitle'>
                {isCustomLiveClassType
                  ? removeUnderscoreAndAddSpace(event.title)
                  : event.title}
              </EventTitle>
            </Trans>
          </Description>
          <ButtonsWrapper data-testid='RemoveCalendarEventModal-ButtonsWrapper'>
            <Button
              variant='outlined'
              onClick={handleClose}
              data-testid='RemoveCalendarEventModal-Button-Cancel'
            >
              Cancel
            </Button>
            <Button
              data-testid='RemoveCalendarEventModal-Button-Remove'
              onClick={() => {
                handleSubmit()
                handleClose()
              }}
            >
              Remove
            </Button>
          </ButtonsWrapper>
        </ModalContent>
      </Modal>
    </>
  )
}

export default RemoveCalendarEventModal

const ModalContent = styled.div`
  .react-datepicker-wrapper {
    position: absolute;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 20px;
`

const Description = styled.div`
  max-width: 460px;

  span {
    color: ${({ theme }) => theme.colors.main.primary500};
    font-weight: bold;
  }
`

const StyledTrashIcon = styled(TrashIcon)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  padding-top: 1px;
  transition: all 0.3s;
  color: ${({ theme }) => theme.colors.main.primary500};

  &:hover {
    color: ${({ theme }) => theme.colors.main.primary700};
  }
`
const EventTitle = styled.span``
