import * as R from 'ramda'
import { CalendarIcon } from 'examkrackers-components'
import { CalendarContext } from 'hooks/CalendarContext'
import CalendarDayEventsList from 'modules/calendar/CalendarDayEventsList'
import React, { FC, useContext } from 'react'
import {
  apiMove,
  apiMoveToArchive,
  apiReorder,
  ARCHIVE_TAB_TYPES,
  uiMove,
  uiMoveToArchive,
  uiReorder
} from 'utils/calendar'
import { useDispatch, useSelector } from 'react-redux'
import {
  addManualCalendarTaskRoutine,
  setManualCalendarActiveTabRoutine,
  updateManualCalendarTasksRoutine
} from 'modules/calendar/ducks/actions'
import { showToastRoutine } from 'modules/toast/ducks/actions'
import { SEVERITY } from 'utils/toast'
import { equals } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import { DragDropContext } from 'react-beautiful-dnd'
import { formatMinutesToHoursAndMinutes } from 'utils/date'
import {
  Card,
  CardContent,
  CardHeader,
  Icon,
  Title,
  ScrollableContainer,
  NoEventsContainer,
  NoEventsMessage,
  TotalDurationContainer,
  IconContainer
} from './Calendar.styles'
import { getStudent } from 'modules/auth/ducks/selectors'

const Calendar: FC = () => {
  const { lists, setLists, archiveEvents } = useContext(CalendarContext)
  const dispatch = useDispatch()
  // eg. 2024-06-10
  const currentlyChecked = new Date().toISOString().split('T')[0]
  const studentDetails = useSelector(getStudent)
  const dashboardTheme = R.propOr('light', 'theme', studentDetails)
  const isDarkTheme = dashboardTheme === 'dark'

  const onDragEnd = async result => {
    const { source, destination, draggableId } = result

    const normalizedDraggableId = draggableId.replace('-custom', '')

    const sourceId = source.droppableId
    const destinationId = destination.droppableId
    const isFromArchive = sourceId.includes('events-archive')
    const isToArchive = destinationId.includes('events-archive')

    // no destination
    if (!destination) {
      return
    }

    // Archive reorder
    if (isFromArchive && isToArchive) {
      if (source.droppableId === destination.droppableId) {
        const uiReorderItems = uiReorder(
          archiveEvents,
          source.index,
          destination.index
        )

        dispatch(
          updateManualCalendarTasksRoutine({
            list: uiReorderItems
          })
        )
      }

      return
    }

    // Move from archive to calendar
    if (isFromArchive && !isToArchive) {
      const uiMoveResult = uiMove(
        archiveEvents,
        lists[destinationId],
        source,
        destination,
        true,
        destinationId
      )

      dispatch(
        updateManualCalendarTasksRoutine({
          list: uiMoveResult.source
        })
      )

      setLists(prev => ({
        ...prev,
        [destinationId]: uiMoveResult.destination
      }))

      const apiMoveResult = await apiMove(
        archiveEvents,
        lists[destinationId],
        source,
        destination,
        normalizedDraggableId,
        true,
        false
      )

      if (
        !uiMoveResult.source.every((_, index) => {
          return (
            uiMoveResult.source[index].event_date ===
            apiMoveResult.source[index].event_date
          )
        }) ||
        !uiMoveResult.destination.every((_, index) => {
          return (
            uiMoveResult.destination[index].event_date ===
            apiMoveResult.destination[index].event_date
          )
        })
      ) {
        setLists(prev => ({
          ...prev,
          [destinationId]: apiMoveResult.destination
        }))

        dispatch(
          updateManualCalendarTasksRoutine({
            list: apiMoveResult.source
          })
        )
      }

      return
    }
    // From calendar to archive
    if (!isFromArchive && isToArchive) {
      if (draggableId.includes('custom')) {
        dispatch(
          showToastRoutine({
            key: 'It is not possible to archive a custom event',
            severity: SEVERITY.warning
          })
        )

        return
      } else {
        const uiMoveResult = uiMoveToArchive(lists[sourceId], source)

        setLists(prev => ({
          ...prev,
          [sourceId]: uiMoveResult.source
        }))

        const apiMoveResult = await apiMoveToArchive(
          lists[sourceId],
          source,
          normalizedDraggableId
        )

        if (isNotNilOrEmpty(apiMoveResult.event)) {
          dispatch(
            addManualCalendarTaskRoutine({
              event: apiMoveResult.event
            })
          )

          const tab = ARCHIVE_TAB_TYPES[apiMoveResult.event.type]

          dispatch(setManualCalendarActiveTabRoutine({ activeTab: tab }))
        }

        if (!equals(uiMoveResult.source, apiMoveResult.source)) {
          setLists(prev => ({
            ...prev,
            [sourceId]: apiMoveResult.source
          }))
        }

        return
      }
    }

    // Calendar Moves
    if (!isFromArchive && !isToArchive) {
      // Same cell
      if (source.droppableId === destination.droppableId) {
        const uiReorderItems = uiReorder(
          lists[sourceId],
          source.index,
          destination.index
        )

        setLists(prev => ({
          ...prev,
          [sourceId]: uiReorderItems
        }))

        const apiReorderItems = await apiReorder(
          lists[sourceId],
          source.index,
          destination.index
        )

        if (!equals(uiReorderItems, apiReorderItems)) {
          setLists(prev => ({
            ...prev,
            [sourceId]: apiReorderItems
          }))
        }
      } else {
        // Different cell
        const uiMoveResult = uiMove(
          lists[sourceId],
          lists[destinationId],
          source,
          destination
        )

        setLists(prev => ({
          ...prev,
          [sourceId]: uiMoveResult.source,
          [destinationId]: uiMoveResult.destination
        }))

        const apiMoveResult = await apiMove(
          lists[sourceId],
          lists[destinationId],
          source,
          destination,
          normalizedDraggableId
        )

        if (
          !uiMoveResult.source.every((_, index) => {
            return (
              uiMoveResult.source[index].event_date ===
              apiMoveResult.source[index].event_date
            )
          }) ||
          !uiMoveResult.destination.every((_, index) => {
            return (
              uiMoveResult.destination[index].event_date ===
              apiMoveResult.destination[index].event_date
            )
          })
        ) {
          setLists(prev => ({
            ...prev,
            [sourceId]: apiMoveResult.source,
            [destinationId]: apiMoveResult.destination
          }))
        }
      }
    }
  }

  const getTotalDuration = () => {
    if (!lists[currentlyChecked] || !Array.isArray(lists[currentlyChecked])) {
      return 0
    }
    const totalDuration = lists[currentlyChecked].reduce(
      (acc: number, curr) => {
        return acc + ((curr as { duration?: number }).duration || 0)
      },
      0
    )
    return totalDuration
  }

  return (
    <Card $isDark={isDarkTheme}>
      <CardContent>
        <CardHeader $isDark={isDarkTheme}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon>
              <IconContainer>
                <CalendarIcon />
              </IconContainer>
            </Icon>
            <Title $isDark={isDarkTheme}>Today's Calendar</Title>
          </div>
          <TotalDurationContainer $isDark={isDarkTheme}>
            <span>{formatMinutesToHoursAndMinutes(getTotalDuration())}</span>
          </TotalDurationContainer>
        </CardHeader>
        <ScrollableContainer $isDark={isDarkTheme}>
          <DragDropContext onDragEnd={onDragEnd}>
            {lists[currentlyChecked] && lists[currentlyChecked].length > 0 ? (
              <CalendarDayEventsList
                currentlyChecked={currentlyChecked}
                shouldDisplayEndDate={false}
                isDashboard
                isDarkTheme={isDarkTheme}
              />
            ) : (
              <NoEventsContainer $isDark={isDarkTheme}>
                <NoEventsMessage $isDark={isDarkTheme}>
                  No events today
                </NoEventsMessage>
              </NoEventsContainer>
            )}
          </DragDropContext>
        </ScrollableContainer>
      </CardContent>
    </Card>
  )
}

export default Calendar
