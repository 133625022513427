import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import styled from 'styled-components'

import { isNotNilOrEmpty } from 'utils/ramda'

import { fetchExamDetailsRoutine } from 'modules/exam/ducks/actions'

import { getExamDetails } from 'modules/exam/ducks/selectors'
import { BouncingLoader } from 'examkrackers-components'
import {
  getExamDiagramData,
  getExamDiagramDataForAllSections
} from 'services/ExamsService'
import { ExamDetails } from 'types'
import PercentileBars from './PercentileBars'
import RawScoreBars from './RawScoreBars'

import RechartsPercentileGraph from './RechartsPercentileGraph'
import { EXAM_DIAGRAM_COLOR_ACCENTS } from 'pages/ExamScoreSheet'

const LoadingState = {
  initial: 'initial',
  loading: 'loading',
  fulfilled: 'fulfilled',
  error: 'error'
}

export const ScoreSheet = ({ children }): JSX.Element => {
  const params = useParams()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(LoadingState.initial)
  const [examDiagramData, setExamDiagramData] = useState({})

  const [sectionsScoreDeifnitions, setSectionsScoreDefinitions] = useState<any>(
    []
  )

  const isLoading =
    loading === LoadingState.loading || loading === LoadingState.initial

  const id: string = R.propOr('', 'id')(params)

  const examDetails = useSelector(getExamDetails) as ExamDetails

  const fetchExamDetails = React.useCallback(
    () => dispatch(fetchExamDetailsRoutine({ id })),
    [dispatch]
  )

  const handleFetchDiagramData = async () => {
    if (!isNotNilOrEmpty(examDetails)) return
    const response = await getExamDiagramData({
      id: examDetails?.exam.originalExam.id
    })
    const data = await response.data
    return data
  }

  const handleFetchSectionsScoreDefinitions = async () => {
    if (!isNotNilOrEmpty(examDetails)) return
    const response = await getExamDiagramDataForAllSections({
      id: examDetails?.exam.originalExam.id
    })
    const data = await response.data
    return data
  }

  useEffect(() => {
    if (loading === LoadingState.initial) {
      fetchExamDetails()
    }
    if (isNotNilOrEmpty(examDetails?.exam.originalExam.id)) {
      handleFetchDiagramData()
        .then(data => {
          setExamDiagramData(data)
        })
        .catch(error => {
          console.error('Error fetching data:', error)
        })

      handleFetchSectionsScoreDefinitions()
        .then(data => {
          setSectionsScoreDefinitions(data)
          setLoading(LoadingState.fulfilled)
        })
        .catch(error => {
          console.error('Error fetching data:', error)
        })
    }
  }, [examDetails])

  if (isLoading) {
    return <BouncingLoader />
  }

  return (
    <Container loading={isLoading}>
      <LoadingContainer loading={isLoading}>
        <BouncingLoader />
      </LoadingContainer>
      <ChartWithTilesContainer>
        <Tiles>
          <TilesWrapper>{children}</TilesWrapper>
        </Tiles>
        <DataGrid>
          <RechartsPercentileGraph
            examDetails={examDetails}
            data={examDiagramData}
            score={examDetails.exam?.scores?.exam?.scaled_score}
            barColor={EXAM_DIAGRAM_COLOR_ACCENTS.overall}
            isMainDiagram
          />
          <SectionChartsContainer>
            <RechartsPercentileGraph
              examDetails={examDetails}
              data={sectionsScoreDeifnitions[0]}
              score={examDetails.exam?.scores?.sections[0].scaled_score}
              title={examDetails.exam?.scores?.sections[0].title}
              barColor={EXAM_DIAGRAM_COLOR_ACCENTS.first_section}
              sectionId={examDetails.exam?.scores?.sections[0].id}
            />
            <RechartsPercentileGraph
              examDetails={examDetails}
              data={sectionsScoreDeifnitions[1]}
              score={examDetails.exam?.scores?.sections[1].scaled_score}
              title={examDetails.exam?.scores?.sections[1].title}
              barColor={EXAM_DIAGRAM_COLOR_ACCENTS.second_section}
              sectionId={examDetails.exam?.scores?.sections[1].id}
            />
            <RechartsPercentileGraph
              examDetails={examDetails}
              data={sectionsScoreDeifnitions[2]}
              score={examDetails.exam?.scores?.sections[2].scaled_score}
              title={examDetails.exam?.scores?.sections[2].title}
              barColor={EXAM_DIAGRAM_COLOR_ACCENTS.third_section}
              sectionId={examDetails.exam?.scores?.sections[2].id}
            />
            <RechartsPercentileGraph
              examDetails={examDetails}
              data={sectionsScoreDeifnitions[3]}
              score={examDetails.exam?.scores?.sections[3].scaled_score}
              title={examDetails.exam?.scores?.sections[3].title}
              barColor={EXAM_DIAGRAM_COLOR_ACCENTS.fourth_section}
              sectionId={examDetails.exam?.scores?.sections[3].id}
            />
          </SectionChartsContainer>
          <PercentileBars examDetails={examDetails} />
          <RawScoreBars examDetails={examDetails} />
        </DataGrid>
      </ChartWithTilesContainer>
    </Container>
  )
}

export default ScoreSheet

const Container = styled.div`
  max-height: 584px;
  display: flex;
  gap: 16px;
  position: relative;
  overflow: ${({ loading }) => (loading ? 'hidden' : 'unset')};
`

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndex.menu};
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  animation: timed-out-opacity 3500ms ease-in;
  display: ${({ loading }) => (loading ? 'flex' : 'none')};

  @keyframes timed-out-opacity {
    0% {
      opacity: 1;
      display: flex;
    }
    70% {
      opacity: 1;
      display: flex;
    }
    99% {
      opacity: 0;
      display: flex;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
`
const ChartWithTilesContainer = styled.div`
  display: grid;
  grid-template-columns: 152px 1fr;
  width: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 6px;
`

const DataGrid = styled.div`
  display: grid;
  grid-template-columns: 528px 528px;
  grid-template-rows: 319px 215px;
  gap: 16px;
  padding: 16px 16px 16px 0;

  .section-diagram {
    display: flex;
    flex-direction: column;
    /* gap: 2px; */
    padding-bottom: 16px;
  }
`

const Tiles = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 16px;
  height: 100%;
  gap: 15px;
`

const SectionChartsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
`

const TilesWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  gap: 16px;
  padding: 8px;
`
