import React, { useState } from 'react'
import {
  Button,
  Modal,
  BouncingLoader,
  RadioButton
} from 'examkrackers-components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { rescheduleExamDate, setPrioriDays } from 'services/CalendarService'
import PATHS from 'utils/paths'
import { radioButtonOptions, countStudyDaysInRange } from 'utils/calendar'
import { useHistory } from 'react-router-dom'

import ConfirmationSalty from 'assets/images/Calendar_Reorder_Confirmation_Saltys.svg'
import WarningCalendar from 'assets/images/WarningCalendar.svg'

interface ReorderCalendarConfirmationModalProps {
  values: {
    calendar_start_at: string
    exam_at: string
    prioriDays: number[]
    mcat_at: string
    pre_reading_end_date: string
  }
  disabled: boolean
  isLiveCourse?: boolean
  hasStudentPreReading: boolean
}

const ReorderCalendarConfirmationModal = ({
  values,
  disabled,
  isLiveCourse,
  hasStudentPreReading
}: ReorderCalendarConfirmationModalProps) => {
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedConfirmationOption, setSelectedConfirmationOption] = useState(
    radioButtonOptions.reject.value
  )

  const { t } = useTranslation()
  const { push } = useHistory()

  const studyDaysCount = countStudyDaysInRange(
    values.calendar_start_at,
    values.exam_at,
    values.prioriDays
  )
  const handleSaveDate = async () => {
    setIsLoading(true)
    const courseId = LocalStorageService.get(LOCAL_STORAGE_KEYS.studentCourseId)

    try {
      await setPrioriDays({
        id: courseId,
        prioridays: values.prioriDays
      })
      await rescheduleExamDate({
        exam_at: values.exam_at,
        course_id: courseId,
        mcat_date_id: values.mcat_at,
        build_calendar: -2,
        study_days: studyDaysCount
      })

      setIsLoading(false)
      handleClose()
      push(PATHS.calendar)
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  const handleSaveDateWithPreReading = async () => {
    setIsLoading(true)
    const courseId = LocalStorageService.get(LOCAL_STORAGE_KEYS.studentCourseId)
    try {
      await setPrioriDays({
        id: courseId,
        prioridays: values.prioriDays
      })
      await rescheduleExamDate({
        exam_at: values.exam_at,
        course_id: courseId,
        mcat_date_id: values.mcat_at,
        build_calendar: -2,
        date_end: values.pre_reading_end_date,
        is_pre_reading: true,
        study_days: studyDaysCount
      })

      setIsLoading(false)
      handleClose()
      push(PATHS.calendar)
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  const handleSaveDateWithoutPreReading = async () => {
    setIsLoading(true)
    const courseId = LocalStorageService.get(LOCAL_STORAGE_KEYS.studentCourseId)
    try {
      await rescheduleExamDate({
        exam_at: values.exam_at,
        course_id: courseId,
        mcat_date_id: values.mcat_at,
        build_calendar: -3,
        // date_end: values.exam_at, - this key triggered building pre-reading calendar events
        is_pre_reading: false,
        study_days: studyDaysCount
      })

      setIsLoading(false)
      handleClose()
      push(PATHS.calendar)
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }
  const handleSelectSubmit = () => {
    if (isLiveCourse && hasStudentPreReading) {
      handleSaveDateWithPreReading()
    } else if (isLiveCourse && !hasStudentPreReading) {
      handleSaveDateWithoutPreReading()
    } else {
      handleSaveDate()
    }
  }
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleCancel = () => {
    handleClose()
    setSelectedConfirmationOption(radioButtonOptions.reject.value)
  }
  const isCheckboxChecked =
    selectedConfirmationOption === radioButtonOptions.confirm.value

  const handleChangeForConfirmation = (value: string) =>
    setSelectedConfirmationOption(value)

  return (
    <>
      <ButtonContainer
        color='secondary'
        onClick={handleOpen}
        disabled={disabled}
      >
        {t('calendar.reorder.button')}
      </ButtonContainer>
      <Modal
        open={open}
        handleClose={isLoading ? () => {} : handleClose}
        hasBorder
        title={
          isLoading
            ? t('calendar.reset.modal.loading')
            : t('calendar.reset.modal.title')
        }
        titleIcon={
          isLoading ? null : (
            <TitleIconContainer data-testid='ReorderCalendarConfirmationModal-TitleIconContainer'>
              <TitleIconWrapper data-testid='ReorderCalendarConfirmationModal-TitleIconWrapper'>
                <WarningCalendarWrapper data-testid='ReorderCalendarConfirmationModal-WarningCalendarWrapper'>
                  <WarningCalendarIcon
                    data-testid='ReorderCalendarConfirmationModal-WarningCalendarIcon'
                    id='warning'
                    src={WarningCalendar}
                    alt='Warning'
                  />
                </WarningCalendarWrapper>
              </TitleIconWrapper>
            </TitleIconContainer>
          )
        }
      >
        {isLoading ? (
          <LoaderContainer data-testid='ReorderCalendarConfirmationModal-LoaderContainer'>
            <BouncingLoader data-testid='ReorderCalendarConfirmationModal-BouncingLoader' />
          </LoaderContainer>
        ) : (
          <div>
            <MainWrapper data-testid='ReorderCalendarConfirmationModal-MainWrapper'>
              <TextWrapper data-testid='ReorderCalendarConfirmationModal-TextWrapper'>
                <DescWrapper data-testid='ReorderCalendarConfirmationModal-DescWrapper-descriptionOne'>
                  {t('calendar.reorder.modal.descriptionOne')}
                </DescWrapper>
                <DescWrapper data-testid='ReorderCalendarConfirmationModal-DescWrapper-descriptionTwo'>
                  {t('calendar.reorder.modal.descriptionTwo')}
                </DescWrapper>
                <DescWrapper data-testid='ReorderCalendarConfirmationModal-DescWrapper-descriptionThree'>
                  {t('calendar.reorder.modal.descriptionThree')}
                </DescWrapper>

                <RadioButtonContainer data-testid='ReorderCalendarConfirmationModal-DescWrapper-RadioButtonContainer'>
                  <RadioButton
                    data-testid='ReorderCalendarConfirmationModal-DescWrapper-RadioButton-cnfirmationYes'
                    alignTop
                    label={t('calendar.reorder.modal.confirmation')}
                    name='confirmationYes'
                    value={radioButtonOptions.confirm.value}
                    isChecked={
                      selectedConfirmationOption ===
                      radioButtonOptions.confirm.value
                    }
                    onChange={handleChangeForConfirmation}
                  />
                </RadioButtonContainer>
              </TextWrapper>
              <ConfirmationSaltyImage
                data-testid='ReorderCalendarConfirmationModal-ConfirmationSaltyImage'
                id='reorganize-salty'
                src={ConfirmationSalty}
                alt='Salty reorganize'
              />
            </MainWrapper>
            <ButtonsWrapper data-testid='ReorderCalendarConfirmationModal-ButtonsWrapper'>
              <ButtonWrapper
                color='tertiary'
                onClick={handleCancel}
                data-testid='ReorderCalendarConfirmationModal-ButtonWrapper-cancel'
              >
                {t('calendar.button.cancel')}
              </ButtonWrapper>
              <ButtonWrapper
                data-testid='ReorderCalendarConfirmationModal-Button-reschedule'
                color='secondary'
                onClick={handleSelectSubmit}
                disabled={!isCheckboxChecked}
              >
                {t('calendar.button.reschedule')}
              </ButtonWrapper>
            </ButtonsWrapper>
          </div>
        )}
      </Modal>
    </>
  )
}

export default ReorderCalendarConfirmationModal

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 65px;
  justify-content: center;
`
const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ConfirmationSaltyImage = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 112px;
  width: 145px;
`
const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 736px;
  padding: 0 86px;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  width: 338px;

  margin-right: 75px;
`
const DescWrapper = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 10px;
`
const ButtonWrapper = styled(Button)`
  width: 158px;
`
const ButtonContainer = styled(Button)`
  width: 158px;
`
const TitleIconContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
`

const TitleIconWrapper = styled.div`
  width: 68px;
  height: 68px;
  background-color: ${({ theme }) => theme.colors.main.primary500};
  border-radius: 50%;
`
const WarningCalendarIcon = styled.img``
const WarningCalendarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`
const RadioButtonContainer = styled.div`
  margin-top: 10px;
`
