import { EXAM_DIAGRAM_COLOR_ACCENTS } from 'pages/ExamScoreSheet'
import React from 'react'
import styled from 'styled-components'

type PercentileBarsProps = {
  examDetails: any
  nonFLexam?: boolean
}

const PercentileBars = ({ examDetails, nonFLexam }: PercentileBarsProps) => {
  return (
    <PercentileBarsWrapper>
      <PercentileBarsTitle>
        <h4>Percentile Rank</h4>
        <p>
          <span>{examDetails?.exam?.scores?.exam?.percentile_rank}%</span>{' '}
          overall
        </p>
      </PercentileBarsTitle>
      <Bars>
        <MainExamBar>
          <PercentileBar>
            <p>{examDetails?.exam?.scores?.exam?.percentile_rank}%</p>
            <BackgroundBar color={EXAM_DIAGRAM_COLOR_ACCENTS.overall}>
              <FillBar
                color={EXAM_DIAGRAM_COLOR_ACCENTS.overall}
                height={parseInt(
                  examDetails.exam?.scores?.exam?.percentile_rank
                )}
              />
            </BackgroundBar>
            <p>
              {examDetails.exam.originalExam.custom_title
                ? examDetails.exam?.originalExam.custom_title
                : examDetails.exam?.title}
            </p>
          </PercentileBar>
        </MainExamBar>
        {nonFLexam ? null : (
          <>
            <Spacer />
            <PercentileBar>
              <p>{examDetails?.exam?.scores?.sections[0].percentile_rank}%</p>
              <BackgroundBar color={EXAM_DIAGRAM_COLOR_ACCENTS.first_section}>
                <FillBar
                  color={EXAM_DIAGRAM_COLOR_ACCENTS.first_section}
                  height={parseInt(
                    examDetails?.exam?.scores?.sections[0].percentile_rank
                  )}
                />
              </BackgroundBar>
              <p>{examDetails?.exam?.scores?.sections[0].title}</p>
            </PercentileBar>
            <PercentileBar>
              <p>{examDetails?.exam?.scores?.sections[1].percentile_rank}%</p>
              <BackgroundBar color={EXAM_DIAGRAM_COLOR_ACCENTS.second_section}>
                <FillBar
                  color={EXAM_DIAGRAM_COLOR_ACCENTS.second_section}
                  height={parseInt(
                    examDetails?.exam?.scores?.sections[1].percentile_rank
                  )}
                />
              </BackgroundBar>
              <p>{examDetails?.exam?.scores?.sections[1].title}</p>
            </PercentileBar>
            <PercentileBar>
              <p>{examDetails?.exam?.scores?.sections[2].percentile_rank}%</p>
              <BackgroundBar color={EXAM_DIAGRAM_COLOR_ACCENTS.third_section}>
                <FillBar
                  color={EXAM_DIAGRAM_COLOR_ACCENTS.third_section}
                  height={parseInt(
                    examDetails?.exam?.scores?.sections[2].percentile_rank
                  )}
                />
              </BackgroundBar>
              <p>{examDetails?.exam?.scores?.sections[2].title}</p>
            </PercentileBar>
            <PercentileBar>
              <p>{examDetails?.exam?.scores?.sections[3].percentile_rank}%</p>
              <BackgroundBar color={EXAM_DIAGRAM_COLOR_ACCENTS.fourth_section}>
                <FillBar
                  color={EXAM_DIAGRAM_COLOR_ACCENTS.fourth_section}
                  height={parseInt(
                    examDetails?.exam?.scores?.sections[3].percentile_rank
                  )}
                />
              </BackgroundBar>
              <p>{examDetails?.exam?.scores?.sections[3].title}</p>
            </PercentileBar>
          </>
        )}
      </Bars>
    </PercentileBarsWrapper>
  )
}

export default PercentileBars

const PercentileBarsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 6px;
`

const PercentileBarsTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 16px;

  h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  p {
    background-color: #fff4d7 !important;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 16px;

    span {
      font-weight: 700;
    }
  }
`

const MainExamBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
`

const Bars = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 16px;
`

const Spacer = styled.div`
  width: 2px;
  background-color: ${({ theme }) => theme.colors.main.grey400};
  height: 100%;
`

const PercentileBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`

const BackgroundBar = styled.div`
  position: relative;

  background-color: ${({ theme }) => theme.colors.main.white};
  border-radius: 6px;
  border: 1px solid
    ${({ theme, color }) => (color ? color : theme.colors.main.primary500)};
  height: 102px;
  width: 30px;
`

const FillBar = styled.div`
  position: absolute;
  bottom: 0;
  border-radius: 4px;
  background-color: ${({ theme, color }) =>
    color ? color : theme.colors.main.primary500};
  height: ${({ height }) => height}%;
  width: 100%;
`
