import { theme } from 'examkrackers-components'
import styled from 'styled-components'

const Congrats = styled.div`
  margin-top: 12px;
  text-align: center;
  font-weight: 600;
  line-height: 1.8;
`

const Card = styled.div<{ isDarkTheme: boolean }>`
  position: relative;
  border-radius: 8px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  background: ${({ theme }) => theme.colors.backgrounds.main} !important;
  width: 400px;
  height: 306px;
  background-color: ${props => (props.isDarkTheme ? '#2C2C2C' : '#FFFFFF')};
`

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`

const Icon = styled.span`
  font-size: 24px;
  margin-right: 6px;
  color: ${({ theme }) => theme.colors.main.white};
  position: relative;
  top: -4px;
`

const Title = styled.h4<{ isDarkTheme: boolean }>`
  margin: 0;
  font-size: 16px;
  position: relative;
  top: -6px;
  color: ${props =>
    props.isDarkTheme ? theme.colors.main.white : theme.colors.main.black};
`

const ProgressContainer = styled.div`
  text-align: center;
  margin-bottom: 16px;
`

const ProgressText = styled.span`
  display: block;
  text-align: left;
`

const ProgressBar = styled.div`
  background-color: #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  height: 8px;
  margin-top: 4px;
`

const Progress = styled.div<{ width: string }>`
  background: linear-gradient(to right, #f15a24, #fbb03b);
  height: 100%;
  width: ${props => props.width};
`

const Tasks = styled.div`
  display: flex;
  flex-direction: column;
`

const Task = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`

const Checkbox = styled.input<{ isDarkTheme: boolean }>`
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid black;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  outline: none;
  margin-right: 8px;

  &:checked {
    background-color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
  }

  &:checked::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-image: ${props =>
      `url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L3.5 6.5L9 1' stroke='${
        props.isDarkTheme ? '%23FFFFFF' : '%23000000'
      }' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")`};
    background-repeat: no-repeat;
    background-position: center;
  }
`

const Label = styled.label<{ checked: boolean }>`
  margin: 0;
  font-size: 16px;
  text-decoration: ${props => (props.checked ? 'line-through' : 'underline')};
  line-height: 1.1;
  cursor: pointer;
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`

const ScrollableTasksContainer = styled.div<{
  showCongrats: boolean
  isDarkTheme: boolean
}>`
  flex-grow: 1;
  overflow-y: auto;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    height: 30px;
    background: ${props =>
      props.isDarkTheme
        ? 'linear-gradient(to top, #2C2C2C, transparent)'
        : 'linear-gradient(to top, white, transparent)'};
    pointer-events: none;
    z-index: 1;
    opacity: ${props => (props.showCongrats ? 1 : 0)};
    transition: opacity 0.3s ease;
  }
`

const FixedCongratsContainer = styled.div<{ isDarkTheme: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${props => (props.isDarkTheme ? '#2C2C2C' : '#FFFFFF')};
  border-radius: 0 0 10px 10px;
`

const CustomLabel = styled.label<{
  checked: boolean
  isLighter: boolean
  isDarkTheme: boolean
}>`
  color: ${props => {
    if (props.isDarkTheme) {
      return props.checked ? '#BDBDBD' : '#FFFFFF'
    }
    return props.checked ? '#949596' : '#000000'
  }};
  transition: color 0.3s ease;
  cursor: pointer;
  text-decoration: ${props => (props.checked ? 'line-through' : 'underline')};
`
export {
  Congrats,
  Card,
  CardHeader,
  Icon,
  Title,
  ProgressContainer,
  ProgressText,
  ProgressBar,
  Progress,
  Tasks,
  Task,
  Checkbox,
  Label,
  CardContent,
  ScrollableTasksContainer,
  FixedCongratsContainer,
  CustomLabel
}
