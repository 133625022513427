import React, { Suspense } from 'react'
import styled, { css } from 'styled-components'
import { BouncingLoader } from 'examkrackers-components'
import { useTranslation } from 'react-i18next'

import { DATE_FORMATS, formatDate } from 'utils/date'

interface IParsedScores {
  exam: {
    amount_correct?: number
    percentile_rank?: number
    scaled_score?: number
  }
  sections: {
    id: string
    title: string
    amount_correct?: number
    percentile_rank: number
    scaled_score: number
    total_amount: number
  }[]
}
const TableRetakeOverall = ({ retakeResults }) => {
  const { t } = useTranslation()

  return (
    <tbody>
      {retakeResults.map((retakeResult, index) => {
        const examId = retakeResult.id
        const examDate = retakeResult.completed_at
        const formattedExamDate = formatDate(examDate, DATE_FORMATS.slash)
        const scores = retakeResult.scores
        const parsedScores: IParsedScores = JSON.parse(scores) as IParsedScores

        // const examAmountCorrect = parsedScores.exam.amount_correct
        const examPercentileRank = parsedScores.exam.percentile_rank
        const examScaledScore = parsedScores.exam.scaled_score
        const totalSectionAmountCorrect = parsedScores.sections.reduce(
          (sum, section) => {
            return sum + (section.amount_correct || 0)
          },
          0
        )
        const totalSectionAmount = parsedScores.sections.reduce(
          (sum, section) => {
            return sum + (section.total_amount || 0)
          },
          0
        )

        return (
          <Suspense key={examId} fallback={<BouncingLoader />}>
            <TableRow>
              <TableCell>
                <OverallWrapper>
                  {t('exams.retakeTable.overall')}
                </OverallWrapper>
              </TableCell>
              <TableCell>{formattedExamDate}</TableCell>
              <TableCell>{`${examPercentileRank} %`}</TableCell>
              <TableCell>{`${totalSectionAmountCorrect} of ${totalSectionAmount}`}</TableCell>
              <TableCell>{examScaledScore}</TableCell>
            </TableRow>
            {parsedScores.sections?.length > 1 &&
              parsedScores.sections.map((section, idx) => {
                const isSingleSection = parsedScores.sections.length === 1
                const sectionId = section.id
                const sectionAmountCorrect = section.amount_correct
                const sectionPercentileRank = section.percentile_rank
                const sectionScaledScore = section.scaled_score
                const sectionTitle = section.title
                const sectionTotalAmount = section.total_amount
                return (
                  <DetailRow
                    key={sectionId}
                    isFirst={idx === 0}
                    isLast={idx === parsedScores.sections.length - 1}
                  >
                    <TableCell>
                      <SectionTitleWrapper>{sectionTitle}</SectionTitleWrapper>
                    </TableCell>
                    <TableCell>{}</TableCell>{' '}
                    {/* Assuming the date remains the same */}
                    <TableCell>{`${
                      isSingleSection
                        ? examPercentileRank
                        : sectionPercentileRank
                    } %`}</TableCell>
                    <TableCell>{`${sectionAmountCorrect} of ${sectionTotalAmount}`}</TableCell>
                    <TableCell>{sectionScaledScore}</TableCell>
                  </DetailRow>
                )
              })}
          </Suspense>
        )
      })}
    </tbody>
  )
}

export default TableRetakeOverall

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
  text-align: left;

  &:hover {
    background-color: #f9f9f9;
  }
`

const TableCell = styled.td`
  padding: 10px;
`
const OverallWrapper = styled.div`
  font-weight: bold;
`
const DetailRow = styled(TableRow)`
  background-color: #fef5e9;

  &:hover {
    background-color: ${({ theme }) => theme.colors.main.primary200};
  }

  ${({ isFirst }) =>
    isFirst &&
    css`
      box-shadow: inset 0 10px 10px -5px rgba(0, 0, 0, 0.1);
    `}

  ${({ isLast }) =>
    isLast &&
    css`
      box-shadow: inset 0 -10px 10px -5px rgba(0, 0, 0, 0.1);
    `}
`
const SectionTitleWrapper = styled.div`
  margin-left: 20px;
`
