import React, { useState } from 'react'
import styled from 'styled-components'
import ModalRetakeExam from './ModalRetakeExam'
import { useDispatch } from 'react-redux'
import { showToastRoutine } from 'modules/toast/ducks/actions'
import { useHistory } from 'react-router-dom'

import { getStartExamError } from 'modules/exams/ducks/errors'
import { startExam } from 'services/ExamsService'

const RetakeExamButton = ({
  numberOfCompletions,
  maxCompletionsNumber,
  id
}) => {
  const [retakeModalOpen, setRetakeModalOpen] = useState(false)
  const handleOpen = () => setRetakeModalOpen(true)
  const handleClose = () => setRetakeModalOpen(false)
  const dispatch = useDispatch()
  const { push } = useHistory()

  const showToast = React.useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const isDisabled = numberOfCompletions >= maxCompletionsNumber

  const redirectToExam = (id: string) => () => {
    push(`/exam/${id}`)
  }

  const handleStartExam = () => {
    const timeOptionElement = document.querySelectorAll(
      `[data-exam-time-option-id="${id}"]`
    )[0]
    const selectedTimeOption =
      timeOptionElement?.getAttribute('data-exam-time-option-value') || '1.0'

    const handleError = e => {
      console.error(e)
      showToast(getStartExamError(e))
    }
    const handleSuccess = redirectToExam(id)

    startExam({ id, timeOption: selectedTimeOption })
      .then(handleSuccess)
      .catch(handleError)
  }

  return (
    <>
      <RetakeButton disabled={isDisabled} onClick={handleOpen}>
        {isDisabled ? 'Retake not available' : 'Retake Exam'}
      </RetakeButton>
      <ModalRetakeExam
        isOpen={retakeModalOpen}
        handleClose={handleClose}
        numberOfCompletions={numberOfCompletions}
        maxCompletionNumber={maxCompletionsNumber}
        handleStartExam={handleStartExam}
      />
    </>
  )
}

export default RetakeExamButton

const RetakeButton = styled.button`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.main.text};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  font-size: 16px;
  text-decoration: underline;
  height: 70%;
  margin: 4px 0 0 8px;
`
