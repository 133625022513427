import React, { useCallback } from 'react'
import { propOr } from 'ramda'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import LocalStorageService from 'services/LocalStorageService'
import { initialiseCourse } from 'services/CourseService'

import { showToastRoutine } from 'modules/toast/ducks/actions'

import { isNotNilOrEmpty } from 'utils/ramda'
import { isDateBeforeToday, DATE_FORMATS, formatDate } from 'utils/date'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import PATHS from 'utils/paths'
import { SEVERITY } from 'utils/toast'

import { getStudent } from 'modules/auth/ducks/selectors'

import { Button } from 'examkrackers-components'

import AnalyticsService from 'services/AnalyticsService'
import {
  ANALYTICS_EVENTS,
  handleActionAnalytics,
  getCourseTypeForAnalyticsEvents
} from 'utils/analytics'

export const ButtonCourseAccess = ({ course }): JSX.Element => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const dispatch = useDispatch()
  const user = useSelector(getStudent)
  const isProduction = process.env.RELEASE_TYPE === 'production'
  const haveTransactionId = isNotNilOrEmpty(course.transaction_id)
  const transactionId = propOr('', 'transaction_id', course)

  const extendProduct = () => {
    if (isProduction && haveTransactionId) {
      window.open(
        `https://examkrackers.com/product/kracku/?liextend=1&litid=${transactionId}`,
        '_blank'
      )
    } else if (!isProduction && haveTransactionId) {
      window.open(
        `https://test.examkrackers.com/product/kracku/?liextend=1&litid=${transactionId}`,
        '_blank'
      )
    } else if (!haveTransactionId && !isProduction) {
      window.open(
        'https://test.examkrackers.com/my-account?panel=orders',
        '_blank'
      )
    } else if (!haveTransactionId && isProduction) {
      window.open('https://examkrackers.com/my-account?panel=orders', '_blank')
    }
  }

  const isOnDemandCourse = course.type === 'on_demand'
  const isCalendarAvailable = course.original?.is_calendar_enabled
  // const isLiveCourse = course.type === 'live_course'

  // const isPrioriDays = isNotNilOrEmpty(course.priori_days)
  // const isExamDate = isNotNilOrEmpty(course.exam_at)

  const showToast = useCallback(
    payload => dispatch(showToastRoutine(payload)),
    [dispatch]
  )

  const accessibleTo = propOr('', 'accessible_to', course)
  const isReady = propOr(false, 'is_ready', course)
  const isPaused = propOr(false, 'is_paused', course)
  const isFreeTrial = course.type === 'free_trial'
  const isStarted = isNotNilOrEmpty(accessibleTo)
  const isExpired = isDateBeforeToday(accessibleTo)

  React.useEffect(() => {
    LocalStorageService.remove(LOCAL_STORAGE_KEYS.studentCourseId)
    LocalStorageService.remove(LOCAL_STORAGE_KEYS.studentCourseType)
    LocalStorageService.remove(LOCAL_STORAGE_KEYS.studentCourseEndDateId)
    LocalStorageService.remove(LOCAL_STORAGE_KEYS.originalCourseId)
  }, [])
  // changed for new init flow
  const saveCourseInLocalStorage = async () => {
    await LocalStorageService.set(LOCAL_STORAGE_KEYS.studentCourseId, course.id)

    await LocalStorageService.set(
      LOCAL_STORAGE_KEYS.originalCourseId,
      course.book_course_id
    )

    await LocalStorageService.set(LOCAL_STORAGE_KEYS.studentCourseId, course.id)

    await LocalStorageService.set(
      LOCAL_STORAGE_KEYS.studentCourseType,
      course.type
    )

    course.end_date_id
      ? await LocalStorageService.set(
          LOCAL_STORAGE_KEYS.studentCourseEndDateId,
          course.end_date_id
        )
      : null
  }

  const courseAnalysis = () => {
    AnalyticsService(user).logEvent(
      ANALYTICS_EVENTS[
        getCourseTypeForAnalyticsEvents(
          handleActionAnalytics(course),
          course?.type
        )
      ],
      {
        Time: formatDate(new Date(), DATE_FORMATS.dashedWithTime),
        'Course type': course?.type || false,
        'Course name': course?.title || false,
        'Course expiration date': course?.accessible_to || false
      }
    )
  }

  const typeOfProduct = {
    course: 'course',
    exam: 'exam'
  }
  // changed for new init flow
  const handleInitialise = async productType => {
    const handleSuccess = () => {
      AnalyticsService(user).logEvent(
        ANALYTICS_EVENTS[
          getCourseTypeForAnalyticsEvents('start', course?.type)
        ],
        {
          Time: formatDate(new Date(), DATE_FORMATS.dashedWithTime),
          'Course type': course?.type || false,
          'Course name': course?.title || false,
          'Course expiration date': course?.accessible_to || false,
          'Course class end date': `${course?.calendar_start_at}` || false
        }
      )
    }
    const handleError = e => {
      console.error(e)
      showToast({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    }

    initialiseCourse({
      id: course.id
    })
      .then(handleSuccess)
      .catch(handleError)
    // changed for new init flow
    await saveCourseInLocalStorage()
    if (productType === typeOfProduct.course || isCalendarAvailable) {
      push({ pathname: PATHS.dashboardLoading, state: { course: course } })
    } else {
      push(PATHS.examLoading)
    }
  }
  // for testing
  const handleInitialiseOldVersionWithoutCalendar = productType => {
    const handleSuccess = () => {
      AnalyticsService(user).logEvent(
        ANALYTICS_EVENTS[
          getCourseTypeForAnalyticsEvents('start', course?.type)
        ],
        {
          Time: formatDate(new Date(), DATE_FORMATS.dashedWithTime),
          'Course type': course?.type || false,
          'Course name': course?.title || false,
          'Course expiration date': course?.accessible_to || false,
          'Course class end date': `${course?.calendar_start_at}` || false
        }
      )
    }
    const handleError = e => {
      console.error(e)
      showToast({
        key: 'toast.somethingWentWrong',
        severity: SEVERITY.error
      })
    }

    initialiseCourse({
      id: course.id
    })
      .then(handleSuccess)
      .catch(handleError)

    saveCourseInLocalStorage()
    if (productType === typeOfProduct.course) {
      push(PATHS.dashboardLoading)
    } else {
      push(PATHS.examLoading)
    }
  }

  const redirectToCourseDashboard = () => {
    courseAnalysis()
    saveCourseInLocalStorage()
    push(PATHS.dashboard)
  }

  // const redirectToCourseCalendar = () => {
  //   courseAnalysis()
  //   saveCourseInLocalStorage()

  //   if (isPrioriDays || isExamDate) {
  //     push(PATHS.calendar)
  //   } else if (isLiveCourse) {
  //     push(PATHS.calendarSetupExpiration)
  //   } else if (!isPrioriDays && !isExamDate && isFreeTrial) {
  //     push(PATHS.calendarSetupFreeTrial)
  //   } else {
  //     push(PATHS.calendarSetup)
  //   }
  // }
  // changed for new init flow
  // const redirectToCalendar = async () => {
  //   courseAnalysis()
  //   await saveCourseInLocalStorage()
  // }
  const handleSelectCourse = () =>
    isReady
      ? redirectToCourseDashboard()
      : handleInitialiseOldVersionWithoutCalendar(typeOfProduct.course)

  const handleSelectCourseWithCalendar = () =>
    isReady
      ? // ? redirectToCourseCalendar()
        redirectToCourseDashboard()
      : handleInitialise(typeOfProduct.course)
  // changed for new init flow
  // const handleSelectCourseWithCalendarNewFlow = () =>
  // isReady
  //   ? redirectToCourseCalendar()
  //   : handleInitialise(typeOfProduct.course)

  const handleContinueCourseWithoutCalendar = () =>
    isReady
      ? redirectToCourseDashboard()
      : handleInitialiseOldVersionWithoutCalendar(typeOfProduct.course)

  // const courseExtendAnalysis = () => {
  //   AnalyticsService(user).logEvent(
  //     ANALYTICS_EVENTS[getCourseTypeForAnalyticsEvents('extend', course?.type)],
  //     {
  //       Time: formatDate(new Date(), DATE_FORMATS.dashedWithTime),
  //       'Course type': course?.type || false,
  //       'Course name': course?.title || false,
  //       'Course expiration date': course.accessible_to || false
  //     }
  //   )
  // }

  // const daysAmount = pipe(
  //   propOr('{}', 'metadata'),
  //   JSON.parse,
  //   propOr('0', 'days_amount'),
  //   Number
  // )(course)
  // changed for new init flow
  const getStartButton = () => (
    <ButtonsContainer>
      <Button
        data-testid='ButtonCourseAccess-extend-product'
        variant='contained'
        size='small'
        color='secondary'
        onClick={extendProduct}
      >
        {t('courses.buyExtensionCTA')}
      </Button>

      {isOnDemandCourse ? (
        <Button
          data-testid={`ButtonCourseAccess-${course.type}-start-course`}
          variant='contained'
          size='small'
          color='secondary'
          onClick={handleContinueCourseWithoutCalendar}
        >
          {t('courses.startCTA')}
        </Button>
      ) : (
        <Button
          variant='contained'
          size='small'
          color='secondary'
          onClick={handleSelectCourse}
        >
          {t('courses.startCTA')}
        </Button>
      )}
    </ButtonsContainer>
  )

  const getContinueButton = () => (
    <ButtonsContainer>
      <Button
        data-testid='ButtonCourseAccess-extend-product'
        variant='contained'
        size='small'
        color='secondary'
        onClick={extendProduct}
      >
        {t('courses.buyExtensionCTA')}
      </Button>
      {isCalendarAvailable ? (
        <Button
          data-testid={`ButtonCourseAccess-${course.type}-continue-course-calendar-available`}
          variant='contained'
          size='small'
          color='secondary'
          onClick={handleSelectCourseWithCalendar}
        >
          {t('courses.continueCTA')}
        </Button>
      ) : (
        <Button
          data-testid={`ButtonCourseAccess-${course.type}-continue-course-calendar-unavailable`}
          variant='contained'
          size='small'
          color='secondary'
          onClick={handleContinueCourseWithoutCalendar}
        >
          {t('courses.continueCTA')}
        </Button>
      )}
    </ButtonsContainer>
  )

  const getInitialiseButton = () => (
    <ButtonContainer>
      {isFreeTrial ? (
        <Button
          data-testid={`ButtonCourseAccess-${course.type}-initialise`}
          variant='contained'
          size='small'
          color='secondary'
          onClick={handleSelectCourse}
        >
          {t('courses.continueCTA')}
        </Button>
      ) : (
        <Button
          data-testid={`ButtonCourseAccess-${course.type}-initialise`}
          variant='contained'
          size='small'
          color='secondary'
          onClick={handleSelectCourse}
        >
          {t('courses.continueCTA')}
        </Button>
      )}
    </ButtonContainer>
  )

  const getExpiredButtons = () => (
    <ButtonsContainer>
      <Button
        data-testid='ButtonCourseAccess-extend-product'
        variant='contained'
        size='small'
        color='secondary'
        onClick={extendProduct}
      >
        {t('courses.buyExtensionCTA')}
      </Button>
      <Button
        data-testid='ButtonCourseAccess-expired'
        variant='contained'
        size='small'
        color='secondary'
        onClick={() => {}}
        disabled
      >
        {t('courses.continueCTA')}
      </Button>
    </ButtonsContainer>
  )

  switch (true) {
    case isPaused:
    case isExpired:
      return getExpiredButtons()
    case isStarted && isReady:
      return getContinueButton()
    case isStarted && !isReady:
      return getInitialiseButton()
    default:
      return getStartButton()
  }
}

export default ButtonCourseAccess

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`
