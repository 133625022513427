import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { fetchTop10 } from 'services/LeaderboardService'
import { propOr } from 'ramda'
import { formatNumberWithDecimal } from 'utils/numbers'

import {
  SaltyBucksIcon,
  FirstPlaceMedalIcon,
  SecondPlaceMedalIcon,
  ThirdPlaceMedalIcon
} from 'examkrackers-components'

const PERIOD_STATE = {
  daily: '_diff_1',
  weekly: '_diff_7',
  monthly: '_diff_30',
  all_time: ''
}

const LeaderboardLeftColumn = () => {
  const [data, setData] = React.useState([])
  const [periodState, setPeriodState] = React.useState<any>(PERIOD_STATE.daily)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const { t } = useTranslation()

  const getTop10 = () => {
    const handleSuccess = response => {
      setIsLoading(false)
      setData(response.data)
    }

    const handleError = response => console.error(response)

    fetchTop10(periodState).then(handleSuccess).catch(handleError)
  }

  React.useEffect(() => {
    getTop10()
  }, [periodState])

  const handlePeriodChange = e => {
    setIsLoading(true)
    e.preventDefault()
    setPeriodState(e.currentTarget.value)
  }

  const renderRank = index => {
    switch (index) {
      case 0:
        return <FirstPlaceMedalIcon />

      case 1:
        return <SecondPlaceMedalIcon />

      case 2:
        return <ThirdPlaceMedalIcon />

      default:
        return index + 1 + '.'
    }
  }

  const renderRows = data.map((row, index) => {
    const saltyBucks = propOr(0, `balance${periodState}`, row)
    return (
      <StyledTr
        key={`leaderboard-entry-${propOr('-', 'id', row)}`}
        index={index}
      >
        <CellRank className='rank'>{renderRank(index)}</CellRank>
        <CellUsername>{propOr('Unnamed', 'username', row)}</CellUsername>
        <CellBalance className='earnings'>
          <SaltyBucksIcon />
          {formatNumberWithDecimal(saltyBucks)}
        </CellBalance>
      </StyledTr>
    )
  })

  return (
    <Container>
      <TableHeader>
        <p>{t('leaderboard.leftColumn.title')}</p>
        <ToggleContainer>
          <PeriodToggle
            value={PERIOD_STATE.daily}
            onClick={e => handlePeriodChange(e)}
            periodState={periodState}
          >
            {t('leaderboard.leftColumn.periodToggle.daily')}
          </PeriodToggle>
          <PeriodToggle
            onClick={e => handlePeriodChange(e)}
            value={PERIOD_STATE.weekly}
            periodState={periodState}
          >
            {t('leaderboard.leftColumn.periodToggle.weekly')}{' '}
          </PeriodToggle>
          <PeriodToggle
            onClick={e => handlePeriodChange(e)}
            value={PERIOD_STATE.monthly}
            periodState={periodState}
          >
            {t('leaderboard.leftColumn.periodToggle.monthly')}{' '}
          </PeriodToggle>
          <PeriodToggle
            onClick={e => handlePeriodChange(e)}
            value={PERIOD_STATE.all_time}
            periodState={periodState}
          >
            {t('leaderboard.leftColumn.periodToggle.allTime')}{' '}
          </PeriodToggle>
        </ToggleContainer>
      </TableHeader>
      {!isLoading && (
        <Table>
          <StyledTr className='headings-container'>
            <CellRank className='heading'>
              {t('leaderboard.leftColumn.headings.rank')}
            </CellRank>
            <CellUsername className='heading'>
              {t('leaderboard.leftColumn.headings.username')}
            </CellUsername>
            <CellBalance className='heading'>
              {t('leaderboard.leftColumn.headings.earnings')}
            </CellBalance>
          </StyledTr>
          {renderRows}
        </Table>
      )}
    </Container>
  )
}

export default LeaderboardLeftColumn

const Container = styled.div`
  max-height: 582px;
  padding: 16px 16px 0 16px;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 6px;
  overflow: hidden;
  transition: 300ms all;
`
const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  p {
    font-weight: 700;
    font-size: 16px;
  }
`

const Table = styled.table`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 4px;
  transition: 300ms all;
  animation: fadeIn 300ms ease-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .headings-container {
    border: none;
    height: 28px;
  }
`

const StyledTr = styled.tr`
  display: grid;
  grid-template-columns: 50px auto 80px;
  align-items: center;
  height: 48px;
  border: solid ${({ theme }) => theme.colors.main.grey300};
  border-width: 1px 0 0 0;
  font-weight: 600;

  .heading {
    font-weight: 400;
    padding: 0;
    font-size: 12px;
  }

  .rank {
    margin: 0 auto;
    font-size: ${({ index }) => (index < 3 ? '29px' : '16px')};
  }

  .earnings {
    color: ${({ theme }) => theme.colors.main.currency500};
  }
`

const ToggleContainer = styled.div`
  display: flex;
  gap: 12px;
`

const PeriodToggle = styled.button`
  padding: 2px 8.5px;
  border: none;
  border-radius: 50px;
  background: ${({ theme, value, periodState }) =>
    value === periodState
      ? theme.colors.buttons.contained.primary
      : 'transparent'};
  color: ${({ theme, value, periodState }) =>
    value === periodState
      ? theme.colors.main.white
      : theme.colors.main.grey600};
  font-weight: ${({ theme, value, periodState }) =>
    value === periodState ? 700 : 400};
  font-size: 11px;
`

const CellRank = styled.div``

const CellUsername = styled.div`
  margin-left: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const CellBalance = styled.div`
  display: flex;
  justify-content: end;
  padding-right: 8px;
  width: 110%;
`
