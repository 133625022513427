import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import * as R from 'ramda'
import qs from 'qs'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Course, RootReducer } from 'types'
import {
  getNextChapterOrder,
  getNextPart,
  getPreviousPart,
  getPreviousChapterOrder,
  selectCurrentBookChaptersWithExams,
  selectBookByOriginalId,
  getChapterByBookOriginalIdAndOrder,
  getIsBookContentsLoading
} from 'modules/books/ducks/selectors'

import {
  Button,
  NavArrowRightIcon,
  NavArrowLeftIcon
} from 'examkrackers-components'
import { BOOK_VIEWER_PARAMS, HIGHLIGHT_MODES } from 'utils/books'
import { pathOr, pipe, propOr } from 'ramda'
import { EXAM_STATUS } from 'utils/exam'
import ModalConfirmStart from 'modules/exams/components/ModalConfirmStart'
import ModalConfirmResume from 'modules/exams/components/ModalConfirmResume'
import { isNotNilOrEmpty } from 'utils/ramda'
import { getStudent, getIsImpersonate } from 'modules/auth/ducks/selectors'

import { Chapter } from 'types/books'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'
import SubscribeButton from 'modules/books/components/SubscribeButton'
import { COURSE_TYPES } from 'utils/courses'
import { error } from 'console'

export const ButtonNext = (): JSX.Element => {
  const { t } = useTranslation()
  const params = useParams()
  const bookId: string = R.propOr('', 'bookId')(params)
  const partOrder: number = R.pipe(R.propOr('', 'partOrder'), Number)(params)
  const isLoading = useSelector(getIsBookContentsLoading)
  const isImpersonate = useSelector(getIsImpersonate)
  const chapterOrder: number = R.pipe(
    R.propOr('1', 'chapterOrder'),
    Number
  )(params)
  const chapters = useSelector(selectCurrentBookChaptersWithExams)

  const currentChapterWithExam = chapters?.find(
    // @ts-ignore
    chapter => chapter.order === chapterOrder
  )
  const hasExamAssigned = isNotNilOrEmpty(
    propOr(null, 'assigned_exam', currentChapterWithExam)
  )
  const isExpired =
    pathOr('', ['assigned_exam', 'status'], currentChapterWithExam) ===
    EXAM_STATUS.expired
  const isPaused =
    pathOr('', ['assigned_exam', 'status'], currentChapterWithExam) ===
      EXAM_STATUS.in_progress ||
    pathOr('', ['assigned_exam', 'status'], currentChapterWithExam) ===
      EXAM_STATUS.paused

  const [isConfirmStartModalOpen, setIsConfirmStartModalOpen] = useState(false)
  const [isConfirmResumeModalOpen, setIsConfirmResumeModalOpen] =
    useState(false)
  const [isRetake, setIsRetake] = useState(false)

  const user = useSelector(getStudent)
  const currentCourse: Course = useSelector(getCurrentCourse)

  const handleConfirmStartModalOpen = () => setIsConfirmStartModalOpen(true)
  const handleConfirmResumeModalOpen = () => setIsConfirmResumeModalOpen(true)
  const handleRetakeModalOpen = () => setIsRetake(true)
  const closeAllModals = () => {
    setIsConfirmStartModalOpen(false)
    setIsConfirmResumeModalOpen(false)
    setIsRetake(false)
  }

  const redirectToScoreReport = id => {
    push(`/exam/${id}/score-report/score-sheet`)
  }

  const exam: any = propOr({}, 'assigned_exam', currentChapterWithExam)
  const examID = pathOr('', ['assigned_exam', 'id'], currentChapterWithExam)

  const maxCompletionNumber: number = R.propOr(
    0,
    'max_completions',
    currentChapterWithExam
  )
  const completionDone: number = R.propOr(
    0,
    'completions_done',
    currentChapterWithExam
  )

  const isExamAbleToRetake =
    maxCompletionNumber > completionDone && completionDone > 0

  const handleStartExam = () => {
    closeAllModals()
    switch (exam.status) {
      case EXAM_STATUS.scheduled:
        handleConfirmStartModalOpen()
        break
      case EXAM_STATUS.completed:
        redirectToScoreReport(examID)
        break
      case EXAM_STATUS.paused:
      case EXAM_STATUS.in_progress:
        handleConfirmResumeModalOpen()
        break
      default:
        break
    }
  }

  const { push, location } = useHistory()
  const { search } = location

  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })

  const highlightMode: string = R.propOr(
    HIGHLIGHT_MODES.none,
    BOOK_VIEWER_PARAMS.highlightMode,
    parsedQuery
  )

  const currentImageSlide = pipe(
    propOr('0', BOOK_VIEWER_PARAMS.imageSlide),
    Number
  )(parsedQuery)

  const book = useSelector((state: RootReducer) =>
    selectBookByOriginalId(state, bookId)
  )
  const chapter = useSelector((state: RootReducer) =>
    getChapterByBookOriginalIdAndOrder(state, bookId, chapterOrder)
  )
  const bookTitle = R.propOr('', 'title', book)
  const bookTag = R.propOr('', 'tag', book)
  const chapterTitle = R.propOr('', 'title', chapter)
  const studentBookId = R.propOr('', 'id', book)

  const nextChapter: Chapter | undefined = chapters.find(
    (chapter: Chapter) => chapter.order === chapterOrder + 1
  )
  const previousChapter: Chapter | undefined = chapters.find(
    (chapter: Chapter) => chapter.order === chapterOrder - 1
  )

  const nextPart = useSelector((state: RootReducer) =>
    getNextPart(state, chapterOrder, partOrder, studentBookId)
  )
  const nextChapterOrder = useSelector((state: RootReducer) =>
    getNextChapterOrder(state, chapterOrder, partOrder, studentBookId)
  )
  const prevPart = useSelector((state: RootReducer) =>
    getPreviousPart(state, chapterOrder, partOrder)
  )
  const prevChapterOrder = useSelector((state: RootReducer) =>
    getPreviousChapterOrder(state, chapterOrder, partOrder)
  )

  const isNextPageWithinChapter = nextPart > 1
  const isPrevPageWithinChapter = prevChapterOrder === chapterOrder

  const handleNextPage = () => {
    const imageSlide = isNextPageWithinChapter ? currentImageSlide : 0

    if (!isNextPageWithinChapter) {
      AnalyticsService(user).logEvent(ANALYTICS_EVENTS.goToBooksNextChapter, {
        'Course type': currentCourse.type || false,
        'Course name': currentCourse.title || false,
        'Course expiration date': currentCourse?.accessible_to || false,
        'Book name': bookTitle || false,
        'Book chapter number from': chapterOrder || false,
        'Book chapter number to': nextChapter?.order || false,
        'Book chapter name from': chapterTitle || false,
        'Book chapter name to': nextChapter?.title || false,
        'Book tag': bookTag || false
      })
    }

    push(
      `/books/${bookId}/chapter/${nextChapterOrder}/part/${nextPart}?${BOOK_VIEWER_PARAMS.highlightMode}=${highlightMode}&${BOOK_VIEWER_PARAMS.imageSlide}=${imageSlide}`
    )
  }

  const handlePreviousPage = () => {
    const imageSlide = isPrevPageWithinChapter ? currentImageSlide : 0

    if (!isPrevPageWithinChapter) {
      AnalyticsService(user).logEvent(
        ANALYTICS_EVENTS.goToBooksPreviousChapter,
        {
          'Course type': currentCourse.type || false,
          'Course name': currentCourse.title || false,
          'Course expiration date': currentCourse?.accessible_to || false,
          'Book name': bookTitle || false,
          'Book chapter number from': chapterOrder || false,
          'Book chapter number to': previousChapter?.order || false,
          'Book chapter name from': chapterTitle || false,
          'Book chapter name to': previousChapter?.title || false,
          'Book tag': bookTag || false
        }
      )
    }

    push(
      `/books/${bookId}/chapter/${prevChapterOrder}/part/${prevPart}?${BOOK_VIEWER_PARAMS.highlightMode}=${highlightMode}&${BOOK_VIEWER_PARAMS.imageSlide}=${imageSlide}`
    )
  }

  const examButtonText = useMemo(() => {
    const exam: any = propOr({}, 'assigned_exam', currentChapterWithExam)

    switch (exam.status) {
      case EXAM_STATUS.completed:
        return t('bookViewer.goToScores')
      case EXAM_STATUS.paused:
      case EXAM_STATUS.in_progress:
        return t('bookViewer.resumeExam')
      case EXAM_STATUS.scheduled:
        return t('bookViewer.goToExam')
      default:
        return t('bookViewer.goToExam')
    }
  }, [currentChapterWithExam, t])

  const isFreeTrial = R.pipe(
    R.propOr('', 'type'),
    R.equals(COURSE_TYPES.freeTrial)
  )(currentCourse)

  return (
    <>
      <ButtonContainer isLoading={isLoading}>
        <div>
          {prevChapterOrder !== 0 && (
            <Button
              id='next-page'
              startIcon={<NavArrowLeftIcon />}
              onClick={handlePreviousPage}
              size='small'
              color='tertiary'
            >
              {isPrevPageWithinChapter
                ? t('bookViewer.previousPart')
                : t('bookViewer.previousChapter')}
            </Button>
          )}
        </div>

        <div>
          {!isNextPageWithinChapter && hasExamAssigned && !isExpired && (
            <Button
              id='start-exam'
              onClick={handleStartExam}
              size='small'
              color='secondary'
              // @ts-ignore
              disabled={isImpersonate}
            >
              {examButtonText}
            </Button>
          )}
        </div>
        <RetakeContainer>
          {!isNextPageWithinChapter &&
            hasExamAssigned &&
            !isExpired &&
            isExamAbleToRetake &&
            !isPaused && (
              <RetakeWrapper
                id='start-retake'
                onClick={handleRetakeModalOpen}
                color='secondary'
                disabled={isImpersonate}
              >
                {t('bookViewer.retakeExam')}
              </RetakeWrapper>
            )}
        </RetakeContainer>
        <div>
          {nextChapterOrder !== 0 && (
            <>
              {isFreeTrial ? (
                isNextPageWithinChapter ? (
                  <Button
                    id='next-page'
                    endIcon={<NavArrowRightIcon />}
                    onClick={handleNextPage}
                    size='small'
                    color='tertiary'
                  >
                    {t('bookViewer.nextPart')}
                  </Button>
                ) : (
                  <SubscribeButton />
                )
              ) : (
                <Button
                  id='next-page'
                  endIcon={<NavArrowRightIcon />}
                  onClick={handleNextPage}
                  size='small'
                  color='tertiary'
                >
                  {isNextPageWithinChapter
                    ? t('bookViewer.nextPart')
                    : t('bookViewer.nextChapter')}
                </Button>
              )}
            </>
          )}
        </div>
      </ButtonContainer>
      <ModalConfirmStart
        exam={exam}
        disabled={false}
        id={pathOr('', ['assigned_exam', 'id'], currentChapterWithExam)}
        isOpen={isConfirmStartModalOpen}
        closeCallback={closeAllModals}
        maxCompletionNumber={propOr(
          1,
          'max_completions',
          currentChapterWithExam
        )}
      />
      <ModalConfirmStart
        disabled={false}
        closeCallback={closeAllModals}
        id={pathOr('', ['assigned_exam', 'id'], currentChapterWithExam)}
        exam={exam}
        isOpen={isRetake}
        isRetake
        maxCompletionNumber={maxCompletionNumber}
        numberOfCompletions={completionDone}
      />
      <ModalConfirmResume
        exam={exam}
        disabled={false}
        id={pathOr('', ['assigned_exam', 'id'], currentChapterWithExam)}
        isOpen={isConfirmResumeModalOpen}
        closeCallback={closeAllModals}
        maxCompletionNumber={propOr(
          1,
          'max_completions',
          currentChapterWithExam
        )}
        numberOfCompletions={propOr(
          1,
          'completions_done',
          currentChapterWithExam
        )}
      />
    </>
  )
}

export default ButtonNext

const ButtonContainer = styled.div`
  width: calc(100% - 220px);
  margin-bottom: 20px;
  display: inline-flex;
  gap: 5px;
  justify-content: space-between;
  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
  transition: all 100ms ${({ theme }) => theme.transitions.easing.easeInOut};
`

const RetakeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
`
const RetakeWrapper = styled.button`
  all: unset;
`
