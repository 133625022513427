export const formatNumberWithDecimal = value => {
  const valueFloored = Math.floor(value)
  const formattedNumber = valueFloored
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return formattedNumber
}

export const getOrdinalSuffix = (number: number): string => {
  const lastDigit = number % 10
  const lastTwoDigits = number % 100

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return 'th'
  }

  switch (lastDigit) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}
