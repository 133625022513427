import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { equals, propOr } from 'ramda'
import { SaltyBucksIcon } from 'examkrackers-components'
import { formatNumberWithDecimal } from 'utils/numbers'

export const SBPercentileRankTable = ({
  allStudents,
  currentStudent
}): JSX.Element => {
  const { t } = useTranslation()

  const isCurrent = row => {
    const rowId = propOr('-', 'id', row)
    const currentStudentId = propOr('', 'id', currentStudent)

    return equals(rowId, currentStudentId)
  }

  const renderRows = allStudents.map(row => {
    const saltyBucks = propOr(0, `salty_bucks_balance`, row)
    return (
      <StyledTr
        className={isCurrent(row) ? 'current' : ''}
        id={isCurrent(row) ? 'percentile-rank-current-user' : ''}
        isCurrent={isCurrent(row)}
        key={`leaderboard-percentile-rank-entry-${propOr('-', 'id', row)}`}
      >
        <CellRank>{propOr('-', 'percentile_position', row)}. </CellRank>
        <CellUsername>
          {propOr(propOr('name', 'name', row), 'username', row)}
        </CellUsername>
        <CellBalance>
          <SaltyBucksIcon />
          {formatNumberWithDecimal(saltyBucks)}
        </CellBalance>
      </StyledTr>
    )
  })

  return (
    <Table>
      <TableHeader>
        <CellRank className='heading'>
          {' '}
          {t('leaderboard.middleColumn.headings.rank')}
        </CellRank>
        <CellUsername className='heading'>
          {' '}
          {t('leaderboard.middleColumn.headings.username')}
        </CellUsername>
        <CellBalance className='heading'>
          {' '}
          {t('leaderboard.middleColumn.headings.earnings')}
        </CellBalance>
      </TableHeader>
      {renderRows}
    </Table>
  )
}

export default SBPercentileRankTable

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;
  margin-top: 12px;
`
const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 50px auto 80px;
  align-items: center;
  border-width: 1px 0 1px 0;
  font-weight: 400;
  font-size: 12px;
  padding-bottom: 8px;
`

const StyledTr = styled.div`
  display: grid;
  grid-template-columns: 50px auto 80px;
  align-items: center;
  height: 48px;
  background: ${({ theme, isCurrent }) =>
    isCurrent ? theme.colors.main.primary200 : 'transparent'};
  border: solid
    ${({ theme, isCurrent }) =>
      isCurrent ? theme.colors.main.primary500 : theme.colors.main.grey300};
  border-width: ${({ isCurrent }) => (isCurrent ? '1px 0 1px 0' : '1px 0 0 0')};
  font-weight: 600;
  padding: 0 16px;

  &.current + & {
    border-width: 0 !important;
  }
`

const CellRank = styled.div``

const CellUsername = styled.div`
  margin-left: calc(40px - 16px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.heading {
    margin-left: 40px;
  }
`

const CellBalance = styled.div`
  display: flex;
  justify-content: end;
  color: ${({ theme }) => theme.colors.main.currency500};
  width: 110%;

  &.heading {
    color: ${({ theme }) => theme.colors.main.text};
  }
`
