import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Droppable } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { selectManualCalendarActiveTab } from 'modules/calendar/ducks/selectors'
import eventEmitter from 'providers/eventEmitter'
import {
  ArrowDownIcon,
  Button,
  theme,
  IconButton,
  Tooltip
} from 'examkrackers-components'
import { setManualCalendarActiveTabRoutine } from 'modules/calendar/ducks/actions'
import { CalendarContext } from 'hooks/CalendarContext'
import CalendarSingleEvent from 'modules/calendar/CalendarSingleEvent'
import { selectFreeTrialAvailableBookTags } from 'modules/books/ducks/selectors'
import * as R from 'ramda'
import { COURSE_TYPES } from 'utils/courses'
import { isNilOrEmpty } from 'utils/ramda'
import { useTranslation } from 'react-i18next'
import { countStudyDaysInRange } from 'utils/calendar'

const ManualCalendarTasks = ({ onOpenStateChange }) => {
  const { archiveEvents, course } = useContext(CalendarContext)
  const courseStartDate = course?.calendar_start_at
  const courseEndDate = course?.exam_at
  const coursePrioriDays = course?.prioridays
  const availableBookTags = useSelector(selectFreeTrialAvailableBookTags)
  const activeTab = useSelector(selectManualCalendarActiveTab)
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const courseStudyDaysAmount = countStudyDaysInRange(
    courseStartDate as string,
    courseEndDate as string,
    coursePrioriDays as []
  )

  const isTypeOfOneWeekCourse =
    courseStudyDaysAmount < 11 && course.type !== 'live_course'

  const isEmpty = isNilOrEmpty(archiveEvents)

  const setActiveTab = (tab: string) => {
    dispatch(setManualCalendarActiveTabRoutine({ activeTab: tab }))
  }

  const toggleOpen = () => {
    setOpen(prev => {
      onOpenStateChange(!prev)

      return !prev
    })
  }

  const isTitleInArray = (title, arr) => {
    const [prefix, suffix] = title.split('_')

    return arr.includes(prefix) && suffix === '1'
  }

  const isFreeTrial = R.pipe(
    R.propOr('', 'type'),
    R.equals(COURSE_TYPES.freeTrial)
  )(course)

  useEffect(() => {
    if (isTypeOfOneWeekCourse) {
      toggleOpen()
      setTimeout(e => eventEmitter.emit('closeExpandedDay', e), 300)
    }
  }, [isTypeOfOneWeekCourse])

  const renderSingleEvent = (item, index) => {
    if (isFreeTrial && !isTitleInArray(item.title, availableBookTags)) {
      return (
        <Tooltip
          tooltipContent='Not available in free trial'
          id={item.id}
          data-testid={`ManualCalendarTasks-Tooltip-${item.title}-${item.id}`}
        >
          <CalendarSingleEvent
            data-testid={`ManualCalendarTasks-CalenderSingleEvent-${item.title}-${item.id}`}
            key={item.id}
            item={item}
            index={index}
            disabledInFreeTrial
          />
        </Tooltip>
      )
    }

    return (
      <CalendarSingleEvent
        key={item.id}
        item={item}
        index={index}
        data-testid={`ManualCalendarTasks-CalendarSingleEvent-${item.title}-${item.id}`}
      />
    )
  }

  const getTabButtonColors = (
    tab: string
  ): { background: string; color: string; borderRadius: string } => {
    return activeTab === tab
      ? {
          background: theme.colors.main.primaryGradient,
          color: '#fff',
          borderRadius: '4px 4px 0 0'
        }
      : {
          background: theme.colors.main.grey300,
          color: theme.colors.main.grey600,
          borderRadius: '4px 4px 0 0'
        }
  }

  const container = document.getElementById('manual-tasks-events-list')
  const wrapperContainer = document.getElementById(
    'manual-tasks-events-list-wrapper'
  )

  const getListWidth = () => {
    if (isEmpty) {
      return '100%'
    }

    if (container && wrapperContainer) {
      const children = Array.from(container?.children || [])

      const containerHeight = container.offsetHeight - 16
      const wrapperContainerWidth = wrapperContainer.offsetWidth

      let currentColumnHeight = 0
      let numColumns = 1

      children.forEach(child => {
        const childHeight = child.offsetHeight + 5
        currentColumnHeight += childHeight

        if (currentColumnHeight > containerHeight) {
          numColumns++
          currentColumnHeight = childHeight
        }
      })

      const width = Math.max(numColumns * 152, 165)

      if (width >= wrapperContainerWidth) {
        return '100%'
      }

      return `${width}px`
    }

    return '100%'
  }

  return (
    <Droppable
      droppableId='events-archive-wrapper'
      data-testid='ManualCalendarTasks-Droppable'
    >
      {(provided, snapshot) => {
        return (
          <Wrapper
            data-testid='ManualCalendarTasks-Wrapper'
            isDraggingOver={snapshot.isDraggingOver}
            open={open}
            ref={provided.innerRef}
            id='manual-tasks-events-list-wrapper'
          >
            {open && (
              <TabsWrapper data-testid='ManualCalendarTasks-TabsWrapper'>
                <Button
                  data-testid='ManualCalendarTasks-Button-Readings'
                  variant='contained'
                  onClick={() => setActiveTab('readings')}
                  size='small'
                  color='primary'
                  style={getTabButtonColors('readings')}
                >
                  Readings
                </Button>
                <Button
                  data-testid='ManualCalendarTasks-Button-Mini-MCATs'
                  variant='contained'
                  onClick={() => setActiveTab('mini_mcats')}
                  size='small'
                  style={getTabButtonColors('mini_mcats')}
                >
                  Mini-MCATs
                </Button>
                <Button
                  data-testid='ManualCalendarTasks-Reviews'
                  variant='contained'
                  onClick={() => setActiveTab('reviews')}
                  size='small'
                  style={getTabButtonColors('reviews')}
                >
                  Reviews
                </Button>
                <Button
                  data-testid='ManualCalendarTasks-Exams'
                  variant='contained'
                  onClick={() => setActiveTab('exams')}
                  size='small'
                  style={getTabButtonColors('exams')}
                >
                  Exams
                </Button>
                <Button
                  variant='contained'
                  onClick={() => setActiveTab('exam_reviews')}
                  size='small'
                  style={getTabButtonColors('exam_reviews')}
                  data-testid='ManualCalendarTasks-ExamReviews'
                >
                  Exam Reviews
                </Button>
                {course?.is_pre_reading && (
                  <Button
                    variant='contained'
                    onClick={() => setActiveTab('prereading')}
                    size='small'
                    style={getTabButtonColors('prereading')}
                    data-testid='ManualCalendarTasks-PreReadings'
                  >
                    Pre-readings
                  </Button>
                )}
              </TabsWrapper>
            )}
            <ButtonWrapper
              open={open}
              onClick={toggleOpen}
              data-testid='ManualCalendarTasks-Archive'
            >
              Archive
              <IconButton
                data-testid='ManualCalendarTasks-IconButton-ArrowDown'
                onClick={e => eventEmitter.emit('closeExpandedDay', e)}
                size='small'
                icon={<ArrowDownIcon />}
              />
            </ButtonWrapper>
            {isEmpty ? (
              <EmptyState data-testid='ManualCalendarTasks-EmptyState'>
                {t('calendar.archive.emptyState')}
              </EmptyState>
            ) : (
              <Droppable
                data-testid='ManualCalendarTasks-Droppable-Events-Archive'
                droppableId='events-archive'
                isDropDisabled={true}
                droppableStyles
              >
                {(provided, snapshot) => {
                  return (
                    <List
                      data-testid='ManualCalendarTasks-List'
                      open={open}
                      id='manual-tasks-events-list'
                      ref={provided.innerRef}
                      isDraggingOver={snapshot.isDraggingOver}
                      width={getListWidth()}
                    >
                      {archiveEvents.map(renderSingleEvent)}
                      {provided.placeholder}
                    </List>
                  )
                }}
              </Droppable>
            )}
            {provided.placeholder}
          </Wrapper>
        )
      }}
    </Droppable>
  )
}

export default ManualCalendarTasks

const Wrapper = styled.div<{
  open: boolean
  isDraggingOver: boolean
}>`
  margin: 0 1px 12px;
  border-radius: 10px;
  height: 33px;
  bottom: 0;
  position: relative;
  left: 0;
  right: 0;
  z-index: 200;
  box-shadow: inset 0 0 10px rgba(50, 50, 50, 0.4);
  padding: 0;
  box-sizing: content-box;
  overflow: hidden;
  transition: background-color 0.3s;
  background-color: ${({ isDraggingOver, theme }) =>
    isDraggingOver ? theme.colors.main.primary200 : 'white'};

  ${({ open }) =>
    open &&
    css`
      overflow: visible;
      height: 100%;
      max-height: 40vh;
      padding: 8px 8px 0;
    `}
`

const List = styled.div<{
  isDraggingOver: boolean
  width: number
}>`
  position: relative;
  border-radius: 6px;
  width: ${({ width }) => width};
  max-width: 100% !important;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 5px;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  overflow-x: auto;
  min-height: ${({ open }) => (open ? 'calc(100% - 30px)' : '250px')};
  max-height: calc(100% - 30px);
  padding: 8px;
  box-sizing: border-box;

  div[data-tip] {
    width: 100%;
  }
`

const ButtonWrapper = styled.div<{
  open: boolean
}>`
  height: 25px;
  display: flex;
  gap: 10px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding-top: ${({ open }) => (open ? '0' : '10px')};

  button {
    padding: 0;
    height: 0;
    z-index: 10;
    transform: ${({ open }) =>
      open ? 'scale(2.7)' : ' translateY(-1px) rotate(180deg) scale(2.7)'};
    transition: all 0.3s ease-in-out;
  }
`

const TabsWrapper = styled.div`
  display: flex;
  gap: 5px;
  position: absolute;
  top: -32px;
`

const EmptyState = styled.div`
  display: flex;
  margin-top: 15px;
  justify-content: center;
  color: ${({ theme }) => theme.colors.main.grey600};
  font-style: italic;
`
