import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as R from 'ramda'
import { randomSeconds } from 'utils/date'

import { getQuestionsChartData } from 'services/BooksService'

import styled from 'styled-components'
import ApexChart from 'react-apexcharts'
import { AnswerContentQuestion } from 'modules/dashboard/utils/AnswerContentQuestion'

import { useSelector } from 'react-redux'
import { getStudent } from 'modules/auth/ducks/selectors'

import {
  ContentQuestionsIcon,
  TotalProgressIcon,
  getTheme
} from 'examkrackers-components'
import { isNotNilOrEmpty } from 'utils/ramda'

import FreeTrialChartTooltipProvider from 'modules/dashboard/components/FreeTrialChartTooltipProvider'

import { contentTypes } from './TooltipChart'
import eventEmitter from 'providers/eventEmitter'
import events from 'modules/dashboard/utils/events'

interface DataMap {
  book_tag?: string
  correct: string
  incorrect: string
  untried: string
}

const PADDING = ['85', '39', '23.5', '15.2', '10.7', '7.6', '5.3', '3.7']

const ContentQuestionTooltip = () => {
  const tooltipEvent = e => ({ e, type: contentTypes.contentQuestions })
  const handleEnterTooltip = e =>
    eventEmitter.emit(events.chartTooltipEnter, tooltipEvent(e))
  const handleLeaveTooltip = () => eventEmitter.emit(events.chartTooltipLeave)

  // const studentDetails = useSelector(getStudent)
  // const dashboardTheme = R.propOr('light', 'theme', studentDetails)
  // const isDarkTheme = dashboardTheme === 'dark'

  return (
    <>
      <Icon onMouseEnter={handleEnterTooltip} onMouseLeave={handleLeaveTooltip}>
        <ContentQuestionsIcon style={{ fontSize: 14 }} />
      </Icon>
    </>
  )
}

export const ContentQuestionsChart = ({
  isGettingStartedCompleted = false
}: {
  isGettingStartedCompleted: boolean
}) => {
  const { t } = useTranslation()
  const theme = getTheme()
  const [data, setData] = useState([])
  const [totalProgress, setTotalProgress] = useState({})

  const studentDetails = useSelector(getStudent)
  const dashboardTheme = R.propOr('light', 'theme', studentDetails)
  const isDarkTheme = dashboardTheme === 'dark'

  const totalProgressFn = data => {
    const totalProgressTemp = data?.shift(el => el.book_tag === 'All')

    const sum =
      parseInt(totalProgressTemp.correct, 10) +
      parseInt(totalProgressTemp.incorrect, 10) +
      parseInt(totalProgressTemp.untried, 10)

    setTotalProgress({
      correct: Math.round(
        (parseInt(totalProgressTemp.correct, 10) * 100) / sum
      ),
      incorrect: Math.round(
        (parseInt(totalProgressTemp.incorrect, 10) * 100) / sum
      ),
      untried: Math.round((parseInt(totalProgressTemp.untried, 10) * 100) / sum)
    })
    setData(data)
  }

  useEffect(() => {
    getQuestionsChartData()
      .then(result => {
        const sortedData = R.pipe(
          R.propOr([], 'data'),
          // @ts-ignore
          R.sortBy(R.prop('title'))
          // @ts-ignore
        )(result)

        // @ts-ignore
        totalProgressFn(sortedData)
      })
      .catch(console.error)
  }, [])

  const injectDefsForSvgCq = () => {
    // @ts-ignore
    const CqChartElement = document.getElementById('apexchartscqChart')

    if (isNotNilOrEmpty(CqChartElement)) {
      // @ts-ignore
      CqChartElement.getElementsByTagName('svg')[0].getElementsByTagName(
        'defs'
      )[0].innerHTML += `
        <linearGradient id="GradientCqCorrect" x1="0" y1="0" x2="1" y2="0">
          <stop offset="0%" stop-color="${theme.colors.dashboard.questions.correctBarFrom}"></stop>
          <stop offset="60%" stop-color="${theme.colors.dashboard.questions.correctBarVia}"></stop>
          <stop offset="100%" stop-color="${theme.colors.dashboard.questions.correctBarTo}"></stop>
        </linearGradient>
        <linearGradient id="GradientCqIncorrect" x1="0" y1="0" x2="1" y2="0">
          <stop offset="0%" stop-color="${theme.colors.dashboard.questions.incorrectBarFrom}"></stop>
          <stop offset="60%" stop-color="${theme.colors.dashboard.questions.incorrectBarVia}"></stop>
          <stop offset="100%" stop-color="${theme.colors.dashboard.questions.incorrectBarTo}"></stop>
        </linearGradient>
        <linearGradient id="GradientCqUntried" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stop-color="${theme.colors.dashboard.totalProgressBackground}"></stop>
          <stop offset="2%" stop-color="${theme.colors.dashboard.totalProgressShadowMedium}" stop-opacity="0"></stop>
          <stop offset="100%" stop-color="${theme.colors.dashboard.totalProgressShadowLight}"></stop>
        </linearGradient>
      `
    }
  }

  useEffect(() => {
    setTimeout(() => {
      injectDefsForSvgCq()
    }, 800)
  }, [theme])

  const timedOutInjectDefsForSvgCq = () => setTimeout(injectDefsForSvgCq, 500)

  useEffect(() => {
    window.addEventListener('resize', timedOutInjectDefsForSvgCq)

    return () => {
      window.removeEventListener('resize', timedOutInjectDefsForSvgCq)
    }
  }, [])

  const ContentQuestion = AnswerContentQuestion(data)

  return (
    <>
      <FreeTrialChartTooltipProvider data={data} id='content-questions-chart' />
      <ChartContainer>
        <ContentQuestionTooltip />
        {isNotNilOrEmpty(totalProgress) && isGettingStartedCompleted ? (
          <TotalProgress isDarkTheme={isDarkTheme}>
            <TotalProgressIcon />
            <p>
              {t('dashboard.charts.totalProgress')}
              <span>
                <DotColor
                  color={theme.colors.dashboard.questions.correctColor}
                />
                {t('dashboard.charts.correct')}
                {/* @ts-ignore */}
                <strong>{totalProgress?.correct}%</strong>
              </span>
              <span>
                <DotColor
                  color={theme.colors.dashboard.questions.incorrectColor}
                />
                {t('dashboard.charts.incorrect')}
                {/* @ts-ignore */}
                <strong>{totalProgress?.incorrect}%</strong>
              </span>
              <span>
                <DotColor
                  color={theme.colors.dashboard.questions.untriedColor}
                />
                {t('dashboard.charts.untried')}
                {/* @ts-ignore */}
                <strong>{totalProgress?.untried}%</strong>
              </span>
            </p>
          </TotalProgress>
        ) : null}
        {/* @ts-ignore */}
        <ApexChart
          id='content-questions-chart'
          // @ts-ignore
          options={ContentQuestion.options}
          series={ContentQuestion.series}
          type='bar'
          width={isGettingStartedCompleted ? '832' : '400'}
          height='270'
        />
        <PercentageChart height={data?.length}>
          <Title>{t('dashboard.charts.percentage')}</Title>
          <ColorPointer>
            <Orange />
            <Red />
            <Grey />
          </ColorPointer>
          <DataChart>
            {data.map((el: DataMap, idx) => {
              const correct = Number(el?.correct)
              const incorrect = Number(el?.incorrect)
              const untried = Number(el.untried)
              const sum = correct + incorrect + untried
              const numberOfBooksWithQuestions = data.filter(
                el =>
                  Number(el?.correct) +
                    Number(el?.incorrect) +
                    Number(el.untried) !==
                  0
              ).length

              /*
                // round the result of the percentages to whole numbers. If the sum of the percentages:
                // is greater, we subtract the difference from the last result
                // is smaller, we add the difference to the last result
              */
              let counter = 0
              let howManyPercentagesLeft = 0
              const toPercentage = data => {
                if (sum > 0) {
                  counter++
                  howManyPercentagesLeft += Math.round((data * 100) / sum)
                  if (howManyPercentagesLeft > 100) {
                    return (
                      Math.round((data * 100) / sum) -
                      (howManyPercentagesLeft - 100)
                    )
                  } else if (howManyPercentagesLeft < 100 && counter === 3) {
                    howManyPercentagesLeft = 0
                    counter = 0
                    return data
                  } else if (howManyPercentagesLeft === 100) {
                    howManyPercentagesLeft = 0
                    return Math.round((data * 100) / sum)
                  }
                  return Math.round((data * 100) / sum)
                } else {
                  return 0
                }
              }
              if (sum !== 0) {
                return (
                  <SingleDataRow
                    key={idx}
                    padding={PADDING}
                    item={data?.length - 1}
                    height={numberOfBooksWithQuestions}
                  >
                    <SingleData>{toPercentage(el?.correct)}</SingleData>
                    <SingleData>{toPercentage(el?.incorrect)}</SingleData>
                    <SingleData>{toPercentage(el?.untried)}</SingleData>
                  </SingleDataRow>
                )
              }
            })}
          </DataChart>
        </PercentageChart>
      </ChartContainer>
    </>
  )
}

const ChartContainer = styled.div`
  display: block;
  height: 70px;
  position: relative;
  svg {
    border-radius: 8px;
    box-shadow: ${({ theme }) => theme.shadows.mainShadow};
    background: ${({ theme }) => theme.colors.backgrounds.main} !important;
  }

  .apexcharts-title-text {
    fill: ${({ theme }) => theme.colors.main.heading} !important;
  }
  .apexcharts-yaxis-label {
    transform: translateX(-8px);
  }

  .apexcharts-bar-series.apexcharts-plot-series .apexcharts-series path {
    clip-path: inset(0px 0px 0px 0px round 10px);
  }

  .apexcharts-bar-area,
  .apexcharts-backgroundBar {
    animation: swipe-bar ${randomSeconds(300, 2500)}ms ease-in;
  }

  .apexcharts-bar-area:nth-child(1),
  .apexcharts-backgroundBar:nth-child(1) {
    animation-duration: 1.3s;
  }

  .apexcharts-bar-area:nth-child(2),
  .apexcharts-backgroundBar:nth-child(2) {
    animation-duration: 1.6s;
  }

  .apexcharts-bar-area:nth-child(3),
  .apexcharts-backgroundBar:nth-child(3) {
    animation-duration: 2s;
  }

  .apexcharts-bar-area:nth-child(4),
  .apexcharts-backgroundBar:nth-child(4) {
    animation-duration: 0.8s;
  }

  .apexcharts-bar-area:nth-child(5),
  .apexcharts-backgroundBar:nth-child(5) {
    animation-duration: 1.5s;
  }

  .apexcharts-bar-area:nth-child(6),
  .apexcharts-backgroundBar:nth-child(6) {
    animation-duration: 1.1s;
  }

  .apexcharts-bar-area:nth-child(7),
  .apexcharts-backgroundBar:nth-child(7) {
    animation-duration: 1.7s;
  }

  .apexcharts-bar-area:nth-child(8),
  .apexcharts-backgroundBar:nth-child(8) {
    animation-duration: 2.2s;
  }

  @keyframes swipe-bar {
    0% {
      transform: translateX(-1000px);
    }
    100% {
      transform: translateX(0px);
    }
  }
`

const PercentageChart = styled.div`
  position: absolute;
  top: 1px;
  right: 0;
  padding: 25px 5px;
  width: 120px;
  height: ${({ height }) => height * 33.75 + 'px'};
  height: 70px;
`

const Title = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: 700;
`

const ColorPointer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 10px 0px;
  width: 110px;
`

const Orange = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50px;
  background-color: ${({ theme }) =>
    theme.colors.dashboard.questions.correctColor};
  margin-left: 1px;
`

const Red = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50px;
  background-color: ${({ theme }) =>
    theme.colors.dashboard.questions.incorrectColor};
`

const Grey = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50px;
  background-color: ${({ theme }) =>
    theme.colors.dashboard.questions.untriedColor};
`

const DataChart = styled.div``
const SingleDataRow = styled.div`
  text-align: right;
  display: flex;
  justify-content: space-evenly;
  padding: ${({ padding, item }) => padding[item] + 'px'} 0px;
  color: ${({ theme }) => theme.colors.main.grey600};
  font-size: 12px;
  height: ${({ height }) => 194 / height + 'px'};
`

const SingleData = styled.div`
  width: 10px;
  text-align: right;
  text-align: right !important;
  min-width: 12px;
  padding-bottom: 2px;
`

const Icon = styled.div`
  position: absolute;
  top: 22px;
  left: 22px;
  width: 24px;
  height: 24px;
  z-index: 1000;
  color: ${({ theme }) => theme.colors.main.white};
  background: ${({ theme }) => theme.colors.dashboard.totalProgressBackground};
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;

  svg {
    font-size: 16px;
    border-radius: unset;
    box-shadow: none;
    background: none !important;
  }
`

const TotalProgress = styled.div`
  position: absolute;
  top: 22px;
  right: 122px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-transform: capitalize;
  box-shadow: ${({ theme, isDarkTheme }) =>
    isDarkTheme
      ? `inset -1px -1px 4px 0px ${theme.colors.dashboard.totalProgressShadowLight},
         inset 1px 1px 4px 0px ${theme.colors.dashboard.totalProgressShadowDark}`
      : 'none'};
  padding: 5px 10px;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.main.white};
  font-size: ${({ theme }) => theme.typography.fontSizeSmall};
  background-color: ${({ theme, isDarkTheme }) =>
    !isDarkTheme
      ? theme.colors.main.grey200
      : theme.colors.dashboard.speedometer.white};
  svg {
    color: ${({ theme, isDarkTheme }) =>
      !isDarkTheme
        ? theme.colors.dashboard.totalProgress
        : theme.colors.main.white};
    border-radius: 0px;
    box-shadow: none;
    background: none !important;
  }
  p {
    color: ${({ theme, isDarkTheme }) =>
      !isDarkTheme
        ? theme.colors.dashboard.totalProgress
        : theme.colors.main.white};
    font-size: ${({ theme }) => theme.typography.fontSizeExtraSmall};
    margin-left: 5px;
    span {
      margin-left: 4px;
      strong {
        margin-left: 4px;
        color: ${({ theme, isDarkTheme }) =>
          !isDarkTheme
            ? theme.colors.dashboard.totalProgress
            : theme.colors.main.white};
        font-weight: 700;
      }
    }
  }
`

const DotColor = styled.span`
  position: relative;
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 1px;
  border-style: solid;
  background: ${({ color }) => color};
  height: 6px;
  width: 6px;
  left: 0px;
  top: 0px;
  border-width: 0px;
  border-radius: 50px;
`
