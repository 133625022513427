import React from 'react'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import rootReducer, { rootSaga } from 'ducks/rootReducer'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'

const loggerDisabledActions = [
  'SET_CHAPTER_READING_TIME_PASSED_SECONDS/TRIGGER',
  'SET_CHAPTER_READING_TIME_PASSED_SECONDS/SUCCESS',
  'SET_LEARNING_TIME_PASSED_SECONDS/TRIGGER',
  'SET_LEARNING_TIME_PASSED_SECONDS/REQUEST',
  'SET_LEARNING_TIME_PASSED_SECONDS/SUCCESS',
  'FETCH_COURSE_TIME_STATE/TRIGGER',
  'FETCH_COURSE_TIME_STATE/REQUEST',
  'FETCH_COURSE_TIME_STATE/SUCCESS',
  'RESET_INACTIVE_TIME_PASSED_SECONDS/TRIGGER',
  'RESET_INACTIVE_TIME_PASSED_SECONDS/REQUEST',
  'RESET_INACTIVE_TIME_PASSED_SECONDS/SUCCESS',
  'SET_INACTIVE_TIME_PASSED_SECONDS/TRIGGER',
  'SET_INACTIVE_TIME_PASSED_SECONDS/SUCCESS'
]

const sagaMiddleware = createSagaMiddleware()
const loggerMiddleware: any = createLogger({
  collapsed: true,
  predicate: (getState, action) => !loggerDisabledActions.includes(action.type)
})

const middlewares = [sagaMiddleware]

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(loggerMiddleware)
}

const store = createStore(
  // @ts-ignore
  rootReducer,
  applyMiddleware(...middlewares)
)
sagaMiddleware.run(rootSaga)

interface ReduxProviderProps {
  children: JSX.Element
}

function ReduxProvider(props: ReduxProviderProps): JSX.Element {
  return <Provider store={store}>{props.children}</Provider>
}

export default ReduxProvider
