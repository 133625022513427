import React from 'react'

const LightbulbIcon: React.FC = () => (
  <div
    style={{
      backgroundColor: 'black',
      borderRadius: '4px',
      padding: '4px',
      width: '24px',
      height: '24px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.00065 13.3333H10.0007V14.6666H6.00065V13.3333ZM11.2713 9.14125C11.958 8.33725 12.6673 7.50592 12.6673 5.99992C12.6673 3.42725 10.5733 1.33325 8.00065 1.33325C5.42798 1.33325 3.33398 3.42725 3.33398 5.99992C3.33398 7.52325 4.04532 8.35192 4.73465 9.15325C4.97332 9.43192 5.22065 9.72059 5.45732 10.0526C5.55332 10.1899 5.71065 10.7166 5.85132 11.3333H5.33398V12.6666H10.6673V11.3333H10.1513C10.2933 10.7153 10.4513 10.1873 10.5467 10.0499C10.7813 9.71459 11.0307 9.42325 11.2713 9.14125ZM9.45532 9.28325C9.16598 9.69459 8.92465 10.6666 8.78465 11.3333H7.21732C7.07798 10.6653 6.83598 9.69125 6.54332 9.27992C6.29316 8.93614 6.02709 8.60423 5.74598 8.28525C5.09665 7.52925 4.66732 7.02925 4.66732 5.99992C4.66732 4.16192 6.16265 2.66659 8.00065 2.66659C9.83865 2.66659 11.334 4.16192 11.334 5.99992C11.334 7.01392 10.9053 7.51592 10.2573 8.27525C10.0087 8.56725 9.72665 8.89725 9.45532 9.28325Z'
        fill='white'
      />
    </svg>
  </div>
)

export default LightbulbIcon
