import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { EXAM_DIAGRAM_COLOR_ACCENTS } from 'pages/ExamScoreSheet'

type RawScoreBarsProps = {
  examDetails: any
  nonFLexam?: boolean
}

const RawScoreBars = ({ examDetails, nonFLexam }: RawScoreBarsProps) => {
  const { t } = useTranslation()
  const calculatePercetage = (value: number, total: number) => {
    return (value / total) * 100
  }

  const examQuestions = examDetails?.questions || []
  const getExamQuestionsLength = (questions: any[]) => {
    let count = 0
    questions.forEach(question => {
      count += question.sectionLength.amount
    })

    return count
  }

  return (
    <RawScoreBarsWrapper>
      <RawScoreBarsTitle>
        <h4>{t('diagnostics.scoreSheet.rawScore')}</h4>
        <p>
          Total: <span>{examDetails?.exam?.scores?.exam?.amount_correct} </span>
          out of {getExamQuestionsLength(examQuestions)}
        </p>
      </RawScoreBarsTitle>
      <BarsContainer>
        <MainExamBar>
          <RawScoreBar>
            <p>
              {examDetails.exam.originalExam.custom_title
                ? examDetails.exam?.originalExam?.custom_title
                : examDetails.exam?.title}
            </p>
            <BackgroundBar color={EXAM_DIAGRAM_COLOR_ACCENTS.overall}>
              <FillBar
                color={EXAM_DIAGRAM_COLOR_ACCENTS.overall}
                height={calculatePercetage(
                  examDetails?.exam?.scores?.exam?.amount_correct,
                  getExamQuestionsLength(examQuestions)
                )}
              />
            </BackgroundBar>
            <RawScore>
              {examDetails?.exam?.scores?.exam?.amount_correct} /{' '}
              <MaxScore> {getExamQuestionsLength(examQuestions)}</MaxScore>
            </RawScore>
          </RawScoreBar>
        </MainExamBar>
        {nonFLexam ? null : (
          <>
            <Spacer />
            <RawScoreBar>
              <p>{examDetails?.exam?.scores?.sections[0].title}</p>
              <BackgroundBar color={EXAM_DIAGRAM_COLOR_ACCENTS.first_section}>
                <FillBar
                  color={EXAM_DIAGRAM_COLOR_ACCENTS.first_section}
                  height={calculatePercetage(
                    examDetails?.exam?.scores?.sections[0].amount_correct,
                    examQuestions[0].sectionLength.amount
                  )}
                />
              </BackgroundBar>
              <RawScore>
                {examDetails?.exam?.scores?.sections[0].amount_correct} /{' '}
                <MaxScore>{examQuestions[0].sectionLength.amount}</MaxScore>
              </RawScore>
            </RawScoreBar>
            <RawScoreBar>
              <p>{examDetails?.exam?.scores?.sections[1].title}</p>
              <BackgroundBar color={EXAM_DIAGRAM_COLOR_ACCENTS.second_section}>
                <FillBar
                  color={EXAM_DIAGRAM_COLOR_ACCENTS.second_section}
                  height={calculatePercetage(
                    examDetails?.exam?.scores?.sections[1].amount_correct,
                    examQuestions[1].sectionLength.amount
                  )}
                />
              </BackgroundBar>
              <RawScore>
                {examDetails?.exam?.scores?.sections[1].amount_correct} /{' '}
                <MaxScore>{examQuestions[1].sectionLength.amount} </MaxScore>
              </RawScore>
            </RawScoreBar>
            <RawScoreBar>
              <p>{examDetails?.exam?.scores?.sections[2].title}</p>
              <BackgroundBar color={EXAM_DIAGRAM_COLOR_ACCENTS.third_section}>
                <FillBar
                  color={EXAM_DIAGRAM_COLOR_ACCENTS.third_section}
                  height={calculatePercetage(
                    examDetails?.exam?.scores?.sections[2].amount_correct,
                    examQuestions[2].sectionLength.amount
                  )}
                />
              </BackgroundBar>
              <RawScore>
                {examDetails?.exam?.scores?.sections[2].amount_correct} /{' '}
                <MaxScore>{examQuestions[2].sectionLength.amount}</MaxScore>
              </RawScore>
            </RawScoreBar>
            <RawScoreBar>
              <p>{examDetails?.exam?.scores?.sections[3].title}</p>
              <BackgroundBar color={EXAM_DIAGRAM_COLOR_ACCENTS.fourth_section}>
                <FillBar
                  color={EXAM_DIAGRAM_COLOR_ACCENTS.fourth_section}
                  height={calculatePercetage(
                    examDetails?.exam?.scores?.sections[3].amount_correct,
                    examQuestions[3].sectionLength.amount
                  )}
                />
              </BackgroundBar>
              <RawScore>
                {examDetails?.exam?.scores?.sections[3].amount_correct} /{' '}
                <MaxScore> {examQuestions[3].sectionLength.amount}</MaxScore>
              </RawScore>
            </RawScoreBar>
          </>
        )}
      </BarsContainer>
    </RawScoreBarsWrapper>
  )
}

export default RawScoreBars

const RawScoreBarsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 6px;
`

const MainExamBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
`

const Spacer = styled.div`
  height: 2px;
  background-color: ${({ theme }) => theme.colors.main.grey400};
  width: 100%;
`

const RawScoreBarsTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 16px;

  h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  p {
    background-color: #fff4d7;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 16px;

    span {
      font-weight: 700;
    }
  }
`

const BarsContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  gap: 8px;
  padding: 16px;
`
const RawScoreBar = styled.div`
  display: grid;
  grid-template-columns: 0.25fr 1fr 70px;
  width: 100%;
  gap: 8px;
  align-items: center;
`
const MaxScore = styled.span`
  color: ${({ theme }) => theme.colors.main.grey600};
`

const BackgroundBar = styled.div`
  position: relative;
  box-shadow: 0px 2px 5px 0px #0000002b;
  background-color: ${({ theme }) => theme.colors.main.white};
  border-radius: 6px;
  border: 1px solid
    ${({ theme, color }) => (color ? color : theme.colors.main.primary500)};
  height: 8px;
  width: 100%;
`

const FillBar = styled.div`
  position: absolute;
  bottom: 0;
  border-radius: 4px;
  background-color: ${({ theme, color }) =>
    color ? color : theme.colors.main.primary500};
  /* height: ${({ height }) => height}%; */
  height: 100%;
  width: ${({ height }) => height}%;
`
const RawScore = styled.p`
  text-align: right;
`
