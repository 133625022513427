import React, { useState, useEffect, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { propOr } from 'ramda'

import saltyCalendarInit from 'assets/images/saltyCalendarInit.svg'

import {
  Button,
  SingleSelect,
  BouncingLoader,
  CalendarIcon,
  Tooltip
} from 'examkrackers-components'

import { DATE_FORMATS, formatDate } from 'utils/date'
// import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import {
  ANALYTICS_EVENTS,
  handleActionAnalytics,
  getCourseTypeForAnalyticsEvents
} from 'utils/analytics'
import {
  columns,
  columnsOrder,
  countStudyDaysInRange,
  daysOfWeek
} from 'utils/calendar'
import { getOptionByValue } from 'utils/form'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import PATHS from 'utils/paths'
// import { SEVERITY } from 'utils/toast'

import {
  getMcatDates,
  setExamDate,
  setPrioriDays
} from 'services/CalendarService'

import AnalyticsService from 'services/AnalyticsService'

import { getStudent } from 'modules/auth/ducks/selectors'
import { showToastRoutine } from 'modules/toast/ducks/actions'

import DateInput from 'components/DateInput/DateInput'
import LeftColumnQuestions from './LeftColumnQuestions'
import DayListItem from './DayListItem'
import useCalendarSettingsValidation from 'hooks/useCalendarSettingsValidation'
import { isAfter } from 'date-fns'
import withSettingsPageWrapper from 'modules/calendar/SettingsPageWrapper'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'

interface McatDate {
  id: string
  course_id: string
  mcat_date: string
}

const initialValues = {
  mcat_at: '',
  calendar_start_at: '',
  exam_at: '',
  prioriDays: [0, 0, 0, 0, 0, 0, 0]
}

enum LoadingState {
  pristine = 'pristine',
  loading = 'loading',
  success = 'success',
  error = 'error'
}

// const WrappedCalendarInit = () => {
//   return (
//     <CalendarContextProvider>
//       <CalendarInit />
//     </CalendarContextProvider>
//   )
// }

const CalendarInit: React.FC = () => {
  // const originalCourseIdLocal = LocalStorageService.get(
  //   LOCAL_STORAGE_KEYS.originalCourseId
  // )
  const [values, setValues] = useState(initialValues)

  const [datesMcat, setDatesMcat] = useState<McatDate[]>([])
  const [columnsData, setColumnsData] = useState(columns)
  const [isLoading, setIsLoading] = React.useState<LoadingState>(
    LoadingState.pristine
  )
  const course = useSelector(getCurrentCourse)
  // const { course } = useContext(CalendarContext)

  const { t } = useTranslation()

  const { push } = useHistory()

  const amountOfDays = course.metadata && JSON.parse(course.metadata)
  const parsedAmountOfDays =
    amountOfDays && propOr(0, 'days_amount', amountOfDays)
  const isOneWeekSubscription = parsedAmountOfDays == 7

  const courseId = course.book_course_id
  const courseStudentId = course.id
  const isExpirationCourse = course.type === 'live_course'

  // const isReady = propOr(false, 'is_ready', course)
  const user = useSelector(getStudent)

  const mcatDateOptions: { label: string; value: string }[] = useMemo(() => {
    return datesMcat
      .filter(date => isAfter(new Date(date.mcat_date), new Date()))
      .sort(
        (a, b) =>
          new Date(a.mcat_date).getTime() - new Date(b.mcat_date).getTime()
      )
      .map(date => ({
        label: formatDate(date.mcat_date, DATE_FORMATS.slash),
        value: propOr('', 'id', date)
      }))
  }, [datesMcat])

  const courseAnalysis = () => {
    const action = handleActionAnalytics(course)
    let data = {}

    if (action === 'start') {
      data = {
        Time: formatDate(new Date(), DATE_FORMATS.dashedWithTime),
        'Course type': course?.type || false,
        'Course name': course?.title || false,
        'Course expiration date': course?.accessible_to || false,
        'Course class end date': `${course?.calendar_start_at}` || false
      }
    } else {
      data = {
        Time: formatDate(new Date(), DATE_FORMATS.dashedWithTime),
        'Course type': course?.type || false,
        'Course name': course?.title || false,
        'Course expiration date': course?.accessible_to || false
      }
    }
    AnalyticsService(user).logEvent(
      ANALYTICS_EVENTS[getCourseTypeForAnalyticsEvents(action, course?.type)],
      data
    )
  }

  // const saveCourseInLocalStorage = () => {
  //   LocalStorageService.set(LOCAL_STORAGE_KEYS.studentCourseId, course.id)
  //   LocalStorageService.set(
  //     LOCAL_STORAGE_KEYS.originalCourseId,
  //     course.book_course_id
  //   )
  //   LocalStorageService.set(LOCAL_STORAGE_KEYS.studentCourseType, course.type)
  //   if (course.end_date_id) {
  //     LocalStorageService.set(
  //       LOCAL_STORAGE_KEYS.studentCourseEndDateId,
  //       course.end_date_id
  //     )
  //   }
  // }
  // changed for new init flow
  const redirectToCourseCalendar = prioriDays => {
    courseAnalysis()
    // saveCourseInLocalStorage()
    const isPrioriDays = isNotNilOrEmpty(prioriDays)

    isPrioriDays ? push(PATHS.calendar) : push(PATHS.calendarSetup)
  }

  // const handleInitialise = productType => {
  //   const handleSuccess = () => {
  //     AnalyticsService(user).logEvent(
  //       ANALYTICS_EVENTS[
  //         getCourseTypeForAnalyticsEvents('start', course?.type)
  //       ],
  //       {
  //         Time: formatDate(new Date(), DATE_FORMATS.dashedWithTime),
  //         'Course type': course?.type || false,
  //         'Course name': course?.title || false,
  //         'Course expiration date': course?.accessible_to || false,
  //         'Course class end date': `${course?.calendar_start_at}` || false
  //       }
  //     )
  //   }
  //   const handleError = e => {
  //     console.error(e)
  //     showToast({
  //       key: 'toast.somethingWentWrong',
  //       severity: SEVERITY.error
  //     })
  //   }

  //   initialiseCourse({
  //     id: course.id as string
  //   })
  //     .then(handleSuccess)
  //     .catch(handleError)
  //   // changed for new init flow
  //   // saveCourseInLocalStorage()
  //   if (productType === typeOfProduct.course) {
  //     push(PATHS.dashboardLoading)
  //   } else {
  //     push(PATHS.examLoading)
  //   }
  // }
  // changed for new init flow (unused)
  // const handleSelectCourse = async () => {
  //   try {
  //     if (isReady && isLoading === LoadingState.success) {
  //       await redirectToCourseCalendar(prioriDays)
  //     } else {
  //       await handleInitialise(typeOfProduct.course)
  //     }
  //   } catch (error) {
  //     console.error('Error in handleSelectCourse:', error)
  //     throw error // or handle it as appropriate for your use case
  //   }
  // }

  // keep it for now (browser's back button control concept)

  // const [isModalOpen, setIsModalOpen] = useState(false)
  // useEffect(() => {
  //   // Push a new state to the history stack
  //   window.history.pushState(null, document.title, window.location.href)

  //   // Add an event listener for the popstate event
  //   const handlePopState = event => {
  //     setIsModalOpen(true)
  //     window.history.pushState(null, document.title, window.location.href)
  //   }

  //   window.addEventListener('popstate', handlePopState)

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener('popstate', handlePopState)
  //   }
  // }, [location])

  const {
    dndErrors,
    startDateMax,
    examDateBoundaries,
    errors,
    touchedFields,
    setTouchedFields
  } = useCalendarSettingsValidation({
    course,
    values,
    mcatDateOptions,
    columnsData,
    isReorder: false
  })

  const handleDragEnd = result => {
    const { source, destination, draggableId } = result
    setTouchedFields(prev => [...prev, 'priori_days'])

    if (destination.droppableId === 'mcatDay') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 6
    } else if (destination.droppableId === 'reviewDay') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 7
    } else if (destination.droppableId === 'priorityOne') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 1
    } else if (destination.droppableId === 'priorityTwo') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 2
    } else if (destination.droppableId === 'priorityThree') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 3
    } else if (destination.droppableId === 'priorityFour') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 4
    } else if (destination.droppableId === 'priorityFive') {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 5
    } else {
      values.prioriDays[daysOfWeek.indexOf(draggableId)] = 0
    }

    if (!destination) {
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    const startColumn = columnsData[source.droppableId]
    const finishColumn = columnsData[destination.droppableId]

    if (startColumn === finishColumn) {
      const newDay = Array.from(startColumn.day)
      const [removed] = newDay.splice(0, 1)
      newDay.splice(destination.index, 0, removed)

      const newColumn = {
        ...startColumn,
        day: newDay
      }

      const newState = {
        ...columnsData,
        [source.droppableId]: newColumn
      }
      setColumnsData(newState)
    } else {
      const startNewDay = startColumn.day
      const [removed] = startNewDay.splice(0, 1)
      const newStartColumn = {
        ...startColumn,
        day: startNewDay
      }
      const finishNewDay = Array.from(finishColumn.day)
      finishNewDay.splice(destination.index, 0, removed)
      const newFinishColumn = {
        ...finishColumn,
        day: finishNewDay
      }

      const newState = {
        ...columnsData,
        [source.droppableId]: newStartColumn,
        [destination.droppableId]: newFinishColumn
      }

      setColumnsData(newState)
    }
  }

  const handleChangeMcatDate = option => {
    setValues(prev => ({ ...prev, mcat_at: option.value }))
    setTouchedFields(prev => [...prev, 'mcat_at'])
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
    setTouchedFields(prev => [...prev, name])
  }

  const studyDaysCount = countStudyDaysInRange(
    values.calendar_start_at,
    values.exam_at,
    values.prioriDays
  )

  const handleSaveDate = async () => {
    setIsLoading(LoadingState.loading)
    try {
      const shouldStartManualCalendar =
        values.exam_at === '' || studyDaysCount < 11

      // changed for new init flow
      // await handleSelectCourse()
      await setPrioriDays({
        id: courseStudentId,
        prioridays: values.prioriDays
      })

      await setExamDate({
        calendar_start_at: values.calendar_start_at,
        exam_at: values.exam_at,
        course_id: courseStudentId,
        mcat_date_id: values.mcat_at,
        build_calendar: shouldStartManualCalendar ? -1 : 1
      })

      courseAnalysis()

      setIsLoading(LoadingState.success)

      await redirectToCourseCalendar(values.prioriDays)
    } catch (e) {
      console.error(e)
      setIsLoading(LoadingState.error)
    }
    setIsLoading(LoadingState.pristine)
  }

  useEffect(() => {
    courseId &&
      getMcatDates({ id: courseId })
        .then(resp => {
          setDatesMcat(resp.data.data)
        })
        .catch(err => {
          console.error(err)
        })
  }, [])
  // changed for new init flow (unused)
  // useEffect(() => {
  //   ;[
  //     LOCAL_STORAGE_KEYS.studentCourseId,
  //     LOCAL_STORAGE_KEYS.studentCourseType,
  //     LOCAL_STORAGE_KEYS.studentCourseEndDateId,
  //     LOCAL_STORAGE_KEYS.originalCourseId
  //   ].forEach(key => LocalStorageService.remove(key))
  // }, [])

  const { calendar_start_at, mcat_at, exam_at, prioriDays } = values
  const hasAllValues = [calendar_start_at, mcat_at, exam_at, prioriDays].every(
    el => isNotNilOrEmpty(el)
  )

  const renderConfirmButton = () => {
    if (isNotNilOrEmpty(errors) && !isOneWeekSubscription) {
      return (
        <Tooltip
          id='inactive-button'
          tooltipContent='This form must be completed in order to continue. You will be able to make changes at any time by clicking on the Reorganize button on your calendar.'
        >
          <Button
            data-testid='CalendarInit-renderConfirmButton-case1'
            variant='contained'
            color='secondary'
            size='small'
            onClick={handleSaveDate}
            disabled
          >
            {t('calendar.setup.button')}
          </Button>
        </Tooltip>
      )
    } else if (isOneWeekSubscription && isNotNilOrEmpty(values.mcat_at)) {
      return (
        <Button
          data-testid='CalendarInit-renderConfirmButton-case2'
          variant='contained'
          color='secondary'
          size='small'
          onClick={handleSaveDate}
          disabled={!isOneWeekSubscription}
        >
          {t('calendar.setup.button')}
        </Button>
      )
    } else {
      return (
        <Button
          data-testid='CalendarInit-renderConfirmButton-case3'
          variant='contained'
          color='secondary'
          size='small'
          onClick={handleSaveDate}
          disabled={isNotNilOrEmpty(errors)}
        >
          {t('calendar.setup.button')}
        </Button>
      )
    }
  }

  const handleCalendarManualOpen = (id: string) => () => {
    const el = document.getElementById(id)

    if (el) {
      el.click()
    }
  }

  useEffect(() => {
    isExpirationCourse ? push(PATHS.calendarSetupExpiration) : null
  }, [])

  if (isLoading === LoadingState.loading) {
    return (
      <LoaderContainer>
        <BouncingLoader />
      </LoaderContainer>
    )
  }

  return (
    <Container data-testid='CalendarInit-Container'>
      <TitleWrapper data-testid='CalendarInit-TitleWrapper'>
        {t('calendar.setup.title')}
      </TitleWrapper>
      {/* stays hidden until reorganize flow is fixed */}
      {/* <SubtitleWrapper>{t('calendar.setup.description')}</SubtitleWrapper> */}
      <ColumnsWrapper>
        <LeftColumn data-testid='CalendarInit-LeftColumn'>
          <QuestionContainer data-testid='CalendarInit-QuestionContainer-mcat'>
            <DescWrapper>
              <QuestionTitle data-testid='CalendarInit-QuestionTitle'>
                {t('calendar.setup.mcat.question')}
              </QuestionTitle>
              <QuestionDescription data-testid='CalendarInit-QuestionDescription'>
                {t('calendar.setup.mcat.tooltip')}
              </QuestionDescription>
            </DescWrapper>
            <SelectWrapper>
              <StyledSelect
                data-testid='CalendarInit-StyledSelect-mcatDate'
                removeMargin
                label=''
                size='small'
                options={mcatDateOptions}
                onChange={handleChangeMcatDate}
                disabled={isNilOrEmpty(mcatDateOptions)}
                value={getOptionByValue(values.mcat_at)(mcatDateOptions)}
              />
            </SelectWrapper>
          </QuestionContainer>
          {errors.mcatDate && touchedFields.includes('mcat_at') && (
            <Error>{errors.mcatDate}</Error>
          )}
          <QuestionContainer
            data-testid='CalendarInit-QuestionContainer-start_at'
            disabled={isNilOrEmpty(values.mcat_at) || isOneWeekSubscription}
          >
            <DescWrapper>
              <QuestionTitle data-testid='CalendarInit-QuestionTitle-start_at'>
                {t('calendar.setup.start.question')}
              </QuestionTitle>
              <QuestionDescription>
                {t('calendar.setup.start.tooltip')}
              </QuestionDescription>
            </DescWrapper>

            <PickerWrapper>
              <DateInput
                data-testid='CalendarInit-DateInput-start_at'
                name='calendar_start_at'
                id='calendar_start_at_input'
                value={values.calendar_start_at}
                validate={() => true}
                size='small'
                onChange={handleValueChange}
                minDate={new Date()}
                maxDate={startDateMax}
              />
            </PickerWrapper>

            <IconContainer>
              <CalendarIcon
                data-testid='CalendarInit-CalendarIcon-start_at'
                onClick={handleCalendarManualOpen('calendar_start_at_input')}
              />
            </IconContainer>
          </QuestionContainer>
          {errors.startDate && touchedFields.includes('calendar_start_at') && (
            <Error>{errors.startDate}</Error>
          )}
          <QuestionContainer
            disabled={isNilOrEmpty(values.calendar_start_at)}
            data-testid='CalendarInit-QuestionContainer-exam_at'
          >
            <DescWrapper>
              <QuestionTitle data-testid='CalendarInit-QuestionTitle-exam_at'>
                {t('calendar.setup.end.question')}
              </QuestionTitle>
              <QuestionDescription>
                {t('calendar.setup.end.tooltip')}
              </QuestionDescription>
            </DescWrapper>

            <PickerWrapper>
              <DateInput
                data-testid='CalendarInit-DateInput-exam_at'
                name='exam_at'
                id='exam_at_input'
                value={values.exam_at}
                validate={() => true}
                size='small'
                // t={t}
                onChange={handleValueChange}
                minDate={examDateBoundaries.min}
                maxDate={course.accessible_to?.toString()}
                course={course}
                withExtendModal
              />
            </PickerWrapper>
            <IconContainer>
              <CalendarIcon
                data-testid='CalendarInit-CalendarIcon-exam_at'
                onClick={handleCalendarManualOpen('exam_at_input')}
              />
            </IconContainer>
          </QuestionContainer>
          {errors.endDate && touchedFields.includes('exam_at') && (
            <Error>{errors.endDate}</Error>
          )}
          <LeftColumnQuestions disabled={isNilOrEmpty(values.exam_at)} />
        </LeftColumn>
        <RightColumn data-testid='CalendarInit-RightColumn'>
          <ImageContainer data-testid='CalendarInit-ImageContainer-saltyCalendarInit'>
            <img src={saltyCalendarInit} alt='book-cover-all' />
          </ImageContainer>
          <DragDropContext
            onDragEnd={handleDragEnd}
            data-testid='CalendarInit-DragDropContext'
          >
            <WrapperDnd disabled={isNilOrEmpty(values.exam_at)}>
              <WeekDaysColumn>
                <>
                  <ColumnTitle data-testid='CalendarInit-ColumnTitle'>
                    {t('calendar.setup.mandatoryDaysOff')}
                  </ColumnTitle>
                  {columnsOrder.map((columnId, index) => {
                    const column = columnsData[columnId]
                    if (index <= 6) {
                      return (
                        <DropZoneWrapper
                          key={column.id}
                          data-testid={`CalendarInit-DropZoneWrapper-${column.id}`}
                        >
                          <Droppable
                            data-testid={`CalendarInit-Droppable-mandatoryDaysOff-${column.id}`}
                            droppableId={column.id}
                            isDropDisabled={
                              column.day && column.day.length === 1
                            }
                          >
                            {(provided, snapshot) => {
                              return (
                                <SingleDropZone
                                  data-testid={`CalendarInit-SingleDropZone-mandatoryDaysOff-${column.id}`}
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                >
                                  <DropContainer
                                    data-testid={`CalendarInit-DropContainer-mandatoryDaysOff-${column.title}`}
                                  >
                                    {column.title}
                                  </DropContainer>

                                  {isNotNilOrEmpty(column.day) && (
                                    <DayListItem
                                      data-testid={`CalendarInit-DayListItem-mandatoryDaysOff-${column.day[0]}`}
                                      day={column.day[0]}
                                      index={index}
                                    />
                                  )}
                                  {provided.placeholder}
                                </SingleDropZone>
                              )
                            }}
                          </Droppable>
                        </DropZoneWrapper>
                      )
                    } else {
                      return null
                    }
                  })}
                </>
              </WeekDaysColumn>
              <McatDaysColumn>
                <>
                  <McatContainer data-testid='CalendarInit-McatContainer'>
                    <ColumnTitle data-testid='CalendarInit-ColumnTitle-mcat'>
                      {t('calendar.setup.fullLengthMCATDay')}
                    </ColumnTitle>
                    {columnsOrder.map((columnId, index) => {
                      const column = columnsData[columnId]
                      if (index === 7) {
                        return (
                          <DropZoneWrapper
                            key={column.id}
                            data-testid={`CalendarInit-DropZoneWrapper-fullLengthMCATDay-${column.id}`}
                          >
                            <Droppable
                              data-testid={`CalendarInit-Droppable-fullLengthMCATDay-${column.id}`}
                              droppableId={column.id}
                              isDropDisabled={
                                column.day && column.day.length === 1
                              }
                            >
                              {(provided, snapshot) => {
                                return (
                                  <SingleDropZoneActive
                                    data-testid={`CalendarInit-SingleDropZoneActive-fullLengthMCATDay-${column.id}`}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    isDraggingOver={snapshot.isDraggingOver}
                                  >
                                    <DropContainer
                                      data-testid={`CalendarInit-DropContainer-${column.title}`}
                                    >
                                      {column.title}
                                    </DropContainer>
                                    {isNotNilOrEmpty(column.day) && (
                                      <DayListItem
                                        data-testid={`CalendarInit-DayListItem-${column.day[0]}`}
                                        day={column.day[0]}
                                        index={index}
                                      />
                                    )}
                                    {provided.placeholder}
                                  </SingleDropZoneActive>
                                )
                              }}
                            </Droppable>
                          </DropZoneWrapper>
                        )
                      } else {
                        return null
                      }
                    })}
                  </McatContainer>
                  <McatContainer data-testid='CalendarInit-McatContainer'>
                    <ColumnTitle data-testid='CalendarInit-ColumnTitle'>
                      {t('calendar.setup.fullLengthMCATReviewDay')}
                    </ColumnTitle>
                    {columnsOrder.map((columnId, index) => {
                      const column = columnsData[columnId]
                      if (index === 8) {
                        return (
                          <DropZoneWrapper
                            key={column.id}
                            data-testid={`CalendarInit-DropZoneWrapper-fullLengthMCATReviewDay-${column.id}`}
                          >
                            <Droppable
                              data-testid={`CalendarInit-Droppable-fullLengthMCATReviewDay-${column.id}`}
                              droppableId={column.id}
                              isDropDisabled={
                                column.day && column.day.length === 1
                              }
                            >
                              {(provided, snapshot) => {
                                return (
                                  <SingleDropZoneActive
                                    data-testid={`CalendarInit-SingleDropZoneActive-fullLengthMCATReviewDay-${column.id}`}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    isDraggingOver={snapshot.isDraggingOver}
                                  >
                                    <DropContainer
                                      data-testid={`CalendarInit-DropContainer-${column.title}`}
                                    >
                                      {column.title}
                                    </DropContainer>
                                    {isNotNilOrEmpty(column.day) && (
                                      <DayListItem
                                        data-testid={`CalendarInit-DayListItem-${column.day[0]}`}
                                        day={column.day[0]}
                                        index={index}
                                      />
                                    )}
                                    {provided.placeholder}
                                  </SingleDropZoneActive>
                                )
                              }}
                            </Droppable>
                          </DropZoneWrapper>
                        )
                      } else {
                        return null
                      }
                    })}
                  </McatContainer>
                </>
              </McatDaysColumn>
              <PrioriColumn data-testid='CalendarInit-PrioriColumn'>
                <>
                  <ColumnTitle>{t('calendar.setup.studyDays')}</ColumnTitle>
                  {columnsOrder.map((columnId, index) => {
                    const column = columnsData[columnId]
                    if (index > 8) {
                      return (
                        <DropZoneWrapper
                          key={column.id}
                          data-testid={`CalendarInit-DropZoneWrapper-studyDays-${column.id}`}
                        >
                          <Droppable
                            data-testid={`CalendarInit-Droppable-studyDays-${column.id}`}
                            droppableId={column.id}
                            isDropDisabled={
                              column.day && column.day.length === 1
                            }
                          >
                            {(provided, snapshot) => {
                              return (
                                <SingleDropZoneActive
                                  data-testid={`CalendarInit-SingleDropZoneActive-studyDays-${column.id}`}
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  isDraggingOver={snapshot.isDraggingOver}
                                >
                                  <DropContainer>{column.title}</DropContainer>
                                  {isNotNilOrEmpty(column.day) && (
                                    <DayListItem
                                      data-testid={`CalendarInit-DayListItem-studyDays-${column.day[0]}`}
                                      day={column.day[0]}
                                      index={index}
                                    />
                                  )}
                                  {provided.placeholder}
                                </SingleDropZoneActive>
                              )
                            }}
                          </Droppable>
                        </DropZoneWrapper>
                      )
                    } else {
                      return null
                    }
                  })}
                </>
              </PrioriColumn>
            </WrapperDnd>
          </DragDropContext>
          {isNotNilOrEmpty(dndErrors) &&
            touchedFields.includes('priori_days') && (
              <DndErrors data-testid='CalendarInit-DndErrors'>
                {dndErrors.map(error => (
                  <Error>{error}</Error>
                ))}
              </DndErrors>
            )}
        </RightColumn>
      </ColumnsWrapper>
      {studyDaysCount > 0 && studyDaysCount < 44 && hasAllValues && (
        <WarningBox>
          <WarningBoxContent>
            Attention: Your current selection has only {studyDaysCount} Study
            Days.
            <br />
            We recommend at least 44 Study Days, so that you have at least one
            study day per chapter.
          </WarningBoxContent>
        </WarningBox>
      )}
      {isOneWeekSubscription && (
        <WarningBox>
          <WarningBoxContent>
            {t('calendar.setupForOneWeekSubWarningPartOne')}
            <br />
            {t('calendar.setupForOneWeekSubWarningPartTwo')}
          </WarningBoxContent>
        </WarningBox>
      )}
      <ButtonWrapper data-testid='CalendarInit-ButtonWrapper-renderConfirmButton'>
        {renderConfirmButton()}
      </ButtonWrapper>
    </Container>
  )
}

export default withSettingsPageWrapper(CalendarInit)

const Container = styled.div`
  margin: 20px 132px;
  font-size: 12px;
  padding-bottom: 30px;
`

const TitleWrapper = styled.div`
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
`

const SubtitleWrapper = styled.div`
  text-align: center;
  font-size: 12px;
  line-height: px;
  margin-top: 10px;
`

const LeftColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const RightColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`

const ColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ImageContainer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 65px;
`
const SingleDropZone = styled.div`
  color: ${({ theme }) => theme.colors.main.grey400};
  background-color: #fbf5f2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 26px;
  gap: 25px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23C5C6C8FF' stroke-width='2' stroke-dasharray='2%2c 8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
`
const SingleDropZoneActive = styled(SingleDropZone)`
  color: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? theme.colors.main.primary500 : '#444'};
  background-color: ${({ theme, isDraggingOver }) =>
    isDraggingOver ? theme.colors.main.primary300 : '#fff3ed'};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%237B7C7DFF' stroke-width='2' stroke-dasharray='2%2c 8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
`

const WrapperDnd = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  margin-top: 36px;

  ${({ disabled }) =>
    disabled &&
    css`
      display: none;
      pointer-events: none;
      cursor: default;
    `}
`
const ColumnTitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
  width: 155px;
  text-align: center;
  font-weight: bold;
`
const WeekDaysColumn = styled.div``
const McatDaysColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
`
const McatContainer = styled.div`
  &:first-child {
    margin-bottom: 33px;
  }
`
const PrioriColumn = styled.div``

const DropZoneWrapper = styled.div`
  margin: 6px;
`
const DropContainer = styled.div`
  position: absolute;
`

const PickerWrapper = styled.div`
  display: flex;

  width: 120px;
  margin-top: -20px;
  margin-bottom: -30px;
`
const QuestionContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 20px;

  ${({ disabled }) =>
    disabled &&
    css`
      display: none;
      pointer-events: none;
      cursor: default;
    `}
`
const QuestionTitle = styled.div`
  font-size: 14px;
  line-height: 30px;
`
const QuestionDescription = styled.div`
  font-size: 10px;
  margin-left: 20px;
  line-height: 20px;
  width: 310px;
`
const DescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 990px; */
`
const IconContainer = styled.div`
  color: ${({ theme }) => theme.colors.main.primary500};
  font-size: 30px;
  display: flex;
  height: 28px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-top: 7px;
  cursor: pointer;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`
const SelectWrapper = styled.div`
  margin-right: 8px;
  width: 100%;
  height: ${({ theme }) => theme.dimensions.inputHeight};
`
const StyledSelect = styled(SingleSelect)`
  min-width: 121px;
  max-width: 121px;

  div {
    overflow-x: hidden;
  }
`
const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Error = styled.div`
  color: red;
`

const DndErrors = styled.div`
  margin-top: 10px;
  padding-left: 10px;
`

const WarningBox = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`

const WarningBoxContent = styled.div`
  text-align: center;
  color: red;
`
