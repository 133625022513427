import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Modal, Button } from 'examkrackers-components'
import { isNotNil } from 'utils/ramda'
import { isNil, propOr } from 'ramda'
import { Exam } from '../../../types'
import { useSelector } from 'react-redux'
import { getAccountType, getStudent } from '../../auth/ducks/selectors'
import AnalyticsService from '../../../services/AnalyticsService'
import { ANALYTICS_EVENTS } from '../../../utils/analytics'
import { DATE_FORMATS, formatDate } from '../../../utils/date'
import ModalRetakeExam from './ModalRetakeExam'

interface ModalConfirmProps {
  id: string
  disabled: boolean
  isOpen?: boolean
  closeCallback?: any
  exam: Exam
  maxCompletionNumber?: number
  numberOfCompletions?: number
}

export const ModalConfirmResume = (props: ModalConfirmProps) => {
  const {
    id,
    disabled,
    isOpen,
    closeCallback,
    exam,
    maxCompletionNumber,
    numberOfCompletions
  } = props
  const { push } = useHistory()
  const [open, setOpen] = React.useState(false)
  const { t } = useTranslation()
  const student = useSelector(getStudent)
  const title = propOr('', 'title', exam)
  const type_title = propOr('', 'type_title', exam)
  const accountType: string = useSelector(getAccountType)

  const maxCompletionNumberValue = maxCompletionNumber ?? 1
  const numberOfCompletionsValue = numberOfCompletions ?? 0

  useEffect(() => {
    if (isNotNil(isOpen)) {
      // @ts-ignore
      setOpen(isOpen)
    }
  }, [isOpen])

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    typeof closeCallback === 'function' && closeCallback()
  }

  const redirectToExam = () => {
    AnalyticsService(student).logEvent(ANALYTICS_EVENTS.examResumed, {
      Time: formatDate(new Date(), DATE_FORMATS.dashedWithTime),
      'Exam Title': title,
      'Exam format': type_title,
      'Account type': accountType
    })
    push(`/exam/${id}`)
  }

  return (
    <>
      {isNil(isOpen) && (
        <Button
          disabled={disabled}
          id={`${id}-resume-exam`}
          onClick={handleOpen}
          size='small'
          color='orange'
        >
          {t('exams.actions.resume')}
        </Button>
      )}
      <ModalRetakeExam
        isOpen={open}
        isResume
        handleClose={handleClose}
        handleStartExam={redirectToExam}
        maxCompletionNumber={maxCompletionNumberValue}
        numberOfCompletions={numberOfCompletionsValue}
      />
    </>
  )
}

export default ModalConfirmResume

const ButtonContainer = styled.div`
  margin: 30px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    min-width: 130px;
  }

  button + button {
    margin-left: 10px;
  }
`

const ModalContainer = styled.div`
  max-width: 400px;
  text-align: left;
  margin: 0 auto;

  ul {
    list-style-type: disc;
    margin-left: 14px;

    li {
      margin-bottom: 10px;
    }
  }
`
