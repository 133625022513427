import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { useTranslation } from 'react-i18next'

interface LeftColumnQuestionsProps {
  disabled: boolean
  isLiveCourse?: boolean
}

const LeftColumnQuestions = ({
  disabled,
  isLiveCourse
}: LeftColumnQuestionsProps) => {
  const { t } = useTranslation()

  return (
    <>
      {isLiveCourse ? (
        <Container
          data-testid='LeftColumnQuestions-Container'
          disabled={disabled}
        >
          <QuestionContainer>
            <QuestionTitle data-testid='LeftColumnQuestions-QuestionTitle'>
              {t('calendar.setup.preferredDays.questionForExpiration')}
            </QuestionTitle>
            <QuestionDescription
              data-testid='LeftColumnQuestions-QuestionDescription-descriptionPartThree'
              dangerouslySetInnerHTML={{
                __html: t(
                  'calendar.setupForExpiration.preferredDays.descriptionPartThree'
                )
              }}
            />
            <QuestionDescription
              data-testid='LeftColumnQuestions-QuestionDescription-descriptionPartFour'
              dangerouslySetInnerHTML={{
                __html: t(
                  'calendar.setupForExpiration.preferredDays.descriptionPartFour'
                )
              }}
            />
            <QuestionDescription
              data-testid='LeftColumnQuestions-QuestionDescription-descriptionPartFive'
              dangerouslySetInnerHTML={{
                __html: t(
                  'calendar.setupForExpiration.preferredDays.descriptionPartFive'
                )
              }}
            />
          </QuestionContainer>
        </Container>
      ) : (
        <Container
          disabled={disabled}
          data-testid='LeftColumnQuestions-Container'
        >
          <QuestionContainer>
            <QuestionTitle data-testid='LeftColumnQuestions-QuestionTitle'>
              {t('calendar.setup.preferredDays.question')}
            </QuestionTitle>
            <QuestionDescription
              data-testid='LeftColumnQuestions-QuestionDescription-descriptionPartOne'
              dangerouslySetInnerHTML={{
                __html: t('calendar.setup.preferredDays.descriptionPartOne')
              }}
            />
            <QuestionDescription
              data-testid='LeftColumnQuestions-QuestionDescription-descriptionPartTwo'
              dangerouslySetInnerHTML={{
                __html: t('calendar.setup.preferredDays.descriptionPartTwo')
              }}
            />
            <QuestionDescription
              data-testid='LeftColumnQuestions-QuestionDescription-descriptionPartThree'
              dangerouslySetInnerHTML={{
                __html: t('calendar.setup.preferredDays.descriptionPartThree')
              }}
            />
            <QuestionDescription
              data-testid='LeftColumnQuestions-QuestionDescription-descriptionPartFour'
              dangerouslySetInnerHTML={{
                __html: t('calendar.setup.preferredDays.descriptionPartFour')
              }}
            />
            <QuestionDescription
              data-testid='LeftColumnQuestions-QuestionDescription-descriptionPartFive'
              dangerouslySetInnerHTML={{
                __html: t('calendar.setup.preferredDays.descriptionPartFive')
              }}
            />
          </QuestionContainer>
        </Container>
      )}
    </>
  )
}

export default LeftColumnQuestions

const fadeId = keyframes`
from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const Container = styled.div`
  width: 455px;
  animation: ${fadeId} 0.3s ease-in;

  ${({ disabled }) =>
    disabled &&
    css`
      display: none;
      pointer-events: none;
      cursor: default;
    `}
`

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 20px;
`
const QuestionTitle = styled.div`
  font-size: 14px;
  line-height: 30px;
`
const QuestionDescription = styled.div`
  font-size: 10px;
  margin-left: 20px;
  line-height: 20px;
  margin-bottom: 10px;

  strong {
    font-weight: bold;
  }
`
