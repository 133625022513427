import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { BouncingLoader, Tabs } from 'examkrackers-components'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import { propOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import eventEmitter from 'providers/eventEmitter'
import events from 'utils/events'
import { useSelector } from 'react-redux'
import {
  getExamDetails,
  getSectionDetailsById
} from '../../exam/ducks/selectors'
import AnalyticsService from '../../../services/AnalyticsService'
import { ANALYTICS_EVENTS } from '../../../utils/analytics'
import * as R from 'ramda'
import { getAccountType, getStudent } from '../../auth/ducks/selectors'
import { ExamDetails, RootReducer } from '../../../types'
// import { getExamDiagramData } from 'services/ExamsService'

interface ExamScoreTabsProps {
  // activeTab: 'answer-sheet' | 'diagnostics'
  children: JSX.Element
  tiles?: JSX.Element[]
  saltyImageUrl?: string
}

const ExamScoreTabs = ({
  // activeTab = 'answer-sheet',
  children,
  tiles,
  saltyImageUrl
}: ExamScoreTabsProps) => {
  const {
    push,
    location: { pathname }
  } = useHistory()

  const [LoadingState, setLoadingState] = useState('loading')
  const params = useParams()
  const examId = propOr('', 'id', params)
  const sectionId = propOr('', 'sectionId', params)
  const examDetails = useSelector(getExamDetails) as ExamDetails
  const student = useSelector(getStudent)
  const accountType: string = useSelector(getAccountType)
  const sectionDetails = useSelector((state: RootReducer) =>
    getSectionDetailsById(state, sectionId)
  )
  const examSections = examDetails?.exam?.scores?.sections || []
  // [{ id: examDetails?.exam.id, title: examDetails?.exam.title }]

  const nonFLExamLabels = [
    {
      label: 'Answer Sheet',
      value: `${examSections[0]?.id}/answer-sheet`
    },
    {
      label: 'Diagnostics',
      value: `${examSections[0]?.id}/diagnostic`
    }
  ]

  const examSectionsLabels =
    examSections.length !== 1
      ? examSections.flatMap(section => [
          {
            label: `${section.title} Answer Sheet`,
            value: `${section.id}/answer-sheet`,
            sectionId: section.id
          },
          {
            label: `${section.title} Diagnostics`,
            value: `${section.id}/diagnostic`,
            sectionId: section.id
          }
        ])
      : nonFLExamLabels
  const tabs = [
    { label: 'Score Sheet', value: 'score-sheet' },
    ...examSectionsLabels
  ]

  const activeTab = pathname.includes('score-sheet')
    ? 'score-sheet'
    : pathname.includes('diagnostic')
    ? `${sectionId}/diagnostic`
    : `${sectionId}/answer-sheet`

  const handleTabChange = tab => {
    AnalyticsService(student).logEvent(
      tab === 'diagnostics'
        ? ANALYTICS_EVENTS.diagnosticsReviewed
        : ANALYTICS_EVENTS.answerSheetReviewed,
      {
        'Exam Title': R.pathOr('', ['exam', 'title'], examDetails),
        'Exam format': R.pathOr('', ['type', 'title'], examDetails),
        'Account type': accountType,
        'Precentile Rank': R.pathOr(
          0,
          ['exam', 'scores', 'exam', 'percentile_rank'],
          examDetails
        ),
        'Raw Score': R.pathOr(
          0,
          ['exam', 'scores', 'exam', 'amount_correct'],
          examDetails
        ),
        'Scaled Score': R.pathOr(
          0,
          ['exam', 'scores', 'exam', 'scaled_score'],
          examDetails
        ),
        'Section Title': R.propOr('-', 'title', sectionDetails),
        'Section Number': R.propOr(0, 'order', sectionDetails)
      }
    )

    const tabValueContents = tab.split('/')
    const sectionIdFromTabValue = tabValueContents[0]
    const tabValue = tabValueContents[1] || tab

    if (tab === activeTab) return
    switch (tabValue) {
      case 'answer-sheet':
        push(
          `/exam/${examId}/score-report/answers-graph/${sectionIdFromTabValue}/answer-sheet`
        )
        break
      case 'diagnostic':
        push(
          `/exam/${examId}/score-report/answers-graph/${sectionIdFromTabValue}/diagnostic`
        )
        break
      case 'score-sheet':
        push(`/exam/${examId}/score-report/score-sheet`)
        break
      default:
        break
    }
  }

  const tabContents = useMemo(() => {
    return tabs.map(tab => {
      return tab.value === activeTab
        ? {
            value: tab.value,
            content: children
          }
        : {
            value: tab.value,
            content: null
          }
    })
  }, [activeTab, children])

  const diagnosticTiles = useMemo(() => {
    if (isNotNilOrEmpty(tiles)) {
      // @ts-ignore
      return tiles.map(tile => tile)
    } else {
      return null
    }
  }, [tiles])

  const [saltyHistoryOpen, setSaltyHistoryOpen] = useState(false)
  const saltyBucksHistoryStatusHandler = () => {
    setSaltyHistoryOpen(saltyHistoryOpen => !saltyHistoryOpen)
  }
  useEffect(() => {
    eventEmitter.on(
      events.saltyBucksHistoryOpened,
      saltyBucksHistoryStatusHandler
    )
    if (isNotNilOrEmpty(examDetails)) {
      setLoadingState('fulfilled')
    }
  }, [])

  if (LoadingState === 'loading' || LoadingState === 'initial') {
    return (
      <LoadingContainer>
        <BouncingLoader />
      </LoadingContainer>
    )
  }

  return (
    <PageWrapper hasTiles={isNotNilOrEmpty(tiles)}>
      {saltyImageUrl && (
        <SaltyImage saltyHistoryOpen={saltyHistoryOpen} tabLength={tabs.length}>
          <img src={saltyImageUrl} alt='salty-image' />
        </SaltyImage>
      )}
      {diagnosticTiles && <Tiles>{diagnosticTiles}</Tiles>}
      <Tabs
        data-testid='ScoreSheet-tabs'
        activeTab={activeTab}
        position='topLeft'
        tabs={tabs}
        tabContents={tabContents}
        onChange={handleTabChange}
      />
    </PageWrapper>
  )
}

export default ExamScoreTabs

const PageWrapper = styled.div`
  margin-top: 40px;
  position: relative;
`

const Tiles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 19px 0 25px;
  gap: 15px;
`

const SaltyImage = styled.div`
  z-index: ${({ theme, saltyHistoryOpen }) =>
    saltyHistoryOpen
      ? theme.zIndex.navigation - 3
      : theme.zIndex.navigation + 1};
  position: absolute;
  display: block;
  ${({ tabLength }) =>
    tabLength > 3
      ? css`
          top: -28px;
          right: 186px;
        `
      : css`
          top: -4px;
          left: 420px;
        `}

  img {
    display: block;
    position: absolute;
    max-width: 65px;
    animation: salty-pop-in 800ms ease-in;
  }

  @keyframes salty-pop-in {
    0% {
      transform: scale(0.3);
    }

    100% {
      transform: scale(1);
    }
  }
`
const LoadingContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`
