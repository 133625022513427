import React, { useContext, useState } from 'react'
import { Button, Modal, Tooltip } from 'examkrackers-components'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import PATHS from 'utils/paths'
import { useTranslation, Trans } from 'react-i18next'

import ReorganizeSalty from 'assets/images/Calendar_SetupQuestions_Saltys.svg'
import ResetIcon from 'assets/images/CalendarReorderResetButtons_Reset.svg'
import ReorderIcon from 'assets/images/CalendarReorderResetButtons_Reorder.svg'
import { CalendarContext } from 'hooks/CalendarContext'

import {
  countStudyDaysInRangeForExpiration,
  NUMBER_OF_DAYS_NEEDED_BEFORE_FIRST_CLASS
  // firstClassDateCalendar
} from 'utils/calendar'

const ReorganizeCalendarModal = () => {
  const { course } = useContext(CalendarContext)

  const isLiveCourse = course?.type === 'live_course'

  const calendarStartAt = course.calendar_start_at as string
  const firstClassDate = course.endDateFirstDay?.class_date

  const numberOfDaysBeforeFirstClass =
    isLiveCourse &&
    countStudyDaysInRangeForExpiration(calendarStartAt, firstClassDate)
  const canCalendarBeReordered =
    isLiveCourse &&
    Number(numberOfDaysBeforeFirstClass) >
      NUMBER_OF_DAYS_NEEDED_BEFORE_FIRST_CLASS

  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const { push } = useHistory()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const goToReorder = () => {
    if (isLiveCourse) {
      push(PATHS.calendarReorderForExpiration)
    } else {
      push({ pathname: PATHS.calendarReorder, state: { isLiveCourse } })
    }
  }

  const goToReset = () => {
    if (isLiveCourse) {
      push(PATHS.calendarResetForExpiration)
    } else {
      push({ pathname: PATHS.calendarReset, state: { isLiveCourse } })
    }
  }

  const isFreeTrial = course.type === 'free_trial'

  return (
    <>
      {isFreeTrial ? (
        <Tooltip
          data-testid='ReorganizeCalendarModal-Tooltip-reorganize-isFreeTrial'
          tooltipContent='Once you purchase KrackU, this button will allow you to reorganize your calendar.'
          id={`reorganize-${course.id}`}
        >
          <Button
            data-testid='ReorganizeCalendarModal-Button-reorganize-isFreeTrial'
            variant='contained'
            color='secondary'
            onClick={handleOpen}
            disabled
          >
            {t('calendar.button.reorganize')}
          </Button>
        </Tooltip>
      ) : (
        <Button
          data-testid='ReorganizeCalendarModal-Button-reorganize'
          variant='contained'
          color='secondary'
          onClick={handleOpen}
          // disabled={!canCalendarBeReordered && isLiveCourse}
        >
          {t('calendar.button.reorganize')}
        </Button>
      )}

      <Modal
        data-testid='ReorganizeCalendarModal-Button-Modal'
        open={open}
        handleClose={handleClose}
        title={t('calendar.reorganizeModal.titleFirstPart')}
        hasBorder
      >
        <>
          <MainWrapper data-testid='ReorganizeCalendarModal-MainWrapper'>
            <Container data-testid='ReorganizeCalendarModal-Container'>
              <TextWrapper data-testid='ReorganizeCalendarModal-TextWrapper'>
                <SectionWrapper data-testid='ReorganizeCalendarModal-SectionWrapper'>
                  <SectionTitle data-testid='ReorganizeCalendarModal-SectionTitle'>
                    <Trans
                      data-testid='ReorganizeCalendarModal-Button-Trans-reorderDesc'
                      i18nKey='calendar.reorganizeModal.reorderDesc'
                      components={{
                        Reorder: <WordReorderWrapper />,
                        Incomplete: <WordIncompleteWrapper />
                      }}
                    />
                  </SectionTitle>
                </SectionWrapper>

                <SectionWrapper data-testid='ReorganizeCalendarModal-SectionWrapper-reset'>
                  <SectionTitle data-testid='ReorganizeCalendarModal-SectionTitle-reset'>
                    <Trans
                      data-testid='ReorganizeCalendarModal-Trans-resetDesc'
                      i18nKey='calendar.reorganizeModal.resetDesc'
                      components={{
                        Reset: <WordResetWrapper />,
                        All: <WordIncompleteWrapper />
                      }}
                    />
                  </SectionTitle>
                </SectionWrapper>
              </TextWrapper>
              <ReorganizeSaltyImage
                data-testid='ReorganizeCalendarModal-ReorganizeSaltyImage'
                id='reorganize-salty'
                src={ReorganizeSalty}
                alt='Salty reorganize'
              />
            </Container>
            <ButtonsWrapper data-testid='ReorganizeCalendarModal-ButtonsWrapper'>
              <ButtonWrapper
                color='tertiary'
                onClick={handleClose}
                data-testid='ReorganizeCalendarModal-ButtonWrapper-cancel'
              >
                {t('calendar.button.cancel')}
              </ButtonWrapper>
              <ButtonWrapper
                color='primary'
                onClick={goToReorder}
                data-testid='ReorganizeCalendarModal-ButtonWrapper-reschedule'
              >
                <IconWrapper data-testid='ReorganizeCalendarModal-IconWrapper-reschedule'>
                  <ReorderIconWrapper
                    data-testid='ReorganizeCalendarModal-ReorderIconWrapper'
                    id='reorder'
                    src={ReorderIcon}
                    alt='Reorder'
                  />

                  {t('calendar.button.reschedule')}
                </IconWrapper>
              </ButtonWrapper>

              <ButtonWrapper
                color='secondary'
                onClick={goToReset}
                data-testid='ReorganizeCalendarModal-Button-reset'
              >
                <IconWrapper data-testid='ReorganizeCalendarModal-IconWrapper-reset'>
                  <ResetIconWrapper
                    id='reset'
                    src={ResetIcon}
                    alt='Reset'
                    data-testid='ReorganizeCalendarModal-ResetIconWrapper'
                  />
                  {t('calendar.button.reset')}
                </IconWrapper>
              </ButtonWrapper>
            </ButtonsWrapper>
          </MainWrapper>
        </>
      </Modal>
    </>
  )
}

export default ReorganizeCalendarModal

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const SectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  /* width: 400px; */
  margin-bottom: 24px;
`

const SectionTitle = styled.div`
  font-size: 16px;
  width: 339px;
  text-align: left;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 65px;
  gap: 16px;
`
const ButtonWrapper = styled(Button)`
  width: 158px;
`
const ReorganizeSaltyImage = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 121px;
  width: 165px;
`

const ResetIconWrapper = styled.img`
  margin-right: 10px;
`
const ReorderIconWrapper = styled.img`
  margin-right: 10px;
`
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const MainWrapper = styled.div`
  width: 732px;
`
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 24px;
`
const WordReorderWrapper = styled.div`
  font-weight: bold;
  display: inline;
  color: ${({ theme }) => theme.colors.main.primary500};
`
const WordResetWrapper = styled.div`
  font-weight: bold;
  display: inline;
  color: ${({ theme }) => theme.colors.main.primary800};
`
const WordIncompleteWrapper = styled.div`
  text-decoration: underline;
  text-underline-offset: 3px;

  display: inline;
`
