import APIService from 'services/APIService'
import {
  FetchExamsListPayload,
  FetchExamLogsListPayload,
  FetchExamDetailsPayload,
  FetchQuestionDetailsPayload,
  SaveExamStatePayload,
  ToggleQuestionFlagPayload,
  AnswerQuestionPayload,
  ChangeQuestionStatusPayload,
  ChangeExamAccessPeriodPayload,
  SaveResourceHtmlPayload,
  SaveSectionStatusPayload,
  SaveExamTimersPayload,
  StartExamPayload,
  FinishExamPayload,
  TogglePTSExclusionPayload,
  EndSectionPayload,
  FetchExamPassagesDetailsPayload,
  FetchPassageReadingTimePayload,
  FetchQuestionsGraphDataPayload,
  FetchScoreProjectionPayload,
  FetchTargetQuestionsGraphDataPayload,
  FetchAverageQuestionsGraphDataPayload,
  FetchTargetPassagesGraphDataPayload,
  FetchAveragePassagesGraphDataPayload,
  FetchPassageTimersPayload,
  FetchSingleQuestionMetrics,
  SaveCanvasToQuestion
} from 'types'
import { dissoc } from 'ramda'

const api = APIService()

//
// Student exams
//

// Get all exams with a query string.
// TODO(): Could this possibly be student-exams?
export const fetchExamsList = (payload: FetchExamsListPayload) =>
  api.get(`/students/exams${payload.query}`)

// Get exam details by exam id
export const fetchExamsDetails = (payload: FetchExamDetailsPayload) =>
  api.get(`/student-exams/${payload.id}`)

// Get logs from a student exam by id.
export const fetchExamLogsList = (payload: FetchExamLogsListPayload) =>
  api.get(`/student-exams/${payload.id}/logs${payload.query}`)

// Save timers by exam id
export const saveExamTimers = (payload: SaveExamTimersPayload) =>
  api.patch(`/student-exams/${payload.id}/timers`, {
    timers: payload.timers,
    timerCheckboxes: payload.timerCheckboxes
  })

// Save state of exam by id
export const saveExamState = (payload: SaveExamStatePayload) =>
  api.patch(`/student-exams/${payload.id}/save`, payload.data)

// Start exam by id
export const startExam = (payload: StartExamPayload) =>
  api.patch(`/student-exams/${payload.id}/start`, {
    time_option: payload.timeOption
  })

// Finish exam by id
export const finishExam = (payload: FinishExamPayload) =>
  api.patch(`/student-exams/${payload.id}/finish`)

// Change exam access period
export const changeExamAccessPeriod = (
  payload: ChangeExamAccessPeriodPayload
) =>
  api.patch(`/student-exams/${payload.id}/change-access-period`, payload.data)

// Save HTML
export const saveResourceHtml = (payload: SaveResourceHtmlPayload) =>
  api.patch(`/student-exams/${payload.id}/change-html`, dissoc('id', payload))

export const togglePTSExclusion = (payload: TogglePTSExclusionPayload) =>
  api.patch(`/student-exams/${payload.id}/toggle-pts-exclusion`)

export const endSection = (payload: EndSectionPayload) =>
  api.patch(`/student-exams/${payload.id}/finish-section/${payload.section_id}`)
export const getPassagesDetails = (payload: FetchExamPassagesDetailsPayload) =>
  api.get(`/student-exams/${payload.id}/passages`)

//
// Student Exam Questions
//

// Get exam question by id
export const fetchQuestionDetails = (payload: FetchQuestionDetailsPayload) =>
  api.get(`/student-exam-questions/${payload.id}`)

// Toggle question flag of exam question by id
export const toggleQuestionFlag = (payload: ToggleQuestionFlagPayload) =>
  api.patch(`/student-exam-questions/${payload.id}/toggle-flagged`)

// Answer exam question
export const answerQuestion = (payload: AnswerQuestionPayload) =>
  api.patch(`/student-exam-questions/${payload.id}/answer`, {
    answer: payload.answer
  })

// Change the status of an exam question
export const changeQuestionStatus = (payload: ChangeQuestionStatusPayload) =>
  api.patch(`/student-exam-questions/${payload.id}/status`, {
    question_status: payload.status
  })

//
// Student Exam Sections
//

// Save the status of a student exam section
export const saveSectionStatus = (payload: SaveSectionStatusPayload) =>
  api.patch(`/student-exam-sections/${payload.id}/status`, {
    status: payload.status
  })

export const getPassageReadingTimeGraph = (
  payload: FetchPassageReadingTimePayload
) => api.get(`/student-exam-sections/${payload.id}/graph/passage-reading-time`)

export const getPassageWorkingTimeGraph = (
  payload: FetchPassageReadingTimePayload
) => api.get(`/student-exam-sections/${payload.id}/graph/passage-working-time`)

export const getQuestionsGraphData = (
  payload: FetchQuestionsGraphDataPayload
) => api.get(`/student-exam-sections/${payload.id}/graph/questions`)

//
// Score projection
//

// Get student score projection data
export const fetchScoreProjectionData = (
  payload: FetchScoreProjectionPayload
) => api.get(`/student-exams/score-projection-data/${payload.type_id}`)

//
// Exam Metrics
//

// Get metrics for an exam question.
export const getSingleQuestionMetrics = (payload: FetchSingleQuestionMetrics) =>
  api.get(`/exam-metrics/question-id/${payload.id}`)

// Save canvas to exam question.
export const saveCanvasToQuestion = (payload: SaveCanvasToQuestion) => {
  const formData = new window.FormData()
  const file = new File([payload.backgroundImage], 'backgroundImage.png', {
    type: 'image/png'
  })

  formData.append('image', file)
  formData.append('canvas', JSON.stringify(payload.canvas))

  return api.patch(`/student-exam-questions/${payload.id}/canvas`, formData)
}

// Get target questions graph data
export const getTargetQuestionsGraphData = (
  payload: FetchTargetQuestionsGraphDataPayload
) =>
  api.get(
    `/exam-metrics/exam/${payload.exam_id}/section-order/${payload.section_order}/questions/score/${payload.section_score}`
  )

// Get average questions graph data
export const getAverageQuestionsGraphData = (
  payload: FetchAverageQuestionsGraphDataPayload
) =>
  api.get(
    `/exam-metrics/exam/${payload.exam_id}/section-order/${payload.section_order}/questions`
  )

// Get passages graph data from a score
export const getTargetPassagesGraphData = (
  payload: FetchTargetPassagesGraphDataPayload
) =>
  api.get(
    `/exam-metrics/exam/${payload.exam_id}/section-order/${payload.section_order}/passages/score/${payload.section_score}`
  )

// Get average passages graph data
export const getAveragePassagesGraphData = (
  payload: FetchAveragePassagesGraphDataPayload
) =>
  api.get(
    `/exam-metrics/exam/${payload.exam_id}/section-order/${payload.section_order}/passages`
  )

// Get timers for a passage
export const getPassageTimers = (payload: FetchPassageTimersPayload) =>
  api.get(`/student-exam-sections/${payload.id}/graph/passages`)

export const getExamDiagramData = (payload: any) =>
  api.get(`/exams/${payload.id}/diagram`)

export const getExamDiagramDataForAllSections = (payload: any) =>
  api.get(`/exam-sections/exam/${payload.id}/diagram-all`)

export const getRetakeResults = (payload: any) =>
  api.get(`student-exams/${payload.id}/retakes`)
