import React, { useState } from 'react'
import { Modal, Button } from 'examkrackers-components'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import WarningCalendar from 'assets/images/WarningCalendar.svg'

interface ModalRetakeExamProps {
  isOpen: boolean
  handleStartExam: () => void
  handleClose: () => void
  numberOfCompletions: number
  maxCompletionNumber: number
  isResume?: boolean
}

const ModalRetakeExam = ({
  isOpen,
  handleStartExam,
  handleClose,
  numberOfCompletions,
  maxCompletionNumber,
  isResume
}: ModalRetakeExamProps) => {
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false)
  const { t } = useTranslation()
  const startValue = numberOfCompletions + 1
  const endValue = maxCompletionNumber
  const isNumberOfCompletionsZero = numberOfCompletions === 0

  const handleCancel = () => {
    setIsSecondModalOpen(false)
    handleClose()
  }
  const handleExamSubmit = () => {
    handleStartExam()
    setIsSecondModalOpen(false)
    handleClose()
  }
  return (
    <>
      <Modal open={isOpen} handleClose={handleCancel} hasBorder>
        {!isSecondModalOpen && !isNumberOfCompletionsZero ? (
          <ModalContainer>
            <TitleContainer>
              <TitleWarningIconContainer>
                <WarningIconWrapper>
                  <WarningIcon
                    id='warning'
                    src={WarningCalendar}
                    alt='Warning'
                  />
                </WarningIconWrapper>
              </TitleWarningIconContainer>
              <TitleWrapper>{t('exams.modal.retake.title')}</TitleWrapper>
            </TitleContainer>
            <DescriptionWarningWrapper>
              <DescWrapper>
                {t('exams.modal.retake.descriptionPartOne')}
              </DescWrapper>
              <DescWrapper>
                {t('exams.modal.retake.descriptionPartTwo')}
              </DescWrapper>
              <DescWrapperLast>
                <Trans
                  i18nKey='exams.modal.retake.descriptionPartThree'
                  values={{ startValue, endValue }}
                  components={{ strong: <NumberOfAttempts /> }}
                />
              </DescWrapperLast>
            </DescriptionWarningWrapper>
            <ButtonContainer>
              <Button
                size='small'
                type='submit'
                color='tertiary'
                id='start-exam-cancel'
                onClick={handleClose}
              >
                {t('exams.modal.retake.cancel')}
              </Button>
              <Button
                size='small'
                color='secondary'
                type='submit'
                id='start-exam-submit'
                onClick={() => setIsSecondModalOpen(true)}
              >
                {isResume
                  ? t('exams.modal.resume.resumeCTA')
                  : t('exams.modal.retake.continue')}
              </Button>
            </ButtonContainer>
          </ModalContainer>
        ) : (
          <ModalContainer>
            <TitleContainer>
              <TitleInfoIconWrapper>
                <InfoIconWrapper>i</InfoIconWrapper>
              </TitleInfoIconWrapper>
              <TitleWrapper>
                {t('exams.modal.retakeImportantInfo.title')}
              </TitleWrapper>
            </TitleContainer>
            <DescriptionInfoWrapper>
              <DescWrapper>
                {t('exams.modal.retakeImportantInfo.descriptionPartOne')}
              </DescWrapper>
              <DescWrapperLast>
                {t('exams.modal.retakeImportantInfo.descriptionPartTwo')}
              </DescWrapperLast>
            </DescriptionInfoWrapper>
            <ButtonContainer>
              <Button
                size='small'
                color='secondary'
                type='submit'
                id='start-exam-submit'
                onClick={handleExamSubmit}
              >
                {isResume
                  ? t('exams.modal.resume.resumeCTA')
                  : t('exams.modal.retake.continue')}
              </Button>
            </ButtonContainer>
          </ModalContainer>
        )}
      </Modal>
    </>
  )
}

export default ModalRetakeExam

const ModalContainer = styled.div`
  width: 669px;
`
const ButtonContainer = styled.div`
  margin: 30px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    min-width: 130px;
  }

  button + button {
    margin-left: 10px;
  }
`

const WarningIcon = styled.img`
  width: 28px;
`
const WarningIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`
const TitleWarningIconContainer = styled.div`
  width: 48px;
  height: 48px;
  background-color: ${({ theme }) => theme.colors.main.primary500};
  border-radius: 50%;
`
const TitleInfoIconWrapper = styled.div`
  width: 48px;
  height: 48px;
  background-color: ${({ theme }) => theme.colors.main.grey400};
  border-radius: 50%;
`
const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
`
const TitleWrapper = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-left: 20px;
`
const DescriptionWarningWrapper = styled.div`
  font-size: 16px;
  margin: 20px 0;
`
const DescriptionInfoWrapper = styled.div`
  font-size: 16px;
  margin: 20px 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
`
const DescWrapper = styled.div`
  margin-bottom: 5px;
`

const DescWrapperLast = styled.div`
  margin-top: 30px;
`
const NumberOfAttempts = styled.span`
  font-weight: bold;
`
const InfoIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.colors.main.white};
  font-size: 28px;
  font-weight: bold;
`
