import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'examkrackers-components'
import ModalConfirmStart from 'modules/exams/components/ModalConfirmStart'
import ModalConfirmResume from 'modules/exams/components/ModalConfirmResume'
import { isDateBeforeToday } from 'utils/date'
import { EXAM_STATUS } from 'utils/exam'
import { Course, Exam } from 'types'
import { useSelector } from 'react-redux'
import { getCurrentCourse } from 'modules/courses/ducks/selectors'
import * as R from 'ramda'
import { COURSE_TYPES } from 'utils/courses'
import { propOr } from 'ramda'

interface ExamButtonProps {
  id: string
  date: string
  status: string
  isImpersonated: boolean
  exam: Exam
  isCalendarPage?: boolean
  disabled?: boolean
}

const ExamButton = (props: ExamButtonProps): JSX.Element => {
  const { id, date, status, isImpersonated, exam, isCalendarPage, disabled } =
    props

  const currentCourse: Course = useSelector(getCurrentCourse)

  const isFreeTrialCourse = R.pipe(
    R.propOr('', 'type'),
    R.equals(COURSE_TYPES.freeTrial)
  )(currentCourse)

  const isExamAvailableInFreeTrial: boolean =
    R.propOr(true, 'is_available', exam) ||
    R.propOr(true, 'is_free_trial_chapter', exam)

  const isExamAvailable: boolean = isFreeTrialCourse
    ? isExamAvailableInFreeTrial
    : true

  const { t } = useTranslation()

  // Check if the current exam is expired,
  // if so, terminate early.
  if (isDateBeforeToday(date)) {
    return (
      <Button
        id={`${id}-expired-exam`}
        size='small'
        variant='contained'
        color='primary'
        disabled
      >
        {t('exams.actions.expired')}
      </Button>
    )
  }

  const isDisabled = isImpersonated || !isExamAvailable || disabled

  const maxCompletionNumber: number = R.propOr(0, 'max_completions', exam)
  const numberOfCompletions: number = R.propOr(0, 'completions_done', exam)
  const isDisabledForMaxCompletions = maxCompletionNumber <= numberOfCompletions

  // Render button differently based on exam status
  switch (status) {
    case EXAM_STATUS.scheduled:
      return (
        <ModalConfirmStart
          exam={exam}
          isDisabledForFreeTrial={!isExamAvailable}
          disabled={isDisabled}
          id={id}
          isCalendarPage={isCalendarPage}
          maxCompletionNumber={maxCompletionNumber}
        />
      )
    case EXAM_STATUS.paused:
    case EXAM_STATUS.in_progress:
      return (
        <ModalConfirmResume
          exam={exam}
          disabled={isDisabled}
          id={id}
          maxCompletionNumber={propOr(1, 'max_completions', exam)}
          numberOfCompletions={propOr(1, 'completions_done', exam)}
        />
      )
    case EXAM_STATUS.completed:
      return (
        <ModalConfirmStart
          exam={exam}
          isDisabledForFreeTrial={!isExamAvailable}
          disabled={isDisabledForMaxCompletions}
          id={id}
          isCalendarPage={isCalendarPage}
          isRetake={!isDisabledForMaxCompletions}
          maxCompletionNumber={maxCompletionNumber}
          numberOfCompletions={numberOfCompletions}
        />
      )
    default:
      return (
        <ModalConfirmStart
          exam={exam}
          isDisabledForFreeTrial={!isExamAvailable}
          disabled={isDisabled}
          id={id}
          isCalendarPage={isCalendarPage}
          isRetake={!isDisabledForMaxCompletions}
          maxCompletionNumber={maxCompletionNumber}
          numberOfCompletions={numberOfCompletions}
        />
      )
  }
}

export default ExamButton
