import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ExamContent, ExamAnswers } from 'examkrackers-components'
import { useHistory, useParams } from 'react-router-dom'

import { finishExam } from 'services/ExamsService'
import { propOr, pipe, pathOr } from 'ramda'
import PAGE_IDS from 'modules/exam/utils/pageIds'

import {
  getAccountType,
  getIsPreviewAdmin,
  getStudent
} from 'modules/auth/ducks/selectors'
import { useSelector } from 'react-redux'
import { getExamDetails } from 'modules/exam/ducks/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'

import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'
import { DATE_FORMATS, formatDate } from 'utils/date'

export const FinishExam = (props): JSX.Element => {
  const params = useParams()
  const history = useHistory()
  const user = useSelector(getStudent)

  const examDetails = useSelector(getExamDetails)
  const id: string = propOr('', 'id')(params)
  const { setOnTimeEnd, setNavigationHandlers, setCurrentPage } = props
  const [selectedAnswer, setSelectedAnswer] = useState('')
  const isPreviewAdmin = useSelector(getIsPreviewAdmin)
  const accountType: string = useSelector(getAccountType)
  const type_title = pathOr('', ['type', 'title'], examDetails)

  const redirectToScoreSheet = () =>
    history.push(`/exam/${id}/score-report/score-sheet`)

  const hasGoogleFormUrl = pipe(
    pathOr(null, ['exam', 'originalExam', 'google_form_url']),
    isNotNilOrEmpty
  )(examDetails)

  const redirectToGoogleForm = () => {
    if (isPreviewAdmin) {
      window.location.href = `${process.env.REACT_APP_ADMIN_URL}/exams`
    } else {
      setCurrentPage(PAGE_IDS.googleForm)
    }
  }

  const handleFinishExam = () => {
    const onSuccess = response => {
      const scaledScore = pathOr('-', [
        'data',
        'scores',
        'exam',
        'scaled_score'
      ])(response)
      const rawScore = pathOr('-', [
        'data',
        'scores',
        'exam',
        'amount_correct'
      ])(response)
      const percentileRank = pathOr('-', [
        'data',
        'scores',
        'exam',
        'percentile_rank'
      ])(response)
      const title = pathOr('-', ['data', 'title'])(response)

      AnalyticsService(user).logEvent(ANALYTICS_EVENTS.examCompleted, {
        Time: formatDate(new Date(), DATE_FORMATS.dashedWithTime),
        'Exam Title': title,
        'Exam format': type_title,
        'Account type': accountType,
        'Scaled score': scaledScore,
        'Raw score': rawScore,
        'Percentile rank': percentileRank
      })
    }
    const onFailure = e => console.error(e)

    if (!isPreviewAdmin) {
      finishExam({ id }).then(onSuccess).catch(onFailure)
    }
  }

  useEffect(() => {
    handleFinishExam()
    setOnTimeEnd(redirectToGoogleForm)
    setNavigationHandlers(prevState => ({
      ...prevState,
      nextButtonOnClick: hasGoogleFormUrl
        ? redirectToGoogleForm
        : redirectToScoreSheet
    }))
  }, [])

  const answers = [
    {
      answerCode: 'A',
      answerContent: 'I wish to have my MCAT exam SCORED.'
    },
    {
      answerCode: 'B',
      answerContent: 'I wish to VOID my MCAT exam.'
    }
  ]

  // @ts-ignore
  // @ts-ignore
  return (
    <ExamContent>
      <FinishExamContainer>
        <p>Congratulations! You have completed the exam.</p>
        <div className='heavy-bordered'>
          <p>
            If you would like to have your MCAT® exam SCORED, select{' '}
            <strong>I wish to have my MCAT exam SCORED.</strong> If you select
            this option, your results will be reported and you will not be able
            to cancel your scores on a later date.
          </p>
          <p>
            If you wish to VOID your MCAT® exam and NOT have it scored, select{' '}
            <strong>I wish to VOID my MCAT exam.</strong> Your results will not
            be scored, and you will not receive a refund of your scheduling fee.{' '}
          </p>
          <p>
            If time expires before you submit your selection, your answer will
            default to your current selection. If no selection is made, your{' '}
            answer will default to SCORED.
          </p>
        </div>
        <p>
          <strong>NOTICE:</strong> Reminder, your practice exam scores are saved
          and visible in your “score report” dashboard regardless of your
          selection below. On the actual exam, if you select “void” your exam
          will <strong>not</strong> be scored.
        </p>
        <br />
        <ExamAnswers
          answers={answers}
          onSelectAnswer={setSelectedAnswer}
          selectedAnswerCode={selectedAnswer}
        />
        <br />
        <br />
        <p>
          Select&nbsp;
          <strong>
            <span className='underline'>N</span>ext
          </strong>{' '}
          to submit your selection.
        </p>
      </FinishExamContainer>
    </ExamContent>
  )
}

export default FinishExam

const FinishExamContainer = styled.div`
  p {
    margin-bottom: 16px;
    line-height: 22.4px;
  }
`
