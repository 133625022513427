import React from 'react'
import styled from 'styled-components'
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer
} from 'recharts'
import { ExamDetails } from 'types'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { removeDuplicatesAndSaveMiddleStudentsValue } from '../utils/ExamScoreSheetChart'

type GraphProps = {
  examDetails: ExamDetails
  data: any
  score: number
  barColor: string
  isMainDiagram?: boolean
  title?: string
  sectionId?: string
}

const CustomBarShape = (props: any) => {
  const { x, y, width, height } = props
  return (
    <rect
      x={x - 5} // Adjust x position for centering
      y={y}
      width={width + 5} // Increase width of the bar. If modified, adjust x position above.
      height={height}
      fill={props.fill}
      stroke='#fff'
      strokeWidth={1}
    />
  )
}

const PercentileGraph = ({
  examDetails,
  data,
  score,
  barColor,
  isMainDiagram,
  title,
  sectionId
}: GraphProps) => {
  const { push } = useHistory()
  const { t } = useTranslation()

  const graphHeight = isMainDiagram ? 280 : 140
  const firstXAxisValue = data.percentile[0].score || 0
  const lastXAxisValue = data.percentile[data.percentile.length - 1].score || 0

  const mapLineGraphData = (array, start, end) => {
    const mappedArray = [...array]
    const interval = (end - start) / (mappedArray.length - 1)

    mappedArray.forEach((item, index) => {
      item.x = start + index * interval
    })

    return mappedArray
  }

  const firstLineGraphValue = data?.stats[0]?.x || 0
  const lastLineGraphValue = data?.stats[data.stats.length - 1]?.x || 59

  const addScoreKeyToLineGraphData = (array, scoreMin, scoreMax) => {
    const mappedArray = [...array]
    const interval = (scoreMax - scoreMin) / (mappedArray.length - 1)

    mappedArray.forEach((item, index) => {
      item.score = scoreMin + index * interval
    })

    return mappedArray
  }

  const lineGraphData = addScoreKeyToLineGraphData(
    data.stats,
    firstXAxisValue,
    lastXAxisValue
  )

  function findClosestObject(arr, target, key) {
    if (arr.length === 0) {
      return { score: 0, students: 0 }
    }

    return arr.reduce((closest, current) => {
      const closestDiff = Math.abs(closest[key] - target)
      const currentDiff = Math.abs(current[key] - target)

      if (current[key] === target) {
        return current
      }

      return currentDiff < closestDiff ? current : closest
    })
  }

  const lineGraphValueOfStudentsScore = findClosestObject(
    lineGraphData,
    score,
    isMainDiagram ? 'x' : 'score'
  )
  const dataForBarChart = data.percentile.map((item, index) => {
    return {
      x: item.score,
      score: item.score,
      // Limit bar height to the corresponding line graph value
      students:
        item.score === score ? lineGraphValueOfStudentsScore.students || 0 : 0
    }
  })

  // const doesBarChartHaveDuplicates = dataForBarChart.some(
  //   (item, index) =>
  //     dataForBarChart.findIndex(i => i.score === item.score) !== index
  // )

  const removeDuplicates =
    removeDuplicatesAndSaveMiddleStudentsValue(dataForBarChart)

  const dataForBarChartUniqueValues = removeDuplicates

  const mappedDataForBarChart = mapLineGraphData(
    dataForBarChartUniqueValues,
    firstLineGraphValue,
    lastLineGraphValue
  )

  const redirectToSectionDiagnostics = (examId, sectionId) =>
    push(`/exam/${examId}/score-report/answers-graph/${sectionId}/diagnostic`)

  const tickArray = Array.from({ length: 15 }, (_, i) => i + 1)

  return (
    <GraphWrapper isMainDiagram={isMainDiagram}>
      <MainDiagramTitle isMainDiagram={isMainDiagram}>
        {isMainDiagram ? t('diagnostics.scoreSheet.scaledScoreCurve') : title}
        <div>
          {isMainDiagram ? (
            <p>
              {examDetails.exam.originalExam.custom_title
                ? examDetails.exam.originalExam.custom_title
                : examDetails.exam.title}
            </p>
          ) : (
            ''
          )}
          <ScoreTile isMainDiagram={isMainDiagram}>{score}</ScoreTile>
        </div>
      </MainDiagramTitle>
      <ResponsiveContainer width='100%' height={graphHeight}>
        <ComposedChart
          data={mappedDataForBarChart}
          margin={
            isMainDiagram
              ? { top: 20, bottom: 0, right: 40, left: 0 }
              : { left: -46, bottom: -10, top: 35, right: 10 }
          }
        >
          <XAxis
            dataKey='x'
            type='number'
            domain={
              isMainDiagram
                ? [firstXAxisValue, lastXAxisValue]
                : [firstLineGraphValue, lastLineGraphValue]
            }
            tick={isMainDiagram ? true : false}
            tickCount={isMainDiagram ? 15 : 0}
            interval={'equidistantPreserveStart'}
            id={`section-${sectionId}-x-axis`}
          />
          <YAxis
            label={{
              value: 'Number of Students',
              angle: -90,
              position: 'center'
            }}
            domain={[0, 'dataMax']}
            tick={false}
            tickLine={false}
            includeHidden
          />
          <Line
            data={lineGraphData}
            type='natural'
            dataKey='students'
            stroke='#985D35'
            strokeWidth={isMainDiagram ? 2 : 1}
            dot={false}
          />
          <Bar
            data={mappedDataForBarChart}
            shape={<CustomBarShape width={isMainDiagram ? 8 : 3} />}
            dataKey='students'
            fill={barColor}
            // maxBarSize={isMainDiagram ? 20 : 3}
          />
        </ComposedChart>
      </ResponsiveContainer>
      {isMainDiagram ? null : (
        <TicksWrapper className='ticks-wrapper'>
          {tickArray.map((item, index) => (
            <Tick key={index} />
          ))}
        </TicksWrapper>
      )}
      {!isMainDiagram ? (
        <a
          className='see-diagnostics-link'
          onClick={() =>
            redirectToSectionDiagnostics(examDetails.exam.id, sectionId)
          }
        >
          {t('diagnostics.scoreSheet.viewDiagnostics')}
        </a>
      ) : (
        ''
      )}
    </GraphWrapper>
  )
}

export default PercentileGraph

const GraphWrapper = styled.div`
  position: relative;
  ${({ isMainDiagram }) =>
    isMainDiagram ? 'box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);' : ''}
  border-radius: 6px;

  .yAxis .recharts-cartesian-axis-line {
    display: none;
  }

  .recharts-cartesian-axis-ticks text {
    visibility: hidden;
  }

  .recharts-cartesian-axis-tick:first-child > text,
  .recharts-cartesian-axis-tick:last-child > text {
    visibility: ${({ isMainDiagram }) =>
      isMainDiagram ? 'visible' : 'hidden'};
    display: block !important;
  }

  .xAxis {
    position: relative;
  }

  .see-diagnostics-link {
    position: absolute;
    bottom: 8px;
    left: 12px;
    cursor: pointer;
    text-decoration: underline;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.main.primary900};
  }
`
const MainDiagramTitle = styled.div`
  font-size: ${({ isMainDiagram }) => (isMainDiagram ? '16px' : '14px')};
  font-weight: ${({ isMainDiagram }) => (isMainDiagram ? '700' : '400')};
  padding: 8px;
  display: flex;
  text-transform: ${({ isMainDiagram }) =>
    isMainDiagram ? 'none' : 'capitalize'};
  z-index: 1;
  justify-content: space-between;
  margin-bottom: -34px;
  p {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 4px;
  }
`

const ScoreTile = styled.div`
  font-size: ${({ isMainDiagram }) => (isMainDiagram ? '24px' : '16px')};
  font-weight: 700;
  /* padding: ${({ isMainDiagram }) => (isMainDiagram ? '4px' : '2px 4px')}; */
  max-width: max-content;
  margin: 0 auto;
  border-radius: 4px;
  background-color: ${({ theme, isMainDiagram }) =>
    isMainDiagram ? '#fff4d7' : theme.colors.main.primary200};
  color: ${({ theme, isMainDiagram }) =>
    isMainDiagram ? theme.colors.main.text : theme.colors.main.primary600};
  text-align: center;
  z-index: 100;
  padding: ${({ isMainDiagram }) => (isMainDiagram ? '4px 20px' : '2px 4px')};
`

const TicksWrapper = styled.div`
  position: absolute;
  bottom: 27px;
  right: 9px;
  width: 97%;
  display: flex;
  justify-content: space-between;
  /* margin-top: 8px; */
  /* padding: 0 8px; */
  padding-left: 15px;
`

const Tick = styled.p`
  height: 6px;
  width: 1.3px;
  background-color: #666;
`
