import '@total-typescript/ts-reset'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import 'providers/i18n'
import ReduxProvider from 'providers/ReduxProvider'
import 'utils/horizontalScrollbarHack.css'
// import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'

const SENTRY_DSN = process.env.SENTRY_DSN
const APP_URL = process.env.REACT_APP_API_URL

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  autoSessionTracking: true,
  environment: process.env.CI_ENV,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['/front/', `${APP_URL}`],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

document.body.addEventListener('dragstart', event => {
  event.preventDefault()
})

document.body.addEventListener('drop', event => {
  event.preventDefault()
})

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider>
      <App />
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
