import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BouncingLoader } from 'examkrackers-components'
import PercentileBars from './PercentileBars'
import { useDispatch, useSelector } from 'react-redux'
import { fetchExamDetailsRoutine } from 'modules/exam/ducks/actions'
import * as R from 'ramda'
import { useParams } from 'react-router-dom'
import { isNotNilOrEmpty } from 'utils/ramda'
import { getExamDetails } from 'modules/exam/ducks/selectors'
import { ExamDetails } from 'types'
import { getExamDiagramData } from 'services/ExamsService'
import RawScoreBars from './RawScoreBars'
import RechartsPercentileGraph from './RechartsPercentileGraph'
import { EXAM_DIAGRAM_COLOR_ACCENTS } from 'pages/ExamScoreSheet'

const LoadingState = {
  initial: 'initial',
  loading: 'loading',
  fulfilled: 'fulfilled',
  error: 'error'
}

const NonFLScoreScheet = ({ children }) => {
  const [loading, setLoading] = useState(LoadingState.initial)
  const [examDiagramData, setExamDiagramData] = useState({})

  const dispatch = useDispatch()
  const params = useParams()
  const id: string = R.propOr('', 'id')(params)
  const examDetails = useSelector(getExamDetails) as ExamDetails
  const isLoading =
    loading === LoadingState.loading || loading === LoadingState.initial

  const fetchExamDetails = React.useCallback(
    () => dispatch(fetchExamDetailsRoutine({ id })),
    [dispatch]
  )

  const handleFetchDiagramData = async () => {
    if (!isNotNilOrEmpty(examDetails)) return
    const response = await getExamDiagramData({
      id: examDetails?.exam.originalExam.id
    })
    const data = await response.data
    return data
  }

  useEffect(() => {
    if (loading === LoadingState.initial) {
      fetchExamDetails()
    }
    if (isNotNilOrEmpty(examDetails?.exam.originalExam.id)) {
      handleFetchDiagramData()
        .then(data => {
          setExamDiagramData(data)
          setLoading(LoadingState.fulfilled)
        })
        .catch(error => {
          console.error('Error fetching data:', error)
        })
    }
  }, [examDetails])

  if (loading === LoadingState.initial || loading === LoadingState.loading) {
    return (
      <Container loading={isLoading}>
        <LoadingContainer loading={isLoading}>
          <BouncingLoader />
        </LoadingContainer>
      </Container>
    )
  }

  return (
    <Container loading={isLoading}>
      <LoadingContainer loading={isLoading}>
        <BouncingLoader />
      </LoadingContainer>
      <ChartWithTilesContainer>
        <Tiles>
          <TilesWrapper>{children}</TilesWrapper>
        </Tiles>
        <DataGrid>
          <Chart>
            <RechartsPercentileGraph
              examDetails={examDetails}
              data={examDiagramData}
              score={examDetails.exam.scores.exam.scaled_score}
              barColor={EXAM_DIAGRAM_COLOR_ACCENTS.overall}
              isMainDiagram
            />
          </Chart>
          <BarsWrapper>
            <PercentileBars examDetails={examDetails} nonFLexam={true} />
            <RawScoreBars examDetails={examDetails} nonFLexam={true} />
          </BarsWrapper>
        </DataGrid>
      </ChartWithTilesContainer>
    </Container>
  )
}

export default NonFLScoreScheet

const Container = styled.div`
  max-height: 584px;
  display: flex;
  gap: 16px;
  position: relative;
  overflow: ${({ loading }) => (loading ? 'hidden' : 'unset')};
`

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndex.menu};
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  animation: timed-out-opacity 3500ms ease-in;
  display: ${({ loading }) => (loading ? 'flex' : 'none')};

  @keyframes timed-out-opacity {
    0% {
      opacity: 1;
      display: flex;
    }
    70% {
      opacity: 1;
      display: flex;
    }
    99% {
      opacity: 0;
      display: flex;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
`
const ChartWithTilesContainer = styled.div`
  display: grid;
  grid-template-columns: 152px 1fr;
  width: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 6px;
`

const DataGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 16px 16px 0;

  .section-diagram {
    display: flex;
    flex-direction: column;
    /* gap: 2px; */
    padding-bottom: 16px;
  }
`

const Chart = styled.div`
  width: 582px;
  align-self: center;
`

const Tiles = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 16px;
  height: 100%;
  gap: 15px;

  & > div > div {
    justify-content: unset !important;
    gap: 16px;
  }
`

const BarsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`

const TilesWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  gap: 16px;
  padding: 8px;
`
