import { propOr, pipe, equals, pathOr } from 'ramda'
import { createSelector } from 'reselect'
import { API_STATES } from 'utils/redux'
import { RootReducer } from 'types'
import LocalStorageService from 'services/LocalStorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'

import { isNotNilOrEmpty } from 'utils/ramda'
import * as R from 'ramda'
import { previewStudentsEmail } from 'utils/user'
import { COURSE_TYPES } from 'utils/courses'
import { ANALYTICS_ACCOUNT_TYPE } from 'utils/analytics'

export const selectAuth = (state: RootReducer) => state.auth

export const getIsAuthLoading = createSelector(
  selectAuth,
  pipe(propOr([], 'state'), equals(API_STATES.IN_PROGRESS))
)

export const getIsAuthorised = () =>
  isNotNilOrEmpty(LocalStorageService.get(LOCAL_STORAGE_KEYS.token))

export const isAuthLoaded = createSelector(
  selectAuth,
  pipe(pathOr('', ['student', 'id']), isNotNilOrEmpty)
)

export const getIsOrdinaryStudent = createSelector(
  (state: RootReducer) => state.auth.student,
  user => {
    const isPreviewStudentsEmail =
      R.propOr('', 'email', user) === previewStudentsEmail
    const isImpersonate = R.propOr(false, 'is_impersonated', user)

    return !isPreviewStudentsEmail && !isImpersonate
  }
)

export const getIsImpersonate = createSelector(
  (state: RootReducer) => state.auth.student,
  R.propOr(false, 'is_impersonated')
)

export const getIsAuthActive = createSelector(
  selectAuth,
  pathOr(true, ['student', 'is_active'])
)

export const getStudent = createSelector(
  selectAuth,
  pathOr(
    {
      external_id: '',
      id: '',
      email: '',
      name: '',
      created_at: '',
      is_active: false,
      is_impersonated: false,
      impersonated_by: {
        id: '',
        email: '',
        role: ''
      },
      has_books: false,
      has_exams: false,
      has_courses: false,
      is_preview: false,
      preview_admin: {
        id: '',
        email: '',
        role: ''
      },
      role: '',
      phone_number: '',
      username: '',
      video_bg_music_enabled: false,
      cq_animations_enabled: true,
      is_getting_started_completed: false
    },
    ['student']
  )
)

export const getIsPreviewAdmin = createSelector(
  selectAuth,
  pathOr(false, ['student', 'is_preview'])
)

export const getAccountType = createSelector(
  R.pathOr('', ['state', 'courses', 'currentCourse']),
  currentCourse => {
    const isFreeTrial = R.pipe(
      R.propOr('', 'type'),
      R.equals(COURSE_TYPES.freeTrial)
    )(currentCourse)

    const hasCourse = R.pipe(R.propOr('', 'id'), isNotNilOrEmpty)(currentCourse)

    switch (true) {
      case isFreeTrial && hasCourse:
        return ANALYTICS_ACCOUNT_TYPE.freeTrial
      case hasCourse && !isFreeTrial:
        return ANALYTICS_ACCOUNT_TYPE.course
      default:
        return ANALYTICS_ACCOUNT_TYPE.exam
    }
  }
)
