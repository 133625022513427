import * as R from 'ramda'
import { BouncingLoader, Modal } from 'examkrackers-components'
import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Congrats,
  Card,
  CardHeader,
  Icon,
  Title,
  ProgressContainer,
  ProgressText,
  ProgressBar,
  Progress,
  Tasks,
  Task,
  Checkbox,
  CardContent,
  ScrollableTasksContainer,
  FixedCongratsContainer,
  CustomLabel
} from './GettingStarted.styles'
import { getTasks, toggleIsCompleted } from 'services/TaskService'
import LightbulbIcon from './LightbulbIcon'
import { markIsGettingStartedCompleted } from 'services/StudentService'
import { useDispatch, useSelector } from 'react-redux'
import { getStudent } from 'modules/auth/ducks/selectors'
import { studentFetchDetailsRoutine } from 'modules/auth/ducks/actions'

interface Task {
  id: string
  name: string
  content: { description: string; videoUrl: string }
  order: number
}

interface StudentTask {
  id: string
  task: Task
  is_completed: boolean
  completed_at: string
  created_at: string
}

type GettingStartedProps = {
  setIsGettingStartedCompleted: (isGettingStartedCompleted: boolean) => void
  setIsLoading: (isLoading: boolean) => void
  isLoading: boolean
}

const GettingStarted: FC<GettingStartedProps> = ({
  setIsGettingStartedCompleted,
  setIsLoading,
  isLoading
}) => {
  const [studentTasks, setStudentTasks] = useState<StudentTask[]>([])
  const [checkedTasks, setCheckedTasks] = useState<boolean[]>([])
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [currentTask, setCurrentTask] = useState<number>(0)
  const studentDetails = useSelector(getStudent)
  const dashboardTheme = R.propOr('light', 'theme', studentDetails)
  const isDarkTheme = dashboardTheme === 'dark'
  const dispatch = useDispatch()

  const handleCheckboxChange = (index: number) => {
    const studentTask = studentTasks[index]
    toggleIsCompleted(studentTask.id).then(() => {
      const newCheckedTasks = [...checkedTasks]
      newCheckedTasks[index] = !newCheckedTasks[index]
      setCheckedTasks(newCheckedTasks)
    })
  }

  const handleOpenModal = (index: number) => {
    setCurrentTask(index)
    setOpenModal(true)
  }

  const calculateProgress = () => {
    const completedTasks = checkedTasks.filter(Boolean).length
    return Math.round((completedTasks / studentTasks.length) * 100)
  }

  const fetchStudentDetails = useCallback(
    () => dispatch(studentFetchDetailsRoutine()),
    [dispatch]
  )
  useEffect(() => {
    getTasks().then(res => {
      const tasksWithParsedContent = res.data.map(task => ({
        ...task,
        task: {
          ...task.task,
          content: JSON.parse(task.task.content)
        }
      }))

      // Sort tasks based on the order field
      const sortedTasks = tasksWithParsedContent.sort(
        (a, b) => a.task.order - b.task.order
      )

      const initialCheckedTasks = sortedTasks.map(task => task.is_completed)

      setStudentTasks(sortedTasks)
      setCheckedTasks(initialCheckedTasks)
    })
  }, [])

  const isAllCompleted = checkedTasks.every(Boolean)

  if (isLoading) {
    return (
      <Card>
        <BouncingLoader />
      </Card>
    )
  }

  return (
    <>
      <style>
        {`
          .ReactModal__Content {
            width: 900px !important;
          }
        `}
      </style>
      <Card isDarkTheme={isDarkTheme} data-testid='GettingStarted-container'>
        <CardContent>
          <CardHeader>
            <Icon>
              <LightbulbIcon />
            </Icon>
            <Title isDarkTheme={isDarkTheme}>Getting Started</Title>
          </CardHeader>
          <ProgressContainer data-testid='GettingStarted-progress-container'>
            <ProgressText>{calculateProgress()}% Complete</ProgressText>
            <ProgressBar data-testid='GettingStarted-progress-progressbar-container'>
              <Progress
                data-testid='GettingStarted-progressbar'
                width={`${calculateProgress()}%`}
              />
            </ProgressBar>
          </ProgressContainer>
          <ScrollableTasksContainer
            data-testid='GettingStarted-scrollable-tasks-container'
            showCongrats={isAllCompleted}
            isDarkTheme={isDarkTheme}
          >
            <Tasks data-testid='GettingStarted-tasks'>
              {studentTasks.map((studentTask, index) => {
                const isLastTwo = index >= studentTasks.length - 2
                return (
                  <Task
                    key={studentTask.id}
                    data-testid={`GettingStarted-task-${index}`}
                  >
                    <Checkbox
                      type='checkbox'
                      checked={checkedTasks[index]}
                      onChange={() => handleCheckboxChange(index)}
                      isDarkTheme={isDarkTheme}
                      data-testid={`GettingStarted-task-checkbox-${index}`}
                    />
                    <CustomLabel
                      checked={checkedTasks[index]}
                      isLighter={isAllCompleted && isLastTwo}
                      onClick={() => handleOpenModal(index)}
                      isDarkTheme={isDarkTheme}
                      data-testid={`GettingStarted-task-label-${index}`}
                    >
                      {studentTask.task.name}
                    </CustomLabel>
                  </Task>
                )
              })}
            </Tasks>
          </ScrollableTasksContainer>
          {isAllCompleted && (
            <FixedCongratsContainer
              data-testid='GettingStarter-congrats-container'
              isDarkTheme={isDarkTheme}
            >
              <Congrats data-testid='GettingStarter-congrats-contents'>
                <div data-testid='GettingStarter-congrats-title'>
                  Congrats! You're set up for success.
                </div>
                <a
                  data-testid='GettingStarter-congrats-close-button'
                  style={{
                    color: isDarkTheme ? '#BDBDBD' : '#949596',
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }}
                  onClick={async () => {
                    setIsLoading(true)
                    await markIsGettingStartedCompleted()
                      .then(() => fetchStudentDetails())
                      .finally(() => setIsLoading(false))
                    setIsGettingStartedCompleted(true)
                    // setIsLoading(false)
                  }}
                >
                  Close helper
                </a>
              </Congrats>
            </FixedCongratsContainer>
          )}
        </CardContent>
      </Card>
      {studentTasks[currentTask] && (
        <Modal
          open={openModal}
          handleClose={() => setOpenModal(false)}
          title={`Getting Started: ${studentTasks[currentTask].task.name}`}
          data-testid='GettingStarted-task-modal'
        >
          <div
            data-testid='GettingStarter-modal-container'
            style={{
              textAlign: 'left',
              fontSize: '16px'
            }}
          >
            <p data-testid='GettingStarter-modal-description'>
              {studentTasks[currentTask].task.content.description}
            </p>
            <div style={{ textAlign: 'center', margin: '20px 0' }}>
              <iframe
                src={studentTasks[currentTask].task.content.videoUrl}
                width='640'
                height='360'
                frameBorder='0'
                allow='autoplay; fullscreen; picture-in-picture'
                allowFullScreen
                title={`Video: ${studentTasks[currentTask].task.name}`}
              />
            </div>
            <p style={{ fontStyle: 'italic' }}>
              When you complete this task, remember to check it off in the
              Getting Started Helper list.
            </p>
          </div>
        </Modal>
      )}
    </>
  )
}

export default GettingStarted
