import APIService from 'services/APIService'
import { dissoc, omit } from 'ramda'

const api = APIService()

export interface CalendarEventTypes {
  id: string
  student_course_id: string
  title: string
  type: string
  status: string
  event_date: string
  duration: number
  order: number
  action_uri: string
  is_manual: boolean
  description: string
  class_date?: string
  student_calendar_event_id?: string
  parent_event_id?: string
}

interface SetCalendarStartDate {
  course_id: string
  calendar_start_at: string
}

interface SetExamDate {
  course_id: string
  calendar_start_at: string | Date
  exam_at: string | Date
  mcat_date_id: string | Date
  build_calendar?: number
  is_pre_reading?: boolean
  study_days?: number
  pre_reading_end_date?: string
}
interface SetExamDateForReschedule {
  course_id: string
  exam_at: string | Date
  mcat_date_id: string
  build_calendar?: number
  date_end?: string
  is_pre_reading?: boolean
  study_days?: number
}

export interface CreateCalendarEvent {
  title: string
  type: string
  event_date: string
  duration: number
  action_uri: string
}

interface UpdateCalendarEvent {
  id: string
  title: string
  type: string
  order: number | string
  event_date: string
  duration: number
  action_uri: string
  status?: string
}

interface RemoveCalendarEvent {
  id: string
}

interface ReorderCalendarEvent {
  ids: string[]
}

interface GetSingleCalendarEvent {
  id: string
}
interface GetMcatDates {
  id: string
}
interface GetAamcEventsColors {
  id: string
}

interface GetCalendarManualTasks {
  rebuild?: boolean
}

interface SetPrioriDays {
  id: string
  prioridays: number[]
}

interface BuildPreReadingCalendar {
  date_end: string
  is_reset?: boolean
  date_start?: string
}

export const setCalendarStartDate = ({
  course_id,
  calendar_start_at
}: SetCalendarStartDate) => {
  return api.patch(`/student-courses/${course_id}/calendar-start-at`, {
    calendar_start_at
  })
}

export const setExamDate = (payload: SetExamDate) => {
  return api.patch(
    `/student-courses/${payload.course_id}/exam-date`,
    dissoc('course_id', payload)
  )
}
export const rescheduleExamDate = (payload: SetExamDateForReschedule) => {
  return api.patch(
    `/student-courses/${payload.course_id}/reschedule-calendar`,
    dissoc('course_id', payload)
  )
}

export const createCalendarEvent = payload => {
  if (payload.description === '') {
    return api.post(
      `/student-calendar-events`,
      omit(['action_uri', 'description'], payload)
    )
  } else {
    return api.post(`/student-calendar-events`, omit(['action_uri'], payload))
  }
}

export const updateCalendarEvent = payload => {
  return api.patch(
    `/student-calendar-events/${payload.id}`,
    omit(['action_uri', 'id'], payload)
  )
}

export const removeCalendarEvent = (payload: RemoveCalendarEvent) => {
  return api.delete(`/student-calendar-events/${payload.id}`)
}

export const reorderCalendarEvents = (payload: ReorderCalendarEvent) => {
  return api.patch(`/student-calendar-events/reorder`, payload)
}

export const getCalendar = () => {
  return api.get(`/student-calendar-events`)
}

export const getSingleCalendarEvent = (payload: GetSingleCalendarEvent) => {
  return api.get(`/student-calendar-events/${payload.id}`)
}

export const setFreeDaysForCalendar = (payload: { days_off: string[] }) => {
  return api.post(`student-calendar-days-off/many`, payload)
}

export const getFreeDaysForCalendar = () => {
  return api.get(`student-calendar-days-off`)
}

export const getMcatDates = (payload: GetMcatDates) => {
  return api.get(`/mcat-dates?filter[course_id]=${payload.id}&limit[take]=30`)
}

export const setPrioriDays = (payload: SetPrioriDays) => {
  return api.patch(
    `student-courses/${payload.id}/prioridays`,
    dissoc('id', payload)
  )
}
export const createNewCalendarEvent = payload => {
  return api.post(`/student-calendar-events/complex`, payload)
}

export const getAamcEventsColors = (payload: GetAamcEventsColors) => {
  return api.get(`/custom-event-groups?filter[course_id]=${payload.id}`)
}

export const getManualCalendarTasks = ({ rebuild }: GetCalendarManualTasks) => {
  return api.get(
    `/student-calendar-events/manual${
      rebuild ? `/rebuild` : ''
    }?order[by]=title&order[dir]=asc`
  )
}

export const postBuildPreReading = (payload: BuildPreReadingCalendar) => {
  return api.post(`/student-calendar-events/pre-reading`, payload)
}

export const postBuildLiveClasses = () => {
  return api.post(`/student-calendar-events/live-class`)
}
